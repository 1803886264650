import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { AdminBreadcrumbService } from '../admin-breadcrumb/services/admin-breadcrumb.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { debounceTime } from 'rxjs';
import * as $ from 'jquery';
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { ShareService } from '../services/share.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { NotificationService } from '../services/notification.service';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { environment } from "../../../environments/environment";
import { GalleryDialog } from './components/gallery/gallery-dialog.component';
import { MasterMultiselect } from './components/master-multiselect/master-multiselect.component';
import { SubmitDialog } from './components/submit-dialog/submit-dialog.component';
import { BulkApproveProperty } from './components/Bulk-Approve-Property/submit-bulk.component';
import { BulkRejectDialog } from './components/bulk-reject-dialog/bulk-reject-dialog.component';
import { property_list } from './models/property-list';
import { floor_list } from './models/floor-list';
import { unit_list } from './models/unit-list';

/**
*
* <strong>List of API using</strong>
* <ol>
* <li>umsapi_user_resource_post</li>
* <li>umsapi_user_list_post</li>
* <li>estapi_propertytypes_get</li>
* <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_unit_id_patch</li>
* <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_patch</li>
* <li>estapi_module_type_assignment_name_properties_property_id_patch</li>
* <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_unit_id_history_get</li>
* <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_history_get</li>
* <li>estapi_module_type_assignment_name_properties_property_id_history_get</li>
* <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_get</li>
* <li>estapi_module_type_assignment_name_properties_property_id_floors_get</li>
* <li>estapi_module_type_assignment_name_properties_get</li>
* <li>estapi_module_type_assignment_name_count_get</li>
* <li>estapi_module_type_assignments_get</li>
* <li>estapi_module_type_entity_attributegroups_get</li>
* <li>estapi_module_type_entity_attributes_get</li>
* <li>estapi_entity_master_master_id_values_get</li>
* <li>estapi_getProperties_post</li>
* <li>estapi_upload_type_uploads_template_get</li>
* </ol>
*
*/

@Component({
  selector: 'app-qa-qc',
  templateUrl: './qa-qc.component.html',
  styleUrls: ['./qa-qc.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('popUpZoom', [
      state('show', style({
        opacity: 1
      })),
      state('hide', style({
        opacity: 0
      })),
      transition('show => hide', animate('600ms ease-out')),
      transition('hide => show', animate('1000ms ease-in'))
    ])
  ],
})
export class QaQcComponent implements OnInit {
  jumpForm: FormGroup;
  isExpansionDetailRow = (i: number, row: Object) => {
    return row.hasOwnProperty('detailRow');
  }
  @ViewChild('sideNav', { static: true }) sideNav: any;
  @ViewChild('sidePNev', { static: true }) sidePNev: any;
  @ViewChild('sideUnitMapNav', { static: true }) sideUnitMapNav: any;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  columns = [];
  col_const = [
    { columnDef: 'action', header: 'Action', cell: (element: any) => `${element.action}` },
    { columnDef: 'user', header: 'User', cell: (element: any) => `${element.user}` },
  ];
  sort_by: any[] = [
    { label: 'Property Uid', value: 'property_uid' },
    { label: 'Added Date', value: 'added_date' },
    { label: 'Submitted Date', value: 'submitted_date' },
  ];
  sort_by_floor: any[] = [
    { label: 'Floor Uid', value: 'floor_uid' },
    { label: 'Sort Order', value: 'sort_order' },
    { label: 'Added Date', value: 'added_date' },
    { label: 'Submitted Date', value: 'submitted_date' },
  ];
  sort_by_unit: any[] = [
    { label: 'Unit Uid', value: 'unit_uid' },
    { label: 'Added Date', value: 'added_date' },
    { label: 'Submitted Date', value: 'submitted_date' },
  ];
  sort_order: any[] = [
    { label: 'Desc', value: false },
    { label: 'Asc', value: true },

  ];
  filterTerm: string = '';
  //VARIABLES DECLARED HERE--------------------------------------------------------

  /*
    Status     Name
     1    -  Created
     2    -  Submited for QA/QC
     3    -  Rejected
     4    -  Rejected & assigned
     5    -  Approved
     6    -  Start editing in Deapp
     7    -  Re-submit

  */
  nav_position: string = 'end';
  navp_position: string = 'end';
  mapView: any = '';
  rowData: any = null;
  selectedPolygons: any = [];
  sortAsc: any = false;
  sortfloor: any = true;
  sortunit: any = false;
  orderBy: string = 'property_uid';
  orderByFloor: string = 'sort_order';
  orderByUnit: string = 'unit_uid'
  searchText = '';
  public isfilter: boolean = false;
  options: any = [
    { status: '2', label: 'Review', value: '2' },
    { status: '3', label: 'Ready For Rework', value: '3' },
    { status: '7', label: 'Re submit', value: '7' },
    { status: '4', label: 'Rework', value: '4' },
    { status: '5', label: 'Approved', value: '5' }

  ];
  table_master = [];
  property_options: any;
  statusCountvalues: any = {};
  property_status: string = '2,3,7';
  status_deleted: string = '';
  status_demolished: string = '';
  status_multi_parcel: string = '';
  status_unit_map = '';
  displayedColumns = [];
  currentState: boolean;
  boxToggle: boolean = false;
  deleteToggle: boolean = false;
  selected_row: any;
  selected_rows: any = [];
  selected_col: any;
  show = false;
  search_input: any;
  test: any;
  toggle: boolean = false;
  dataSource: any=[];
  elementData: Element[];
  selectedElementId: any;
  expandedElement: any;
  selected_group: any = null;
  assignment_list: any = [];
  selected_assignment: any;
  selected_unit: any;
  user_list: any = [];
  selected_user_id: number = 0;
  groups: any = [{childrens:[]}];
  entity_type: string = 'property';
  assigned_to: any = [];
  history_view: any;
  group_clicked: any;
  linked_master: any = [];
  paginator_switch: boolean = true;
  group_active_icon:boolean=false;
  masters = [];
  master_val = [];
  group_attribute = [];
  used_attribute = [];
  customize_attribute = [];
  attribute_list = [];
  property_history = [];
  comment_rejected: string = '';
  loader: boolean;
  loader_assignment: boolean = false;
  rejectionType: number;
  expBoxToggle: boolean = false;
  viewMode: boolean = false;
  group_list_property: any = [];
  group_list_floor: any = [];
  group_list_unit: any = [];
  subgroups: any = [];
  current_attribute: any = {};
  selectedIds: number[] = [];

  //allPropertiesIds: number[] = [];

  property_list = [];
  property_limit = 10;
  property_offset = 0;
  property_type_id: any;
  resource_access: any = {
    'GET': true,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  image_access: any = {
    'GET': true,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  video_access: any = {
    'GET': true,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };

  property_access: any = {
    'GET': true,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };

  propertyForm: FormGroup;
  showStatus: boolean = false;
  child_master_val: any = {};
  showUsers: any = null;
  property_type_list: any = [];

  searchTerm: string = '';

  neighbourhoodList: any = [];
  blockList: any = [];
  streetList: any = [];

  filteredNeighList: any = [];
  filteredBlockList: any = [];
  filteredStreetList: any = [];

  neigh_uid: string = '';
  block_uid: string = '';

  floor_list = [];
  floor_history = [];
  selected_property = null;
  selected_floor = null;
  unit_list = [];
  unit_history = [];
  entity_count = {
    name: null,
    count: 0
  };
  newlist: any = [];
  pages: any = [];
  pageSizeOptions: number[] = [10, 15, 25, 50, 100, 200];
  defaultPageSize: number = 10;
  pageEvent: PageEvent;
  pageIndex = 0;
  errormess: string;
  property_name_id: number;

  searchField: FormControl;
  selectedValues: any = [];
  active_field: any = {
    id: null,
    label: null
  };
  public geoParcelData_list = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public unit_type_ids: string;
  public downloadLoader: boolean = false;

  new_floors_list: any = [];
  new_units_list: any = [];

  _deleted: any = [];
  _demolished: any = [];
  _multi_parcel: any = [];
  _review_later: any = [];
  showStepToggle: boolean = true;
  review_later: boolean = false;
  master_loader: boolean = false;
  listEnable: boolean = false;
  property_index: number = 1;
  floor_index: number = 1;
  unit_index: number = 1;
  total_property: any = 0;

  dragDisabled=true;
  add_status_2: string='';
  all_year: boolean;
  group_loader: boolean=false;


  constructor(private menu: AdminMenusService, private cd: ChangeDetectorRef,
    private _crumbs: AdminBreadcrumbService, public dialog: MatDialog, private fb: FormBuilder,
    private api: ApiserviceService, public fnc: CommonfunctionService, private share: ShareService,
    private elementRef: ElementRef, private notify: NotificationService, private route: Router) {
    this.setBreadCrumb();
    this.getPropertyType();
    this.menu.setActive('quality');
    this.columns = this.col_const;

    // this.pageSizeOptions = this.sizeOptions.split(',').map(str => +str);
    //this.getNeighInfo();
    this.getAllowResourcesApi();
    this.getAssignment();
    this.share.qaqc_obj = this;
  }


  onTableDrop(event: CdkDragDrop<any[]>){
    this.dragDisabled = true;
    if(this.property_limit < 200){
      this.notify.notify('Floor limit should be 200 for floor sorting', 'warn', 10000)
      return;
    }
    if(event.previousIndex == event.currentIndex){
      return;
    }
    let data_source =  this.dataSource.filter(ele=> ele.floor_id);
    moveItemInArray(data_source, event.previousIndex, event.currentIndex);

    let gIndex = this.fnc.getArrayindex('floor_name', 'Ground', data_source);
    if (gIndex == null){
      gIndex = this.fnc.getArrayindex('floor_name', 'ground', data_source);
    }
    let newdataSource =[];
    let up = 0;
    let down = gIndex - (gIndex * 2) - 1;
    let new_sort_order = [];

    let k =0;
    data_source.forEach((v) => {
      if(v.id){
        if (k == gIndex) v.sort_order = 0;
        if (k < gIndex) {
          down++;
          v.sort_order = down;
        }

        if (k > gIndex) {
          up++;
          v.sort_order = up;
        }

          new_sort_order.push({
            floor_id: v.id,
            sort_order: v.sort_order,
          })
          k++;
           newdataSource.push(v,{ detailRow: true, element: v })
        }
    });
    this.dataSource = newdataSource;
    let data = {
      "floor_sort_order_list": new_sort_order,
      "updated_by": this.api.user_id
    }
   this.loader= true;
    this.api.patchEpsData("deadmin/" + this.selected_assignment.name + "/properties/" + this.selected_property.id + '/floors', data)
      .subscribe({next:(data: any) => {
        this.loader= false;
        if(data && data.length){
          this.getFloors(false);
        }
      },
        error:(err) => {
          this.loader= false;
        }});

  }
  sortBy(val) {
    if (this.entity_type == "property") {
      this.orderBy = val;
      this.getProperties();
    };
    if (this.entity_type == "property_floor") {
      this.orderByFloor = val;
      this.getFloors();
    };
    if (this.entity_type == "property_unit") {
      this.orderByUnit = val;
      this.getUnits();
    }

  }

  sortOrder(val) {
    if (this.entity_type == "property") {
      this.sortAsc = val;
      this.getProperties();
    };
    if (this.entity_type == "property_floor") {
      this.sortfloor = val;
      this.getFloors();
    };
    if (this.entity_type == "property_unit") {
      this.sortunit = val;
      this.getUnits();
    };


  }
  refreshToggles() {
    this.showStepToggle = false;
    setTimeout(() => {
      this.showStepToggle = true;
    }, 100);
  }
  //do something with build status
  buildStatus(event) {
    switch (event) {
      case 'review_later':
        if (this.selected_assignment) {
          this.review_later = !this.review_later;
          setTimeout(() => {
            this.getProperties();
          }, 10);
        }
        break;
      case 'deleted':
        this.review_later = false;
        this.status_demolished = '';
        this.status_deleted = '-1';
        this.status_multi_parcel = '';
        this.status_unit_map = '';
        if (this.entity_type == 'property') {
          setTimeout(() => {
            this.getProperties();
          }, 0);
        }
        if (this.entity_type == 'property_floor') {
          setTimeout(() => {
            this.getFloors();
          }, 0);
        }
        if (this.entity_type == 'property_unit') {
          setTimeout(() => {
            this.getUnits();
          }, 0);
        }
        break;
      case 'demolished':
        this.review_later = false;
        this.status_deleted = '';
        this.status_demolished = '-2';
        this.status_multi_parcel = '';
        this.status_unit_map = '';
        if (this.entity_type == 'property') {
          setTimeout(() => {
            this.getProperties();
          }, 0);
        }
        if (this.entity_type == 'property_floor') {
          setTimeout(() => {
            this.getFloors();
          }, 0);
        }
        if (this.entity_type == 'property_unit') {
          setTimeout(() => {
            this.getUnits();
          }, 0);
        }
        break;
      case 'multi_parcel':
        this.review_later = false;
        this.status_deleted = '';
        this.status_demolished = '';
        this.status_multi_parcel = 'multi_parcel';
        this.status_unit_map = '';
        if (this.entity_type == 'property') {
          setTimeout(() => {
            this.getProperties();
          }, 0);
        }
        if (this.entity_type == 'property_floor') {
          setTimeout(() => {
            this.getFloors();
          }, 0);
        }
        if (this.entity_type == 'property_unit') {
          setTimeout(() => {
            this.getUnits();
          }, 0);
        }
        break;
      case 'unit_map':
        this.review_later = false;
        this.status_deleted = '';
        this.status_demolished = '';
        this.status_multi_parcel = '';
        this.status_unit_map = 'unit_map';
        this.openUnitMap();
        break;
      default:
        this.status_deleted = '';
        this.status_demolished = '';
        this.status_multi_parcel = '';
        this.status_unit_map = '';
        if (this.entity_type == 'property') {
          this.property_status = '2,3,7';
          setTimeout(() => {
            this.getProperties();
          }, 0);
        }
        if (this.entity_type == 'property_floor') {
          this.property_status = '2,3,7';
          setTimeout(() => {
            this.getFloors();
          }, 0);
        }
        if (this.entity_type == 'property_unit') {
          this.property_status = '2,3,7';
          setTimeout(() => {
            this.getUnits();
          }, 0);
        }
        break;
    }
  }

  resetStepToggle() {
    this.status_deleted = '';
    this.status_demolished = '';
    this.status_multi_parcel = '';
    this.property_status = '2,3,7';
    this._deleted = [];
    this._demolished = [];
    this._multi_parcel = [];
    this._review_later = [];
    this.showStepToggle = false;
    setTimeout(() => {
      this.showStepToggle = true;
    }, 0);
  }

  bulkReject() {
    let dialogRef = this.dialog.open(BulkRejectDialog, {
      width: '350px',
      panelClass: 'create-master-panel',
      data: { parent: this }//optional
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  getCount(type: any) {
    return this.entity_count[type];
  }
  //Reset filter
  public reset() {
    if (this.isfilter) {
      this.property_status = '2';
      this.property_offset = 0;
      if (this.entity_type == 'property') {
        this.property_limit = 10;
        this.getProperties();
      } else if (this.entity_type == 'property_floor') {
        this.property_limit = 200;
        this.getFloors();
      } else if (this.entity_type == 'property_unit') {
        this.property_limit = 10;
        this.getUnits();
      }
    }
    this.isfilter = false;
  }

  isNewlyCreated(value, column) {
    if (column == 'user') {
      if (this.entity_type == 'property_floor') {
        if (this.new_floors_list.includes(value.id)) {
          return true;
        } else {
          return false;
        }
      }
      if (this.entity_type == 'property_unit') {
        if (this.new_units_list.includes(value.id)) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
  // MULTISELECT RELATED FUNCTIONS====================================================================================================//
  // MULTISELECT RELATED FUNCTIONS====================================================================================================//
  openMultiSelect(attrib, parent = null) {
    let arr = [], parent_val = null;
    if (parent) {
      parent_val = this.propertyForm.get('input_' + parent.id).value;
      if (Array.isArray(parent_val) && parent_val.length == 0) {
        this.notify.notify('Please select a parent value', 'warn');
        return;
      } else {
        if (!parent_val) {
          this.notify.notify('Please select a parent value', 'warn');
          return;
        }
      }
    }
    let selected = this.propertyForm.get('input_' + attrib.id).value;
    if (Array.isArray(selected)) {
      arr = [{
        attr_id: attrib.id,
        selected: selected
      }];
    }
    let dialogRef = this.dialog.open(MasterMultiselect, {
      width: '450px',
      panelClass: 'create-master-panel',
      data: {
        source: this,
        attrib: attrib,
        parent: parent,
        selected: arr
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });

  }

  getList(attrib, s, parent = null) {
    let l;
    if (parent) {
      l = this.getChildMasterValue(attrib, parent);
    } else {
      l = this.getMasterById(attrib.master_id);
    }

    if (!s || this.active_field.id != attrib.id) {
      return l;
    }
    if (this.newlist) {
      return this.newlist;
    } else {
      return l;
    }
  }

  //getting selected values for multiselect dropdown
  getSelectedValues(attrib, single = false) {
    let selected = this.propertyForm.get('input_' + attrib.id).value;
    if (!single) {
      if (selected && selected.length) {
        // this.selectedValues.push({
        //   attr_id: attrib.id,
        //   selected: selected
        // })
        $('#input_custom_' + attrib.id).val(selected.length + " values selected");
      } else {
        $('#input_custom_' + attrib.id).val("No values selected");
      }
    } else if (selected && single) {
      let valObj = this.fnc.getArrayValueNull('id', selected, this.master_val);
      if (valObj != null) {
        let val = (valObj.label) ? valObj.label : valObj.master_name;
        if (val) $('#input_custom_' + attrib.id).val(val);
      }
    } else {
      $('#input_custom_' + attrib.id).val("No values selected");
    }
  }

  // MULTISELECT RELATED FUNCTIONS====================================================================================================//
  // MULTISELECT RELATED FUNCTIONS====================================================================================================//
  validate(data) {
    if (data) {
    }
  }

  getAllowResourcesApi() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe({next:(res: any) => {
        this.api.allowResources = res.data;
        this.getAllowResource();
      }, error:(err) => {
      }})
  }
  // getAllowResourcesApi() {
  //   this.api.getUserRole(); // getting role assign to this user
  //   let body = "place_id=" + this.api.city_id;
  //   this.api.postUmsData('user/resource', body)
  //     .subscribe(
  //       (data: any) => {
  //         this.api.allowResources = data;
  //         this.getAllowResource();
  //       },
  //       err => {
  //       }
  //     )
  // }
  getAllowResource() {
    this.resource_access = this.fnc.checkResourceAccess('qa_qc', false);
    this.image_access = this.fnc.checkResourceAccess('images', false);
    this.video_access = this.fnc.checkResourceAccess('videos', false);
    this.property_access = this.fnc.checkResourceAccess('property_data ', false);
    if (!this.resource_access.GET) {
      this.route.navigateByUrl('/');
      return;
    }
  }

  setBreadCrumb() {
    this._crumbs.clear();
    this._crumbs.addcrumb = { title: 'QA' };
    if (this.selected_property) {
      this._crumbs.addcrumb = { title: `Property ${this.selected_property.uid}`, function: `property` };
    }
    if (this.selected_floor) {
      this._crumbs.addcrumb = { title: `Floor ${this.selected_floor.uid}`, function: `floor` };
    }
    this._crumbs.count = 1;
  }

  createFormElement() {
    let control = this.current_attribute;
    let group = this.fb.group({});
    let tbl_attr = [], table_attr = [];
    if (control.data_type != 'table') {
      group.addControl('input_' + control.id, this.fb.control(''));
      if (control.measure_unit) group.addControl('unit_' + control.id, this.fb.control(''));
      if (control.child.length > 0) { // control for child attributes
        control.child.forEach(child => {
          group.addControl('input_' + child.id, this.fb.control(''));
          if (child.measure_unit) group.addControl('unit_' + child.id, this.fb.control(''));
          if (child.mutual.length > 0) { // control for child mutual attributes
            setTimeout(() => {
              //this.hideElement(control.id); // hide child element for mutual exclussive
            }, 200);
            child.mutual.forEach(cm => {
              group.addControl('input_' + cm.id, this.fb.control(''));
              // if (cm.measure_unit) group.addControl('unit_' + cm.id, this.fb.control(''));
            });
          }
        }
        );
      }
      if (control.mutual.length > 0) { // control for mutual exclussive attributes
        control.mutual.forEach(mutual => {
          if (mutual) {
            group.addControl('input_' + mutual.id, this.fb.control(''));
            //if (mutual.measure_unit) group.addControl('unit_' + mutual.id, this.fb.control(''));
          }
        });
      }
    }
    if (control.data_type == 'table') {
      group.addControl('input_' + control.id, this.fb.control(''));
      let attrib_detail = this.fnc.getArrayValue('id', control.id, this.attribute_list);
      let master_val = this.getMasterById(attrib_detail.master_id);
      master_val.forEach((m) => {
        control.child.forEach((c) => {
          group.addControl('input_' + m.id + "_" + c.id, this.fb.control(''));
          // if (c.measure_unit) group.addControl('unit_' + m.id + "_" + c.id, this.fb.control(''));
        });
      });
    }
    this.propertyForm = group;
    //return group;
  }
  formatDate(value) {
    return value.getFullYear() + "-" + (value.getMonth() + 1).toString().padStart(2, 0) + "-" + value.getDate().toString().padStart(2, 0);
  }
  patchFormData2(v, data) {
    let e = $(this.elementRef.nativeElement);
    let val = null, key, unit_key, control;

    let rec = {}, unit_rec = {};
    let patchData = {}, pval;
    let attrib_detail = v;
    if (data[v.id] && !v.is_hidden && v.status) {
      key = 'input_' + v.id;
      if (v.data_type != 'table') {
        if (v.measure_unit && String(data[v.id]).indexOf(' ') != -1) {
          let unit_data = data[v.id].split(' ');
          rec[key] = unit_data[0];
          if (unit_data[1]) {
            unit_key = "unit_" + v.id;
            unit_rec[unit_key] = unit_data[1];
            //this.propertyForm.patchValue(unit_rec);
          }

        } else {
          val = data[v.id];
          if (v.data_type == 'year' || v.data_type == 'month') { // convert each value to string
            val = String(val);
          }
          if (v.data_type == 'date') { // convert each value to string
            val = this.formatDate(new Date(val));
          }
          if (v.data_type == 'single-select') val = String(val);
          if (v.data_type == 'single-select' && attrib_detail) {
            val = String(val);
            if (val && v.master_id) {//getting selected master value for single select
              let obj = this.fnc.getArrayValueNull('id', +val, this.master_val);
              if (!obj) {
                // this.getMasterValue(v.master_id, [val]);
              }
            }
            if (attrib_detail.child.length) {
              pval = val;
              setTimeout(() => {
                this.changeChild(pval, attrib_detail, true); // patch value of child element
              }, 600);
            }
          }
          if (v.data_type == 'multi-select') { // convert each value of multi select to string
            if (val.length > 0) {
              if (attrib_detail) { // patch value of child element
                if (val && v.master_id) {//getting selected master value for multi select
                  let filteredVals = [];
                  val.forEach(element => {
                    let obj = this.fnc.getArrayValueNull('id', +element, this.master_val);
                    if (!obj) {
                      filteredVals.push(element);
                    }
                  });
                  //if (filteredVals.length > 0) this.getMasterValue(v.master_id, filteredVals);
                }
                if (attrib_detail.child.length) {
                  pval = val;
                  setTimeout(() => {
                    this.changeMultiChild(pval, attrib_detail, true);
                  }, 600);
                }
              }
              let multi_val = [];
              val.forEach((multi) => {
                multi_val.push(String(multi));
              });
              val = multi_val;
            } else {
              val = null;
            }
          }
          rec[key] = val;
        }
        if (attrib_detail && attrib_detail.mutual.length) { // for mutual attribute condition

          if (data[attrib_detail.id]) {
            setTimeout(() => {
              e.find("#radio_" + attrib_detail.id + "_1").prop('checked', true);
            }, 200);
            attrib_detail.mutual.forEach((mt) => {
              patchData["input_" + mt.id] = null;
              this.propertyForm.patchValue(patchData);
            });
          }
          if (attrib_detail.child.length) {
            attrib_detail.child.forEach((c) => {
              if (data[c.id]) {
                setTimeout(() => {
                  e.find("#radio_" + c.id + "_1").prop('checked', true);
                }, 200);
              }
              if (c.mutual.length) {
                c.mutual.forEach((m) => {
                  if (data[m.id]) {
                    setTimeout(() => {
                      e.find("#radio_" + c.id + "_2").prop('checked', true);
                      e.find("#div_" + c.id + "_1").addClass("disabled-div");
                      e.find("#input_" + c.id).addClass("disabled-div");
                      e.find("#div_" + c.id + "_2").removeClass("disabled-div");
                    }, 200);
                  }
                })
              }
            })
          }
        }
        this.propertyForm.patchValue(rec);
      }
      if (v.data_type == 'table') {
        if (data[v.id].length > 0) {
          let record = data[v.id];
          let master_val = this.getMasterById(v.master_id);
          let attrib_detail = this.fnc.getArrayValue('id', v.id, this.customize_attribute);
          master_val.forEach((m) => {
            if (attrib_detail.child.length > 0) {
              let attr_m = this.fnc.getArrayValue('0', m.id, record);
              if (!attr_m) return;
              attrib_detail.child.forEach((c) => {
                key = 'input_' + m.id + '_' + c.id;
                if (!c.is_hidden && c.status) {
                  if (c.measure_unit && String(attr_m[c.id]).indexOf(' ') != -1) {
                    let unit_data = attr_m[c.id].split(' ');
                    rec[key] = unit_data[0];
                    if (unit_data[1]) {
                      unit_key = 'unit_' + m.id + '_' + c.id;
                      unit_rec[unit_key] = unit_data[1];
                      this.propertyForm.patchValue(unit_rec);
                    }
                  } else {
                    val = attr_m[c.id];
                    if (c.data_type == 'year' || c.data_type == 'month') { // convert each value to string
                      val = String(val);
                    }
                    if (c.data_type == 'single-select') val = String(val);
                    if (c.data_type == 'multi-select') { // convert each value of multi select to string
                      if (val) {
                        if (val.length > 0) {
                          let multi_val = [];
                          val.forEach((multi) => {
                            multi_val.push(String(multi));
                          });
                          val = multi_val;
                        } else {
                          val = null;
                        }
                      }
                    }
                    rec[key] = val;
                  }
                }
              });
            }
          });
          this.propertyForm.patchValue(rec);
        }
      }
    } else {

      if (attrib_detail && attrib_detail.mutual) {
        attrib_detail.mutual.forEach((m) => {
          if (data[m.id]) {
            setTimeout(() => {
              e.find("#radio_" + attrib_detail.id + "_2").prop('checked', true);
              this.hideElement(attrib_detail.id);
              e.find("#div_" + attrib_detail.id + "_1").addClass("disabled-div");
              e.find("#input_" + attrib_detail.id).addClass("disabled-div");
              e.find("#div_" + attrib_detail.id + "_2").removeClass("disabled-div");
              patchData["input_" + attrib_detail.id] = null;
              this.propertyForm.patchValue(patchData);
            }, 300);

          } else {
            setTimeout(() => {
              e.find("#radio_" + attrib_detail.id + "_1").prop('checked', true);
            }, 300);
          }
        });
        if (attrib_detail.child) {
          attrib_detail.child.forEach((c) => {
            if (data[c.id]) {
              setTimeout(() => {
                e.find("#radio_" + c.id + "_1").prop('checked', true);
              }, 200);
            }
            if (c.mutual.length) {
              c.mutual.forEach((m) => {
                if (data[m.id]) {
                  setTimeout(() => {
                    e.find("#radio_" + c.id + "_2").prop('checked', true);
                    e.find("#div_" + c.id + "_1").addClass("disabled-div");
                    e.find("#input_" + c.id).addClass("disabled-div");
                    e.find("#div_" + c.id + "_2").removeClass("disabled-div");
                  }, 200);
                } else {
                  setTimeout(() => {
                    e.find("#radio_" + c.id + "_1").prop('checked', true);
                  }, 200);
                }
              })
            }
          })
        }
      }
      // path default value of non table attribute
      // if (v.data_type != "table") {
      //   key = "input_" + v.id;
      //   unit_rec[key] = v.default_value;
      //   this.propertyForm.patchValue(unit_rec);
      //   if (v.measure_unit) {
      //     unit_key = "unit_" + v.id;
      //     rec[unit_key] = v.measure_unit;
      //     this.propertyForm.patchValue(rec);
      //   }
      // }
    }
    // path default value of table attribute
    // if (v.data_type == 'table') {
    //   let record = data[v.id];
    //   let master_val = this.getMasterById(v.master_id);
    //   let attrib_detail = this.fnc.getArrayValue('id', v.id, this.customize_attribute);
    //   if (attrib_detail) {
    //     master_val.forEach((m) => {
    //       let attr_m = this.fnc.getArrayValue('0', m.id, record);
    //       if (attrib_detail.child.length > 0) {
    //         attrib_detail.child.forEach((c) => {
    //           if (!c.is_hidden && c.status) {
    //             if (c.measure_unit) {
    //               unit_key = 'unit_' + m.id + '_' + c.id;
    //               unit_rec[unit_key] = c.measure_unit;
    //               this.propertyForm.patchValue(unit_rec);
    //             } else if(!attr_m[c.id]) {
    //               key = 'input_' + m.id + '_' + c.id;
    //               rec[key] = c.default_value;
    //               this.propertyForm.patchValue(rec);
    //             }
    //           }
    //         });
    //       }
    //     });
    //   }
    // }
  }

  showChild(attr, event = null, num) {
    let id = attr.id;
    let patchData = {};
    let e = $(this.elementRef.nativeElement);
    //this.propertyForm.controls.nam
    if (event.target.value == 'on') {
      e.find('#child_' + id).removeClass('hidden');
    } else {
      e.find('#child_' + id).addClass('hidden');
    }
    if (num == 1) {
      e.find("#div_" + id + "_2").addClass("disabled-div");
      e.find("#input_" + id).removeClass("disabled-div");
      e.find("#div_" + id + "_1").removeClass("disabled-div"); // special case for toggle
      if (attr.data_type == "toggle") {
        e.find("#input_" + id).prop('checked', true);
        patchData["input_" + id] = true;
      } else {
        patchData["input_" + id] = null;
      }
      if (attr.mutual.length) {
        attr.mutual.forEach((c) => {
          patchData["input_" + c.id] = null;
          this.propertyForm.patchValue(patchData);
        });
      }

    }
    if (num == 2) {
      e.find("#div_" + id + "_2").removeClass("disabled-div");
      e.find("#div_" + id + "_1").addClass("disabled-div");
      e.find("#input_" + id).addClass("disabled-div");
      if (attr.data_type == "toggle") {
        e.find("#input_" + id).prop('checked', false);
        patchData["input_" + id] = false;
      } else {
        patchData["input_" + id] = null;
      }
      this.propertyForm.patchValue(patchData);
      if (attr.child.length) {
        attr.child.forEach((c) => {
          patchData["input_" + c.id] = null;
          this.propertyForm.patchValue(patchData);
          if (c.mutual.length) {
            c.mutual.forEach((m) => {
              patchData["input_" + m.id] = null;
              this.propertyForm.patchValue(patchData);
            });
          }
        });
      }

    }
  }

  patchFormData() {
    let data = {}, attr;
    if (this.dataSource) {
      let info = this.fnc.getArrayValue('uid', this.selected_row, this.dataSource)
      data = info.data;
    }
    attr = this.current_attribute;

    if (data) {
      this.patchFormData2(attr, data)
      if (attr.child.length) {
        attr.child.forEach((c) => {
          this.patchFormData2(c, data);
        });
      }
      if (attr.mutual.length) {
        attr.mutual.forEach((c) => {
          this.patchFormData2(c, data);
        });
      }
    }
  }
  printObject(obj) {


  }

  isChecked(id) {
    if (this.selectedIds.includes(id)) {
      return true;
    } else {
      return false;
    }
  }

  // getting selected Ids
  changeSelectedIds(e, data) {
    if (e.checked == true) {
      this.selectedIds.push(data.id);
      this.selected_rows.push(data);
    } else {
      this.selectedIds.splice(this.selectedIds.indexOf(data.id), 1)
      this.selected_rows.splice(this.selected_rows.indexOf(data), 1)
    }
  }
  public changeSelectedAllIds(event) {
    if (event.checked == true) {
      this.selectedIds = [];
      this.selected_rows = [];
      if (this.entity_type == 'property') {
        this.property_list.forEach(data => {
          this.selectedIds.push(data.id);
          this.selected_rows.push(data)
        })
      }
      if (this.entity_type == 'property_floor') {
        this.floor_list.forEach(data => {
          this.selectedIds.push(data.id);
          this.selected_rows.push(data)
        })
      }
      if (this.entity_type == 'property_unit') {
        this.unit_list.forEach(data => {
          this.selectedIds.push(data.id);
          this.selected_rows.push(data)
        })
      }


    } else {
      this.selectedIds = [];
      this.selected_rows = [];
    }
  }
  updateProperty(event) {
    if (!this.resource_access.PATCH) {
      this.notify.notify("You're not authorized to perform update operation in qa/qc property", 'warn');
      return;
    }
    let info;
    let updatedAttribute = 0;
    let preVal, attribute_info = {};
    if (this.dataSource) {
      info = this.fnc.getArrayValue('uid', this.selected_row, this.dataSource)
      preVal = info.data;
      if (info.attribute_info) attribute_info = info.attribute_info;
    }
    let value = event.value;
    let x, attr_id, unit = '', attribute_c, attribute, lang_attribute = {}, simple_attribute = {}, val;
    let data = {}, body = {}, used_attr = [], table_attr = [];
    let e = $(this.elementRef.nativeElement);
    for (x in value) {
      if (x.indexOf('unit') == -1) {
        unit = '';
        let attr_array = x.split('_');
        let attr_id = attr_array[attr_array.length - 1]; // last index wil be attribute id
        e.find("#input_" + attr_id).removeClass("red-border");
        attribute_c = this.fnc.getArrayValue('id', attr_id, this.customize_attribute);
        attribute = this.fnc.getArrayValue('id', attr_id, this.attribute_list);
        if (attribute.is_editable && !attribute.is_hidden && attribute.status) {
          if (attribute.data_type == 'table') { // for type table
            let tbl = { id: attribute_c.id, child: [] };
            used_attr.push(attribute_c.id);
            if (attribute_c.child.length > 0) {
              attribute_c.child.forEach((attr) => {
                used_attr.push(attr.id);
              })
              tbl.child = attribute_c.child;
            }
            table_attr.push(tbl);
          }
          if (used_attr.indexOf(+attr_id) == -1) {
            // if (attribute.measure_unit) {
            //   e.find("#unit_" + attr_id).removeClass("red-border");
            //   unit = value["unit_" + attr_id];
            //   val = null;
            //   if (unit && value[x]) val = value[x] + " " + unit;
            //   if ((!unit || !value[x]) && attribute.is_mandatory) {
            //     e.find("#unit_" + attr_id).addClass("red-border");
            //     e.find("#input_" + attr_id).addClass("red-border");
            //     val = null;
            //   }
            // } else {
            val = value[x];
            if (!val && attribute.is_mandatory) {
              //e.find("#input_" + attr_id).addClass("red-border");
            }
            //}
            if (attr_id == this.property_name_id && val) {
              let _building_name = { [this.api.language.toString()]: val };
              body['own_address'] = {
                'building_name': _building_name
              }; // synching own address
            }
            let oldVal = (preVal[attr_id]) ? preVal[attr_id] : null;
            if (attribute.data_type == "toggle" && !val) {
              val = false;   // set false value to toggel in case of null value
              if (!oldVal) oldVal = false;
            }
            if (attribute.data_type == "date" && !val) {
              val = null;   // set false value to toggel in case of null value
            }
            if ((attribute.data_type == "toggle" && val != oldVal) || this.compairValue(val, oldVal)) { // send only changed value
              //updatedAttribute++;
              if (attribute.data_type == 'multi-select') { // convert each value to integer
                if (val.length > 0) {
                  let multi_val = [];
                  val.forEach((multi) => {
                    multi_val.push(parseInt(multi));
                  });
                  val = multi_val;
                } else {
                  val = null;
                }
              }

              if (attribute.data_type == 'year' || attribute.data_type == 'month') { // convert each value to integer
                val = parseInt(val);
              }
              if (attribute.is_mandatory && !val) {  // null value is not allow in mandatory case

              } else {
                attribute_info[attr_id] = {
                  status: 2,
                  comment: ''
                }
                if (attribute.is_lang_dependent) {
                  lang_attribute[attr_id] = val;
                }
                if (!attribute.is_lang_dependent) {
                  simple_attribute[attr_id] = val;
                }
              }
            }

          }
        }
      }
    }
    if (table_attr.length > 0) { // get data of table type attribute
      table_attr.forEach((v) => {
        let tbl_val = [];
        let attrib_detail = this.fnc.getArrayValue('id', v.id, this.attribute_list);
        let master_val = this.getMasterById(attrib_detail.master_id);
        //let record = preVal[v.id];

        master_val.forEach((m) => {
          let tbl = {};
          //let attr_m = this.fnc.getArrayValue('0', m.id, record);
          tbl["0"] = m.id;
          v.child.forEach((c) => {
            if (c.is_editable && !c.is_hidden && c.status) {
              // if (c.measure_unit) {
              //   e.find("#unit_" + m.id + "_" + c.id).removeClass("red-border");
              //   unit = value["unit_" + m.id + "_" + c.id];
              //   val = value["input_" + m.id + "_" + c.id] + " " + unit;
              //   if ((!unit || !value["input_" + m.id + "_" + c.id]) && attribute.is_mandatory) {
              //     e.find("unit_" + m.id + "_" + c.id).addClass("red-border");
              //     val = null;
              //   }
              // } else {
              val = value["input_" + m.id + "_" + c.id];
              if (!val && attribute.is_mandatory) {
                //e.find("input_" + m.id + "_" + c.id).addClass("red-border");
              }
              // }
              if ((attribute.data_type == 'string-input') && val) {
                val = val.trim();
              }
              //let oldVal = (attr_m[c.id]) ? attr_m[c.id] : null;
              if (val != '' || val != null) { //  && this.compairValue(val,oldVal)
                //updatedAttribute++;
                if (c.data_type == 'multi-select') { // convert each value to integer
                  if (val.length > 0) {
                    let multi_val = [];
                    val.forEach((multi) => {
                      multi_val.push(parseInt(multi));
                    });
                    val = multi_val;
                  } else {
                    val = null;
                  }
                }
                if (c.data_type == 'year' || c.data_type == 'month') { // convert each value to integer
                  val = parseInt(val);
                }

                if (c.data_type == 'number-input' && val) {
                  tbl[c.id] = + val;
                } else {
                  tbl[c.id] = val?val:null;
                }
              }
            }
          });
          if (this.fnc.jsonLength(tbl) > 1) tbl_val.push(tbl);
        });
        attribute_info[v.id] = {
          status: 2,
          comment: ''
        }
        if (attrib_detail.is_lang_dependent && tbl_val.length) {
          lang_attribute[v.id] = tbl_val;
        }
        if (!attrib_detail.is_lang_dependent && tbl_val.length) {
          simple_attribute[v.id] = tbl_val;
        }
      });
    }
    if (this.fnc.jsonLength(lang_attribute) || this.fnc.jsonLength(simple_attribute)) updatedAttribute = 1;
    if (updatedAttribute) {
      data["0"] = simple_attribute;
      data[this.api.language] = lang_attribute;
      body["attribute_info"] = attribute_info;
      body["data"] = data;
      body["updated_by"] = this.api.user_id;
      body["assignment_id"] = this.selected_assignment.assignment_id;
      let url = "";
      if (this.entity_type == 'property') {
        url = "deadmin/" + this.selected_assignment.name + "/properties/" + info.property_id;
      }
      if (this.entity_type == 'property_floor') {
        url = "deadmin/" + this.selected_assignment.name + "/properties/" + this.selected_property.id + "/floors/" + info.id;
      }
      if (this.entity_type == 'property_unit') {
        url = "deadmin/" + this.selected_assignment.name + "/properties/" + this.selected_property.id + "/floors/" + this.selected_floor.id + "/units/" + info.id;
      }
      this.api.patchEpsData(url, body)
        .subscribe({next: (data: any) => {
          this.notify.notify("Information updated", 'success');
          if (this.entity_type == 'property') {
            this.getProperties();
          }
          if (this.entity_type == 'property_floor') {
            this.getFloors();
          }
          if (this.entity_type == 'property_unit') {
            this.getUnits();
          }
        },
          error:(err) => {
            this.notify.notify(this.fnc.getErrorMessage(err), 'error');
          }});
    }
  }
  compairValue(val1, val2) {
    let res = true;
    if (!val1 && !val2) res = false;
    if (val1 && val2) {
      if (val1.toString() == val2.toString()) res = false;
    }
    return res;
  }
  getMasterLabel(values, is_array = false) {
    let ids;
    let label = [], lb;
    if (typeof values === 'object' && values.column) {
      const { row, column } = values;
      ids = row.data[column.id];
    } else {
      ids = values;
    }
    if (!is_array) {
      if (!ids) return;
      lb = this.fnc.getArrayValue('id', ids, this.master_val);
      if (this.fnc.jsonLength(lb)) {
        label[0] = lb.label;
      } else {
        if (typeof values === 'object' && !this.master_loader) {
          this.findMasterValue(values);
        }
      }
    } else {
      if (!ids) return;
      ids = "" + ids;
      let l_arr = ids.split(',');
      if (l_arr.length) {
        l_arr.forEach((v) => {
          lb = this.fnc.getArrayValue('id', v, this.master_val);
          if (this.fnc.jsonLength(lb)) {
            label.push(lb.label);
          }
        });
      }
    }
    return label.toString();
  }

  findMasterValue({ row, column }) {
    this.current_attribute = this.fnc.getArrayValue('id', column.id, this.customize_attribute);
    let property_detail = this.fnc.getArrayValue('uid', row.uid, this.dataSource);
    let propertydetail = property_detail.data;
    if(this.current_attribute?.child && this.current_attribute?.child.length > 0){
      this.current_attribute?.child.forEach(element => {
        if(this.current_attribute.data_type == "multi-select" || this.current_attribute.data_type == "single-select"){
          this.getMasterValue(propertydetail[element.id]);
        }
        });
    }
    if(this.current_attribute.data_type == "multi-select" || this.current_attribute.data_type == "single-select"){
      this.getMasterValuesById(this.current_attribute.master_id, propertydetail[column.id]);
    }

  }

  // getMasterLabel(ids, is_array = false) {
  //   let label = [], lb;
  //   if (!is_array) {
  //     lb = this.fnc.getArrayValue('id', ids, this.master_val);
  //     if (lb) label[0] = lb.label;
  //   } else {
  //     ids = "" + ids;
  //     let l_arr = ids.split(',');
  //     if (l_arr.length) {
  //       l_arr.forEach((v) => {
  //         lb = this.fnc.getArrayValue('id', v, this.master_val);
  //         if (lb) label.push(lb.label);
  //       });
  //     }
  //   }
  //   return label.toString();
  // }
  isEdited(row, id) {
    let flag = false;
    if (id && row.attribute_info) {
      if (row.attribute_info[id]) {
        let info = row.attribute_info[id];
        if (info.status == 2) flag = true;
      }
    }
    return flag;
  }
  isRejected(row, id) {
    let flag = false;
    if (id && row.attribute_info) {
      if (row.attribute_info[id]) {
        let info = row.attribute_info[id];
        if (info.status == -1) flag = true;
      }
    }
    return flag;
  }

  isBtnDisable(row){
    if(row && row.status == 5){
      return true;
    }
    return false;
  }
  wasEdited(row, id) {
    let flag = false;
    if (id && row.attribute_info) {
      if (row.attribute_info[id]) {
        let info = row.attribute_info[id];
        if (info.status == 2) flag = true;
      }
    }
    return flag;
  }
  wasRejected(row, id = null) { //check on row level
    let flag = false;
    if (id) {
      if (id && row.attribute_info) {
        if (row.attribute_info[id]) {
          let info = row.attribute_info[id];
          if (info.status == 3) flag = true;
        }
      }
    } else {
      if (row.attribute_info) {
        for (let key in row.attribute_info) {
          if (row.attribute_info[key].status == 3) {
            flag = true;
            break;
          }
        }
      }
    }
    return flag;
  }
  getComment(data, attr_id) {
    let comment;
    if (data.attribute_info) {
      if (data.attribute_info[attr_id]) {
        comment = data.attribute_info[attr_id].comment
        return comment;
      };
    }
  }
  hideElement(id) {
    let e = $(this.elementRef.nativeElement);
    e.find('#child_' + id).addClass('hidden');
  }
  // changeMultiChild(event, attrib) {
  //   let val = event;
  //   let last_val: any = [];
  //   let new_val: any = [];
  //   let final_val: any = [];
  //   if (val) {
  //     if (attrib.child.length) {
  //       let e = $(this.elementRef.nativeElement);
  //       attrib.child.forEach((child) => {
  //         this.child_master_val[child.id] = [];
  //         if (child.data_type == "single-select" || child.data_type == "multi-select") {
  //           val.forEach((v) => {
  //             if (this.child_master_val[child.id].length) {
  //               last_val = this.child_master_val[child.id];
  //             }
  //             new_val = this.getMasterByValueId(child, +v);
  //             if (new_val.length) {
  //               final_val = new_val;
  //             }
  //             if (last_val.length) {
  //               final_val = last_val;
  //             }
  //             if (last_val.length && new_val.length) {
  //               final_val = new_val.concat(last_val);
  //             }
  //             this.child_master_val[child.id] = final_val;
  //           });
  //         }
  //       });
  //     }
  //   }
  // }

  changeMultiChild(event, attrib, onload = false) {
    let val = event;
    let fld = null;
    let last_val: any = [];
    let new_val: any = [];
    let final_val: any = [];
    if (val) {
      if (attrib.child.length) {
        attrib.child.forEach((child) => {
          this.child_master_val[child.id] = [];
          if (child.data_type == "single-select" || child.data_type == "multi-select") {
            this.linked_master = [];
            val.forEach((v) => {
              fld = null;
              if (this.child_master_val[child.id].length) {
                last_val = this.child_master_val[child.id];
              }
              new_val = this.getMasterByValueId(child, +v);
              if (new_val.length) {
                final_val = new_val;
              }
              if (last_val.length) {
                final_val = last_val;
              }
              if (last_val.length && new_val.length) {
                final_val = new_val.concat(last_val);
              }

              this.child_master_val[child.id] = final_val;
              if (child.has_linked_master) {
                this.linked_master = this.fnc.removeDuplicateKey(this.linked_master);// remove duplicate value
                fld = 'input_' + child.id;
                if ((!this.propertyForm.get('input_' + child.id).value && onload) || !onload) {
                  if(child.data_type == "single-select"){
                    this.propertyForm.patchValue({ [fld]: this.linked_master.length>0 ? this.linked_master[0] : '' });

                  }else if(child.data_type == "multi-select"){
                    this.propertyForm.patchValue({ [fld]: this.linked_master });

                  }
                  // this.propertyForm.patchValue({ [fld]: this.linked_master });
                }
              }
            });
          }
        });
      }
    }
  }

  changeChild(event, attrib, onload = false) {
    let val = event;
    if (val == 'addNewOption') {
      return;
    }
    if (val) {
      if (attrib.child.length) {
        let fld = null;
        attrib.child.forEach((child) => {
          this.linked_master = [];
          this.child_master_val[child.id] = [];
          if (child.data_type == "single-select" || child.data_type == "multi-select") {
            this.child_master_val[child.id] = this.getMasterByValueId(child, +val);
            if (child.has_linked_master) {
              fld = 'input_' + child.id;
              if ((!this.propertyForm.get('input_' + child.id).value && onload) || !onload) {

                if(child.data_type == "single-select"){
                  this.propertyForm.patchValue({ [fld]: this.linked_master.length>0 ? this.linked_master[0] : '' });

                }else if(child.data_type == "multi-select"){
                  this.propertyForm.patchValue({ [fld]: this.linked_master });

                }
                // this.propertyForm.patchValue({ [fld]: this.linked_master });
              }
            }
          }
        });
      }
    }
  }
  changeStatus(e) {
    this.property_status = e.value;
  }
  //GET ASSIGNMENT---------------------------------------------------------------------------
  getAssignment() {
    this.loader_assignment = true;
    let totalUsers = 0, no_property = 0;
    this.api.getEmsData(`assignments?sort_by=assignment_name&status=1&user_info=false&qaqc=true&assigned_to=${this.api.user_id}`)
      .subscribe(
        (data: any) => {
          this.loader_assignment = false;
          this.assignment_list = [];
          let label = "", assignData: any, user_arr = [];
          //change sort order
          data = this.fnc.assignmentSortOrder(data);
          data.forEach(e => {
            user_arr = [];
            totalUsers = 0;
            no_property = 0;
            // for (let x in e.assigned_to) {
            //   if (x && x != '0') {
            //     totalUsers++;
            //     no_property += e.assigned_to[x];
            //     user_arr.push(x);
            //   }
            // }
            if (e.assignment_label) {
              label = e.assignment_label[this.api.language] == undefined ? e.assignment_name : e.assignment_label[this.api.language];
            } else {
              label = e.assignment_name;
            }
            assignData = {
              id: e.assignment_id,
              name: e.assignment_name,
              assignment_id: e.assignment_id,
              AssignTo: e.assigned_to,
              PropertyNo: no_property,
              Users: totalUsers,
              user_arr: user_arr,
              label: label,
              lang: e.assignment_label,
              status: e.status,
              geom: e.geom,
              year: e?.year? e?.year:null
            }
            this.assignment_list.push(assignData);
          }, err => {
            this.loader_assignment = false;
          });
        }
      );
  }
  //GET ASSIGNMENT detail---------------------------------------------------------------------------
  getAssignmentDetail() {
    // getting user_detail from assignment
    let totalUsers = 0, no_property = 0, user_arr = [];
    this.api.getEmsData(`assignments/${this.selected_assignment.id}`)
      .subscribe({next: (asd: any) => {
        if (asd.assigned_to) {
          for (let x in asd.assigned_to) {
            if (x && x != '0') {
              totalUsers++;
              no_property += asd.assigned_to[x];
              user_arr.push(x);
            }
          }
        }
        this.selected_assignment.user_arr = user_arr;
        this.selected_assignment.Users = totalUsers;
        this.selected_assignment.PropertyNo = no_property;
        this.getUsers();
      },
        error:() => {
          this.notify.notify("Error getting assignment details", "error");
        }});
  }

  //GET USERS--------------------------------------------------------------------------------
  getUsers() {
    let assignUser;
    // let body = 'place_id=' + this.api.city_id;
    if (this.selected_assignment.user_arr.length) {
      assignUser = '&user_ids=' + this.selected_assignment.user_arr.toString();
    }
    this.user_list = [{
      id: 0,
      username: 'All'
    }];
    let img, userdetail: any;
    this.api.getUmsData(`users?place_id=${this.api.city_id}&order_by=first_name&sort_asc=true${assignUser}`)
      .subscribe(
        (data: any) => {
          this.listEnable = true;
          let users: any = data.data;
          users.forEach(e => {
            if (e.status == 1) {
              if (e.profile_image) {
                img = e.profile_image;
              } else {
                img = '../../../assets/images/user.png';
              }
              let fullname = (e.user_details) ? (e.user_details['1'].first_name) ? e.user_details['1'].first_name : '' + ' ' + (e.user_details['1'].last_name) ? e.user_details['1'].last_name : '' : ''
              userdetail = {
                id: e.user_id,
                imageURL: img,
                username: fullname,
                mail: e.primary_email_id,
                contact: e.phone_number
              }
              this.user_list.push(userdetail);
            }
          });
        }
      );
  }
  //GET USERS--------------------------------------------------------------------------------

  //GET GROUPS-------------------------------------------------------------------------------
  getGroups() {
    let url = "deadmin/" + this.entity_type + "/attributegroups?limit=500&status=1&offset=0&assignment_id=" + this.selected_assignment.id;
    if (this.entity_type == 'property_unit') {
      if (this.property_type_id) {
        url += "&property_type_ids=" + this.property_type_id;
      }
    }
    this.group_loader=true;
    this.displayedColumns = [];
    this.api.getEmsData(url)
      .subscribe({
        next:
        (data: any) => {
          this.group_loader=false;
          this.groups = [];
          if (data.length) {
            let groups = data;
            data?.forEach(element => {
              if (!element.parent_group_id) {
                let list = {
                  group_name: element.group_label[this.api.language] == undefined ? element.group_name : element.group_label[this.api.language],
                  childrens: [],
                  id: element.group_id,
                  name: element.group_name,
                  property_type_id: element.property_type_id,
                  labels: element.group_label,
                  description: element.description,
                  status: element.status,
                  parent_id: element.parent_group_id,
                  attributes_count: element.attribute_count,
                  sub_group_count: 0
                };
                let group_ctr = 0;
                let attribute_group_cout = 0;
                groups.forEach(sub => {
                  if (sub.parent_group_id == element.group_id) {
                    let sub_list = {
                      group_name: sub.group_label[this.api.language] == undefined ? sub.group_name : sub.group_label[this.api.language],
                      id: sub.group_id,
                      name: sub.group_name,
                      property_id: sub.property_type_id,
                      labels: sub.group_label,
                      description: sub.description,
                      status: sub.status,
                      parent_id: sub.parent_group_id,
                      attributes_count: sub.attribute_count
                    }
                    list.childrens.push(sub_list);
                    attribute_group_cout += sub.attribute_count;
                    list.sub_group_count = ++group_ctr; // counting sub group
                  }
                });
                list.attributes_count += attribute_group_cout;
                this.groups.push(list);
              }
            });
            // default selecting a group
            let grp = null;


            for (let i = 0; i < this.groups.length; i++) {
              if (this.groups[i].childrens.length == 0) {
                grp = this.groups[i];
                break;
              }
            }
            this.getGroupAttribute(grp);
            // if (this.selected_group == null && this.entity_type == 'property_unit') {
            //   this.getGroupAttribute(grp);
            // } else if(this.entity_type == 'property' || this.entity_type == 'property_floor'){
            //   this.getGroupAttribute(grp);
            // }
          }else{
            this.getGroupAttribute(null);
          }
        },
        error:() => {
          this.group_loader=false;
          this.groups = [];
          this.getGroupAttribute(null);
          // set basic column if no group
          // this.col_const = [
          //   { columnDef: 'action', header: 'Action', cell: (element: any) => `${element.action}` },
          //   { columnDef: 'user', header: 'User', cell: (element: any) => `${element.user}` },
          // ];
        }}
      );
  }

  settotalProp(status) {
    if (!status) return;
    if (status == '2,3,7') {
      let tcount = 0;
      let statuslist = [2, 3, 7];
      statuslist.map(ele => {
        if (this.statusCountvalues[ele]) {
          tcount += this.statusCountvalues[ele];
        }
      })
      this.total_property = tcount;
    } else {
      if (this.statusCountvalues[status]) {
        this.total_property = this.statusCountvalues[status];
      }
    }
  }
  selectAssignment(e) {
    this.resetStepToggle();
    this.selected_assignment = this.fnc.getArrayValue('assignment_id', e.source.value, this.assignment_list);
    this.getAssignmentDetail();
    this.getAttributes();
  }


  addStatus(event){
    if(event.checked == true){
      this.add_status_2=',-2';
    }else{
      this.add_status_2='';
    }
    if(this.entity_type == 'property'){
      this.getProperties();
    }
    if(this.entity_type == 'property_floor'){
      this.getFloors();
    }
    if(this.entity_type == 'property_unit'){
      this.getUnits();
    }
  }

  updateAllRecords(type, event) {


    // this.pageIndex = 0;
    //this.sideNav.close();
    if (type == 'assignment') {
      this.total_property = 0;
      this.entity_type = 'property';
      this.review_later = false;
      this.neigh_uid = null;
      this.block_uid = null;
      this.property_offset = 0;
      this.selectedPolygons = [];
      this.selectAssignment(event);
      this.sidenavTrigger(event)
    }
    if (type == 'user') {
      // this.neigh_uid = null;
      // this.block_uid = null;
      this.selectUser(event);
    }
    if (type == 'status') {
      // this.neigh_uid = null;
      // this.block_uid = null;
      this.settotalProp(event.value)
      this.selectedIds = [];
      this.selected_rows = [];
      this.property_offset = 0
      this.changeStatus(event);
    }
    if (type == 'property_type' && event && event.value) {
      // console.log('update all called', event);
      this.search_input = null;
      this.property_type_id = event.value;
      this.getAttributes(true);


    }
    this.getDataForPagin();
    if (this.entity_type == 'property') {
      this.pageIndex = 0;
      this.jumpForm.patchValue({
        page_number: 1
      })
      this.getProperties();
    }
    if (this.entity_type == 'property_floor') {
      this.pageIndex = 0;
      this.jumpForm.patchValue({
        page_number: 1
      })
      this.getFloors();
    }
    if (this.entity_type == 'property_unit') {
      this.pageIndex = 0;
      this.jumpForm.patchValue({
        page_number: 1
      })
      if (this.property_type_id && type != 'property_type') {
        this.getUnits();
      }
    }

  }

  selectUser(e) {
    this.selected_user_id = e.source.value;
  }

  /*Get attributes*/
  getAttributes(getUnits = false) {
    let url = "deadmin/" + this.entity_type + "/attributes?sort_by=sort_order&status=1&limit=10000&offset=0&assignment_id=" + this.selected_assignment.id;
    let desc = '', label = '', language: any;
    this.masters = [];
    this.attribute_list = [];
    if (this.entity_type == 'property_unit') {
      if (this.property_type_id) {
        url += "&property_type_ids=" + this.property_type_id;
      }
    }
    this.api.getEmsData(url)
      .subscribe({
        next: (data: any) => {
          this.getGroups();
          this.loader = false;
          if (!this.attribute_list.length) {
            data?.forEach(element => {
              if (element.attribute_name == 'property_name') {
                this.property_name_id = element.attribute_id;
              }
              desc = '', label = '';
              if (element.description) {
                desc = element.description[this.api.language] == undefined ? element.attribute_name : element.description[this.api.language];
              }
              if (element.attribute_label) {
                label = element.attribute_label[this.api.language] == undefined ? element.attribute_name : element.attribute_label[this.api.language];
              }

              // if(element.data_type == 'table'){
              if (element.master_id) this.table_master.push(element.master_id);
              // }
              let val = {
                name: element.attribute_name,
                label: label,
                description: desc,
                id: element.attribute_id,
                status: element.status,
                property_type_id: element.property_type_id,
                is_hidden: element.is_hidden,
                is_mandatory: element.is_mandatory,
                is_listable: element.is_listable,
                is_editable: element.is_editable,
                is_lang_dependent: element.is_lang_dependent,
                group_id: element.group_id,
                mutually_exclusive: (typeof element.mutually_exclusive_attributes == 'string') ? [element.mutually_exclusive_attributes] : element.mutually_exclusive_attributes,
                dependent: element.dependent_attributes,
                master_id: element.master_id,
                data_type: element.data_type,
                measure_type: element.measure_unit_type,
                default_value: element.default_value,
                parent_id: element.parent_attribute_id,
                measure_unit: element.measure_unit_code,
                quick_edit: element.quick_edit,
                has_linked_master: element.has_shared_master_values
              };
              this.attribute_list.push(val);
            });
            this.createFormGroup();
            setTimeout(() => {
              this.getMasterValue();
            }, 1000);
          }
          if (getUnits) {
            this.getUnits();
          }
        },
        error:(err) => {
          this.loader = false;
        }}
      );
  }


  checkIfTrue(e) {
    if (e == true) return true;
    if (e == false) return false;
  }
  isSelectedGroup(group) {
    if (!this.selected_group) return false;
    if (this.selected_group.id == group.parent_id) return true;
    if (this.selected_group.id == group.id) return true;
    return false;
  }

  //getting list of property type
  getPropertyType() {
    let unit_type_list: any = [];
    let url = "propertytypes?sort_by=property_type_name&status=1";
    this.api.getEmsData(url)
      .subscribe(
        (data: any) => {
          data?.forEach((v) => {
            unit_type_list.push(v.property_type_id);
          });
          this.unit_type_ids = unit_type_list.join();
        }
      );
  }

  group_selected(v) {
    this.group_clicked = v;

  }
/**
 * this a function to change group
 * @param group
 */
  getGroupAttribute(group) {
    // this.getDataForPagin();
    if (group) this.selected_group = group;
    let attr;
    if (group && group.id) {
      attr = this.fnc.getChildArray('group_id', group.id, this.customize_attribute);
    }
    this.displayedColumns = ['action','user'];
    this.col_const = [
      { columnDef: 'action', header: 'Action', cell: (element: any) => `${element.action}` },
      { columnDef: 'user', header: 'User', cell: (element: any) => `${element.user}` },
    ];
    this.columns = this.col_const;
    if (attr && attr.length) {
      let col = [];
      attr.map((c,k) => {
        if(k < (this.property_limit > 25 ? 2: attr.length) ) {
           col.push(c.name)
        }

      })
      this.displayedColumns = this.displayedColumns.concat(col);
      attr.forEach((v, k) => {
        if(k < (this.property_limit > 25 ? 2: attr.length) ) {
          this.columns.push({ columnDef: v.name, header: v.label, id: v.id, cell: (element: any) => `${element.id}` });
        }
      });
    }

  }

  /**
   * This is function to download demolish data
   */
  downloadDemolish(){

    if (!this.resource_access.GET) {
      this.notify.notify('You are not authorized to download demolish data', 'warn');
    }
    if(this.selected_assignment){
      this.loader = true;
      this.api
        .getCsvData(
          `download-demolish-data?assignment_id=${this.selected_assignment.id}`
        )
        .subscribe({
          next: (res: any) => {
            this.loader = false;
            if (res.url) {
              window.open(`https://${res.url}`, "_blank");
            } else {
              this.notify.notify(res?.message, "warn");
            }
          },
          error: () => {
            this.loader = false;
          },
        });
    }
  }
  /* Get master value */
  // getMasterValue(new_master_id = null, value = []) {
  //   let master_list = [], masterValueIds = null;
  //   if (new_master_id) {
  //     master_list.push(new_master_id);
  //     if (value) {
  //       masterValueIds = value.toString();
  //     }
  //   } else {
  //     this.master_val = [];
  //     this.masters.forEach((v) => {
  //       if (master_list.indexOf(v) == -1) {
  //         master_list.push(v);
  //       }
  //     });
  //   }
  //   if (master_list.length > 0) {
  //     master_list.forEach((v) => {
  //       if (v) {
  //         let url = this.entity_type + "/masters/" + v + "/values?sort_by=sort_order&limit=100&offset=0&status=1";
  //         if (this.property_type_id && this.entity_type == "property_unit") {
  //           url += "&property_type_ids=" + this.property_type_id;
  //         }
  //         if (masterValueIds) {
  //           url += '&master_value_ids=' + masterValueIds;
  //         }
  //         this.api.getEpsData(url)
  //           .subscribe((data: any) => {
  //             data.forEach(element => {
  //               let val = {
  //                 master_id: v,
  //                 name: element.master_value_name,
  //                 label: element.master_value_label[this.api.language] == undefined ? element.master_value_name : element.master_value_label[this.api.language],
  //                 id: element.master_value_id,
  //                 status: element.status,
  //                 property_type_id: element.property_type_id,
  //                 master_value_id: element.parent_master_value_id,
  //                 parent_master_value_ids: element.parent_master_value_ids
  //               };
  //               this.master_val.push(val);
  //             });
  //           });
  //       }
  //     });
  //   }
  // }


  getMasterValue(ids=null) {
    let master_list = [];
    // this.master_val = [];
    this.table_master.forEach((v) => {
      if (master_list.indexOf(v) == -1) {
        master_list.push(v);
      }
    });

    if (master_list && master_list.length > 0) {
      let url = this.entity_type + "/masters/values?sort_by=master_value_name&offset=0&status=1&master_ids=" + master_list.toString();
      if (this.property_type_id && this.entity_type == "property_unit") {
        url += "&property_type_ids=" + this.property_type_id;
      }
      if (Array.isArray(ids) && ids.length > 0) {
        url += '&master_value_ids='+ ids + '&limit='+ids.length;
      }else{
        url += '&limit=100'
      }
      this.api.getEmsData(url)
        .subscribe((data: any) => {
          data?.forEach(element => {
            let val = {
              label: element.master_value_label[this.api.language],
              labels: element.master_value_label,
              master_id: element.master_id,
              master_name: element.master_value_name,
              id: element.master_value_id,
              status: element.status,
              property_type_id: element.property_type_id,
              master_value_id: element.parent_master_value_id,
              parent_master_value_ids: element.parent_master_value_ids,
              name: element.master_value_label[this.api.language],  // adding this for drop down
              image_url: element.image_url
            };

            if(this.master_val.findIndex(ele=> ele.id == val.id) == -1){
              this.master_val.push(val);
            }

          });
        });
    }
  }

  getMasterValuesById(v, masterValueIds = null) {
    this.searchTerm = '';
    let m_val = this.getMasterById(v);
    let val_ids = [];
    let curMaster: any;

    if (Array.isArray(masterValueIds) && typeof masterValueIds[0] === 'object') {
      this.master_loader = false;
      return;
    }
    this.master_loader = true;
    if (masterValueIds) { // check if master value is in list or not
      masterValueIds = (masterValueIds.toString()).split(','); // convert into array
      for (let i = 0; i < masterValueIds.length; i++) {
        curMaster = this.fnc.getArrayValue('id', masterValueIds[i], m_val);
        if (this.fnc.jsonLength(curMaster) <= 0) {
          val_ids.push(masterValueIds[i]);
        }
      }
    }
    //if(m_val.length > 0 && masterValueIds == null) return;
    if (masterValueIds == null || val_ids.length == 0) return;
    let url = this.entity_type + "/masters/" + v + "/values?sort_by=master_value_name&limit=100&offset=0&status=1";
    if (this.property_type_id && this.entity_type == "property_unit") {
      url += "&property_type_ids=" + this.property_type_id;
    }
    url += '&master_value_ids=' + val_ids.toString();
    this.api.getEmsData(url)
      .subscribe({next:(data: any) => {
        data?.forEach(element => {
          let val = {
            label: element.master_value_label[this.api.language],
            labels: element.master_value_label,
            master_id: v,
            master_name: element.master_value_name,
            id: element.master_value_id,
            status: element.status,
            property_type_id: element.property_type_id,
            master_value_id: element.parent_master_value_id,
            parent_master_value_ids: element.parent_master_value_ids,
            name: element.master_value_label[this.api.language], // adding this for drop down
            image_url: element.image_url
          };
          if(this.master_val.findIndex(ele=> ele.id == val.id) == -1){
            this.master_val.push(val);
          }
          this.master_loader = false;
        });
      }, error:() => {
        this.master_loader = false;
      }});
  }

  getMasterById(master_id) {
    let record = [];
    record = this.fnc.getChildArray('master_id', master_id, this.master_val);
    return record;
  }

  getMasterByValueId(attrib, master_value_id) {
    let master_id = attrib.master_id;
    let record = [];
    let sub_master = this.fnc.getChildArray('master_id', master_id, this.master_val);
    if (attrib.has_linked_master) {
      record = sub_master;
      sub_master.forEach((v) => {
        if (v.parent_master_value_ids) {
          let ms = v.parent_master_value_ids.split(",");
          if (ms.indexOf('' + master_value_id) > -1) {
            this.linked_master.push(('' + v.id).trim());
          }
        }
      });
    } else {
      record = this.fnc.getChildArray('master_value_id', master_value_id, sub_master);
    }
    return record;
  }

  getChildMasterValue(attrib, parent) {
    let res = [];
    let id = attrib.id;
    if (parent.data_type == "single-select" || parent.data_type == "multi-select") {
      if (this.child_master_val[id]) {
        res = this.child_master_val[id];
      }
      return res;
    } else {
      return this.getMasterById(attrib.master_id);
    }
  }

  getProperties() {
    if (!this.resource_access.GET) {
      this.notify.notify("You're not authorized to view properties", 'warn');
      this.property_list = [];
      return;
    }
    let uids;
    this.entity_count.name = "Property: ";
    this.entity_count.count = 0;
    this.selectedIds = [];
    this.selected_rows = [];
    this.selected_property = null;
    this.selected_floor = null;
    this.loader = true;
    this.showUsers = false;
    let status = `${this.property_status}${(this.status_deleted) ? `,${this.status_deleted}` : ''}${(this.status_demolished) ? `,${this.status_demolished}` : ''}`;
    setTimeout(() => {
      this.setBreadCrumb();
    }, 100);
    let polys = null;
    if (this.selectedPolygons.length) {
      polys = this.selectedPolygons;
    }

    if (this._deleted.length && this.status_deleted == '-1') {
      uids = this._deleted.toString();
    }

    if (this._demolished.length && this.status_demolished == '-2') {
      uids = this._demolished.toString();
    }

    if (this.status_multi_parcel == 'multi_parcel' && this._multi_parcel.length > 0) {
      uids = this._multi_parcel.toString();
    }
    let url = 'getProperties';
    let body = {
      "moduleType": "deadmin",
      "assignmentName": this.selected_assignment.name,
      "assignmentId": this.selected_assignment.id,
      "listAllAttributes": true,
      "returnAttributeIds": true,
      "returnAttributeLabels": false,
      "returnDefaultValueForNulls": true,
      "returnGisProperties": false,
      "returnMasterValueIds": true,
      "returnMeasureUnits": false,
      "returnUserDetails": true,
      "showSeparateColumnForTableValues": false,
      "showTotalOfTableValues": false,
      "polygon": polys,
      "sortAsc": this.sortAsc,
      "sortBy": this.orderBy,
      "limit": this.property_limit,
      "offset": this.property_offset,
      "status": status+this.add_status_2,
      "propertyUid": uids
    }
    body["reviewLater"] = this.review_later;
    if(this.all_year){
      body['get_latest_property'] = 'Y'
    }else{
      body['get_latest_property'] = 'N'
    }
    if (this.search_input) {
      body["propertyUid"] = this.search_input;
      body["attributeValue"] = this.search_input;
    }
    if (this.selected_user_id) {
      body["assignedTo"] = this.selected_user_id;
    }
    if (this.block_uid) {
      body["blockUid"] = this.block_uid;
    }

    if (this.neigh_uid) {
      body["neighUid"] = this.neigh_uid;
    }
    this.getDataForPagin();
    this.api.postEpsData(url, body)
      .subscribe({
        next: (data: any) => {
        // this.config['totalItems'] = data.length;
        let pr_list = [], rec: property_list;
        let temp_list = [];
        this.loader = false;
        let revers_no = this.total_property - this.property_offset;
        data?.forEach((v) => {
          rec = {
            uid: v.property_uid,
            id: v.property_id,
            show: false,
            property_id: v.property_id,
            data: v.data,
            assigned_to_emailid: v.assigned_to_emailid,
            assigned_to_name: v.assigned_to_name,
            assigned_to: v.assigned_to,
            assignment_id: v.assignment_id,
            building_gis_uid: v.building_gis_uid,
            address_gis_uid: v.address_gis_uid,
            own_address: v.own_address,
            added_by: v.added_by,
            attribute_info: v.attribute_info,
            geom: v.geom,
            status: v.status,
            updated_date: v.updated_date,
            submitted_date: v.submitted_date,
            added_date: v.added_date,
            profile_image: v.profile_image,
            no_of_floors: (v.no_of_floors ? v.no_of_floors : 0),
            no_of_units: (v.no_of_units ? v.no_of_units : 0),
            lat: v.lat,
            lon: v.lon,
            revers_no: revers_no,
            updateAttribute: v.updatedAttributes || [],
          }
          revers_no--;
          // v['uid'] = v.property_uid;
          // v['show'] = false;
          pr_list.push(rec);
          temp_list.push(rec, { detailRow: true, element : rec })
        })
        this.property_list = pr_list;
        this.dataSource = temp_list;

        // if (!this.resource_access.GET) {
        //   this.property_list = [];
        // }
        //this.dataSource = this.property_list;
        //this.getRows(this.property_list);

      },
        error:(err) => {
          this.dataSource = [];
          this.loader = false;
          // this.getDataForPagin();
          this.notify.notify(this.fnc.getErrorMessage(err), 'error');
        }}
      );
  }

  getPropertyHistory(uid) {
    let info = this.fnc.getArrayValue('uid', uid, this.dataSource);
    this.loader = true;
    let url, rec: property_list;
    url = "deadmin/" + this.selected_assignment.name + "/properties/" + info.property_id + "/history";
    this.api.getEpsData(url)
      .subscribe({next:(data: any) => {
        let pr_list = [];
        this.loader = false;
        data?.forEach((v) => {
          rec = {
            uid: v.updated_date,
            id: v.property_id,
            added_date: v.added_date,
            show: true,
            property_id: v.property_id,
            data: v.data,
            assigned_to_emailid: v.assigned_to_emailid,
            assigned_to_name: v.assigned_to_name,
            assigned_to: v.assigned_to,
            assignment_id: v.assignment_id,
            building_gis_uid: null,
            address_gis_uid: null,
            own_address: null,
            added_by: v.added_by,
            attribute_info: v.attribute_info,
            geom: null,
            status: v.status,
            updated_date: v.updated_date,
            submitted_date: v.submitted_date,
            profile_image: v.profile_image,
            no_of_floors: (v.no_of_floors ? v.no_of_floors : 0),
            no_of_units: (v.no_of_units ? v.no_of_units : 0),
            lat: v.lat,
            lon: v.lon,
            revers_no: 0,
            updateAttribute: v.updatedAttributes,
          }

          // v['uid'] = v.property_uid;
          // v['show'] = false;
          pr_list.push(rec);
        })
        this.property_history = pr_list;
        if (!this.resource_access.GET) {
          this.property_history = [];
        }
        //this.dataSource = this.property_list;
        this.getHistoryRows(info, this.property_history, this.property_list);
      },
        error:(err) => {
          this.loader = false;
        }}
      );
  }
  getHistoryRows(info, history, list) {
    let rows = [];
    list.forEach((element) => {
      element.show = false;
      if (element.uid == info.uid && history.length) {
        let ctr = 0;
        history.forEach((e) => {
          if (ctr == 0) {
            rows.push(element, { detailRow: true, element: e });
          } else {
            rows.push({ detailRow: true, element: e });
          }
          ctr++;
        });
      } else {
        rows.push(element, { detailRow: true, element });
      }
      //rows.push(element, { detailRow: true,  element:element },{ detailRow: true,  element:element })

    }
    );
    setTimeout(() => {
      this.dataSource = rows;
    }, 10);

  }
  getRows(data) {
    let rows = [];
    data.forEach(element => rows.push(element, { detailRow: true, element }));
    this.dataSource = rows;
  }
  getIdentityName(row) {
    let name = '', detail = null, data = row.data;
    if (this.entity_type == 'property') {
      detail = this.fnc.getArrayValue('name', 'property_name', this.attribute_list);
    }
    if (this.entity_type == 'property_unit') {
      detail = this.fnc.getArrayValue('name', 'unit_number', this.attribute_list);
    }
    if (this.entity_type == 'property_floor') {
      detail = null;
      name = row.floor_name;
    }
    if (detail && data) {
      name = data[detail.id];
    }
    return name;
  }
  getLabel(v) {
    return v[this.api.language] == undefined ? v[1] : v[this.api.language];
  }

  getInitials(e) {
    let str = e;
    if (str) {
      let splited = str.split(' '), newStr: any = [], count = 0;
      if (splited.length >= 2) {
        splited.forEach((v) => {
          if (count < 2) {
            newStr.push(v.charAt(0));
            count++;
          }
        })
      } else {
        let char = splited[0];
        newStr.push(char.charAt(0))
        newStr.push(char.charAt(1))
      }
      let initials = newStr[0] + newStr[1];
      return initials;
    }
    return '';
  }
  //Get data type for header-----------------------------------------------
  getDataType(col) {
    let obj = this.fnc.getArrayValue('id', col, this.customize_attribute);
    return obj.data_type;
  }

  isInUpdated(id: number, newIdarr: any): boolean {
    if(newIdarr?.includes(id.toString())){
      return true
    }else{
      return false
    }
  }
  //**************TABLE Functions**************
  setUser(e) {

  }
  saveUser() {
    this.selected_row = null;
  }
  cancelUser() {
    this.selected_row = null;
  }
  assignUser(row) {
    this.selected_row = row;

  }
  checkUser(row) {
    if (!this.selected_row) return false;
    if (row.uid == this.selected_row.uid) {
      return true;
    }
    return false;
  }
  setStatus(row, col) {
  if((this.groups.length == 0 && !this.group_loader && !this.loader) &&  this.dataSource?.length){
    this.notify.notify("Groups/attributes are not configured. Please configure to update status","warn")
    return
  }
    this.selected_row = row.uid;
    this.selected_col = col;
    if (this.entity_type == 'property_floor' || this.entity_type == 'property_unit') {
      this.property_options = [
        { label: 'Reject "' + this.selected_group.group_name + '"', value: '7' },
        { label: 'Reject all groups', value: '8' }
      ];
      this.showStatus = !this.showStatus;
    }
    if (this.entity_type == 'property' && this.checkForEdit()) this.openDialog(row);
  }

  checkForEdit() {
    if (this.property_status == '2' || this.property_status == '3' || this.property_status == '7' || this.property_status == '2,3,7') return true;
    return false;

  }
  cancelStatus() {
    this.showStatus = false;
  }
  editView(row, col, attr_id = null) {
    this.viewMode = false;
    this.expBoxToggle = false;
    this.current_attribute = this.fnc.getArrayValue('id', attr_id, this.customize_attribute);
    this.selected_row = row;
    this.createFormElement();
    this.patchFormData();
    this.deleteToggle = false;
    this.showStatus = false;
    this.selected_col = col
    this.boxToggle = true;
    let property_detail = this.fnc.getArrayValue('uid', this.selected_row, this.dataSource);
    let propertydetail = property_detail.data;
    if(this.current_attribute?.child && this.current_attribute?.child.length > 0){
      this.current_attribute?.child.forEach(element => {
          this.getMasterValue(propertydetail[element.id]);
        });
    }
    if(this.current_attribute.data_type == "multi-select" || this.current_attribute.data_type == "single-select"){
      this.getMasterValuesById(this.current_attribute.master_id, propertydetail[attr_id]);
    }

  }
  deleteView(row, col, attr_id = null) {
    this.viewMode = false;
    this.expBoxToggle = false;
    this.selected_row = row;
    this.selected_col = col;
    this.showStatus = false;
    this.comment_rejected = null;
    this.current_attribute = this.fnc.getArrayValue('id', attr_id, this.customize_attribute);
    let info = this.fnc.getArrayValue('uid', this.selected_row, this.dataSource);
    if (info.attribute_info) {
      if (info.attribute_info[attr_id]) this.comment_rejected = info.attribute_info[attr_id].comment;
    }
    this.boxToggle = false;
    this.deleteToggle = true;
    // this.show = this.deleteToggle;
  }
  viewData(row, col, attr_id = null) {
    this.current_attribute = this.fnc.getArrayValue('id', attr_id, this.customize_attribute);
    this.selected_row = row;
    this.createFormElement();
    this.patchFormData();
    this.deleteToggle = false;
    this.expBoxToggle = false;
    this.showStatus = false;
    this.selected_col = col
    this.boxToggle = true;
    this.viewMode = true;
    this.propertyForm.disable();
  }

  // view history data for table type attribute
  viewHistoryData(row, attr_id = null) {
    this.history_view = [];
    this.current_attribute = this.fnc.getArrayValue('id', attr_id, this.customize_attribute);
    let data = row.data[attr_id];
    let result = {}, val = {}, obj = {}, arrobj = [];

    if (data) {
      data.forEach(d => {
        arrobj = [];
        result['master_label'] = this.getMasterLabel(d[0]);
        this.current_attribute.child.forEach(e => {
          val = {};
          val['child_label'] = e.label;
          if (e.data_type == "single-select") {
            val['value'] = this.getMasterLabel(d[e.id]);
          } else if (e.data_type == "multi-select") {
            val['value'] = this.getMasterLabel(d[e.id], true);
          } else {
            val['value'] = d[e.id];
          }
          arrobj.push(val);
        });
        result['attributes'] = arrobj;
        obj = JSON.parse(JSON.stringify(result))
        this.history_view.push(obj);
      });
    }
  }
  // getFlno(floor){
  //   if(floor.floor_id) return " - "+floor.sort_order;
  // }
  closeView() {
    this.boxToggle = false;
    this.deleteToggle = false;
    this.showStatus = false;
  }
  selectStatus(e) {
    if (e.value == 7) {
      this.rejectionType = 7;
    }
    if (e.value == 8) {
      this.rejectionType = 8;
    }
  }
  saveStatus() {
    let url, body: any = {}, attr = {}, attributes = [];
    let info = this.fnc.getArrayValue('uid', this.selected_row, this.dataSource);
    if (info.attribute_info) {
      attr = info.attribute_info;
    }
    if (info.attribute_info) {
      attr = info.attribute_info;
    }
    if (this.rejectionType == 7 && this.attribute_list.length) {
      attributes = this.fnc.getChildArray('group_id', this.selected_group.id, this.attribute_list);
    } else if (this.rejectionType == 8 && this.attribute_list.length) {
      attributes = this.attribute_list;
    }
    if (attributes.length) {
      attributes.forEach((c) => {
        attr[c.id] = {
          "comment": '',
          "status": -1
        }
      });
    }
    body = {
      "attribute_info": attr,
      "data": {},
      "updated_by": this.api.user_id,
      "status": 3
    }
    if (this.entity_type == 'property_floor') {
      url = "deadmin/" + this.selected_assignment.name + "/properties/" + this.selected_property.id + "/floors/" + info.id;
    }
    if (this.entity_type == 'property_unit') {
      url = "deadmin/" + this.selected_assignment.name + "/properties/" + this.selected_property.id + "/floors/" + this.selected_floor.id + "/units/" + info.id;
    }

    this.api.patchEpsData(url, body)
      .subscribe({next:(data: any) => {
        this.notify.notify('Information Updated', 'success');
        this.showStatus = false;
        if (this.entity_type == 'property_floor') {
          this.getFloors();
        }
        if (this.entity_type == 'property_unit') {
          this.getUnits();
        }
      }, error:() => {
        this.showStatus = false;
      }});
  }

  showMap(_rowData) {
    if (this._multi_parcel.indexOf(_rowData) == -1) {
      return false;
    } else {
      return true;
    }

  }

  saveView(type, status_attr = null) {
    if (type == 'rejected') {
      let url = '', attribute_info = {};
      let info = this.fnc.getArrayValue('uid', this.selected_row, this.dataSource);
      if (info.attribute_info) {
        attribute_info = info.attribute_info;
      }

      attribute_info[this.current_attribute.id] = {
        "comment": this.comment_rejected,
        "status": -1
      }
      if (this.current_attribute.child.length) {
        this.current_attribute.child.forEach((c) => {
          attribute_info[c.id] = {
            "comment": this.comment_rejected,
            "status": -1
          }
        })
      }
      if (this.current_attribute.mutual.length) {
        this.current_attribute.mutual.forEach((c) => {
          attribute_info[c.id] = {
            "comment": this.comment_rejected,
            "status": -1
          }
        })
      }
      let body = {
        "attribute_info": attribute_info,
        "data": {},
        "updated_by": this.api.user_id,
        "status": 3
      }
      if (this.entity_type == 'property') {
        url = "deadmin/" + this.selected_assignment.name + "/properties/" + info.property_id;
      }
      if (this.entity_type == 'property_floor') {
        url = "deadmin/" + this.selected_assignment.name + "/properties/" + this.selected_property.id + "/floors/" + info.id;
      }
      if (this.entity_type == 'property_unit') {
        url = "deadmin/" + this.selected_assignment.name + "/properties/" + this.selected_property.id + "/floors/" + this.selected_floor.id + "/units/" + info.id;
      }
      this.api.patchEpsData(url, body)
        .subscribe({next: (data: any) => {
          this.notify.notify("Information Updated", 'success');
          if (this.entity_type == 'property') {
            this.getProperties();
          }
          if (this.entity_type == 'property_floor') {
            this.getFloors();
          }
          if (this.entity_type == 'property_unit') {
            this.getUnits();
          }
        },
          error:(err) => {
            this.notify.notify(this.fnc.getErrorMessage(err), 'error');

          }});
    }
    if (type == 'saved') {
      // submitting form
      let e = $(this.elementRef.nativeElement);
      e.find("#property_form_btn").trigger('click');
    }
    setTimeout(() => {
      this.boxToggle = false;
      this.deleteToggle = false;
    })
  }

  isExpanded(data) {
    if (data.uid == this.selectedElementId) {
      return true;
    } else {
      return false;
    }
  }
  openDialog(selected_row) {
    let dialogRef = this.dialog.open(SubmitDialog, {
      width: '550px',
      panelClass: 'create-master-panel',
      data: {
        parent: this,
        row: selected_row
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  openBulkDialog() {
    let dialogRef = this.dialog.open(BulkApproveProperty, {
      width: '350px',
      panelClass: 'create-master-panel',
      data: { parent: this }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  callHistory(uid) {
    if (this.entity_type == 'property') {
      this.getPropertyHistory(uid);
    }
    if (this.entity_type == 'property_floor') {
      this.getFloorHistory(uid);
    }
    if (this.entity_type == 'property_unit') {
      this.getUnitHistory(uid);
    }
  }

  toggleRow(value) {
    let flag = true;
    this.dataSource.forEach(e => {
      if (e.hasOwnProperty('detailRow')) {
        e.element.show = false;
      }
    });
    if (this.selectedElementId != value.uid) {
      this.dataSource.forEach(e => {
        if (e.hasOwnProperty('detailRow')) {
          if (e.element.id == value.id) {
            if (flag) {
              this.callHistory(value.uid);
              this.selectedElementId = value.uid;
              flag = false;
            }
            e.element.show = true;
          }
        }
      });
    } else {
      this.selectedElementId = null;
    }
  }
  getUsername(data) {
    return data.assigned_to_name;
  }
  getImageUrl(data) {
    return '../assets/images/user2.png';
  }
  getStatus(data) {
    let status = 'review';
    if (data.status == '5') {
      status = 'approved';
    }
    if (data.status == '2') {
      status = 'review';
    }
    if (data.status == '4') {
      status = 'rework';
    }
    if (data.status == '3') {
      status = 'rework animate';
    }
    if (data.status == '7') {
      status = 'resubmit';
    }
    return status;
  }
  switch(row) {
    let entity = this.entity_type;
    if (entity == 'property_unit') {
      this.pageIndex = this.unit_index
      this.selected_unit = row;
      this.openUnitMap();
      if (this.sideUnitMapNav) {
        this.share.unit_map_obj.selected_unit = row;
        this.share.unit_map_obj.setColorunitLayout();
      }
      return;
    }
    if (entity == 'property') {
      this.pageIndex = this.property_index
      this.switchEntity('property_floor', row.uid);
    }
    if (entity == 'property_floor') {
      this.pageIndex = this.floor_index
      this.mapView = null;
      this.switchEntity('property_unit', row.uid);
    }

  }
  refreshToggle() {
    this.showStepToggle = false;
    setTimeout(() => {
      this.showStepToggle = true;
    }, 10);
  }

  switchEntity(entity, uid = null) {

    this.refreshToggle();
    this.selectedIds = [];
    this.selected_rows = [];
    this.entity_type = entity;
    this.resetPaginator();
    this.search_input = null;
    if (uid) this.selected_row = uid;

    // this.property_type_id = null;
    let info = this.fnc.getArrayValue('uid', this.selected_row, this.dataSource);
    this.status_deleted = ''
    this.status_demolished = ''
    this.status_multi_parcel = ''
    if (entity == 'property') {
      this.getDataForPagin();
      this.property_type_id = null;
      this.entity_count.name = "Property: ";
      this.entity_count.count = 0;
      this.property_offset = 0;
      this.pageIndex = 0;
      this.selected_property = null;
      this.getAttributes();
      this.getProperties();
      this.sidesideUnitMapNavTrigger(null);
      this.sidePNevTrigger(null);
      this.jumpForm.patchValue({
        page_number: 1
      })

    }
    if (entity == 'property_floor') {
      this.property_type_id = null;
      this.property_type_list = [];
      this.entity_count.name = "Floors: ";
      this.entity_count.count = 0;
      this.property_offset = 0;
      this.pageIndex = 0;
      this.jumpForm.patchValue({
        page_number: 1
      })
      if (!this.selected_property) this.selected_property = info;
      this.getDataForPagin();
      this.selected_floor = null;
      this.getAttributes();
      this.getFloors();
      this.sidesideUnitMapNavTrigger(null);
      this.sidePNevTrigger(null);

    }
    if (entity == 'property_unit') {

      this.entity_count.name = "Units: ";
      this.entity_count.count = 0;
      this.property_offset = 0;
      this.pageIndex = 0;
      this.selected_floor = info;
      this.getDataForPagin();
      this.jumpForm.patchValue({
        page_number: 1
      })
    }


    this.displayedColumns = [];
    this.dataSource = [];
    this.groups = [];
    this.selectedElementId = null;
    setTimeout(() => {
      this.setBreadCrumb();
    }, 100);
  }

  //get count for all entity
  getDataForPagin(getcount = true) {
    let url, status = '2,3,4,5,7'+this.add_status_2, assigned_to = "", block_uid = '', neigh_uid = '', property_type = "";

    if (this.property_type_id && this.entity_type == 'property_unit') property_type = '&property_type_ids=' + this.property_type_id;
    // if (this.property_status == '2,3,7') { status = '2,3,7' }

    if (this.entity_type == 'property') {
      if (this.selected_user_id) {
        assigned_to = "&assigned_to=" + this.selected_user_id;
      }
      if (this.neigh_uid) {
        neigh_uid = '&neigh_uid=' + this.neigh_uid;
      }
      if (this.block_uid) {
        block_uid = '&block_uid=' + this.block_uid;
      }
      url = "deadmin/" + this.selected_assignment.name + "/count?sort_asc=false&action=property_count" + property_type + "&status=" + status + assigned_to + neigh_uid + block_uid;

    } else if (this.entity_type == 'property_floor') {
      let property_id = "&property_id=" + this.selected_property.id
      url = "deadmin/" + this.selected_assignment.name + "/count?sort_asc=false&action=floor_count" + property_type + "&status=" + status + assigned_to + neigh_uid + block_uid + property_id;
    } else if (this.entity_type == 'property_unit') {
      let property_id = "&property_id=" + this.selected_property.id
      let floor_id = "&floor_id=" + this.selected_floor.id;
      url = "deadmin/" + this.selected_assignment.name + "/count?sort_asc=false&action=unit_count" + property_type + "&status=" + status + assigned_to + neigh_uid + block_uid + property_id + floor_id;
    }

    //count of deleted and demolished as per status
    if (this.status_deleted) {
      if (this._deleted.length > 0) url += `&property_uid=${this._deleted.toString()}`;
    }
    if (this.status_demolished) {
      if (this._demolished.length > 0) url += `&property_uid=${this._demolished.toString()}`;
    }
    if (this.status_multi_parcel && this.entity_type == 'property') {
      if (this._multi_parcel.length > 0) {
        url += `&property_uid=${this._multi_parcel.toString()}`;
      }
    }
    if (this.review_later && this.entity_type == 'property') {
      if (this._review_later.length > 0) {
        url += `&property_uid=${this._review_later.toString()}`;
      }
    }
    if(this.all_year){
      url += '&get_latest_property=Y';
    }else{
      url += '&get_latest_property=N';
    }
    if(this.search_input){
      url += `&propertyUid=${this.search_input}&attributeValue=${this.search_input}`
    }

    this.api.getEpsData(url).subscribe({next:(data: any) => {
      if (data.data) {
        let countData = data.data;
        if (data.data.status) this.statusCountvalues = data.data.status;
        let count = countData.count;
        //saving deleted and demolished properties in a global variable only if it returns an Array
        if (Array.isArray(countData.deleted)) {
          this._deleted = countData.deleted;
        } else {
          this._deleted = [];
        }
        if (Array.isArray(countData.demolished)) {
          this._demolished = countData.demolished;
        } else {
          this._demolished = [];
        }
        if (Array.isArray(countData.multi_parcel)) {
          this._multi_parcel = countData.multi_parcel;
        } else {
          this._multi_parcel = [];
        }
        if (Array.isArray(countData.review_later)) {
          this._review_later = countData.review_later;
        } else {
          this._review_later = [];
        }


        if (this.entity_type == 'property') {
          this.entity_count.count = count;
          if (this.property_status) {
            this.settotalProp(this.property_status);
          } else {
            this.settotalProp('2,3,7');
          }

          if (assigned_to == '' && property_type == '' && (this.neigh_uid == null || this.neigh_uid == '')) {
            this.neighbourhoodList = countData.neighbourhood;
            this.filteredNeighList = countData.neighbourhood;
          }
        }
        if (this.entity_type == 'property_floor') {
          this.entity_count.count = count;
          // this.entity_count.name = "Floor";
        }
        if (this.entity_type == 'property_unit') {
          this.entity_count.count = count;
          if (countData && countData.property_type) {
            // converting json key value pair to array
            if (this.property_type_list == 0) {
              this.property_type_list = Object.keys(countData.property_type).map(e => {
                let ret = {};
                ret['id'] = e;
                ret['name'] = countData.property_type[e];
                return ret;
              });

            }
            if (this.property_type_list.length && !this.property_type_id) this.property_type_id = this.property_type_list[0].id;

          }
          if (this.property_type_id && getcount) {
            // console.log('called from pagin');

            this.getAttributes(getcount);
          }
        }
      }

    }, error:() => {
      this.entity_count.count = 0;
    }})
  }

  public statusCount(key: any) {
    if (key == '2,3,7') {
      let tcount = 0;
      let statuslist = [2, 3, 7];
      statuslist.map(ele => {
        if (this.statusCountvalues[ele]) {
          tcount += this.statusCountvalues[ele];
        }
      })
      return tcount
    }
   else{
    if (this.statusCountvalues[key]) {
      return this.statusCountvalues[key]
    } else {
      return 0;
    }
  }
  }

  resetPaginator() {
    // this.pageIndex = 1;
    if (this.entity_type == 'property') {

      this.pageIndex = this.property_index;
      this.property_offset = (this.pageIndex - 1) * this.property_limit;
      this.property_limit = 10;

    };
    if (this.entity_type == 'property_floor') {
      this.pageIndex = this.floor_index;
      this.property_offset = (this.pageIndex - 1) * this.property_limit;
      this.property_limit = 200;
    };
    if (this.entity_type == 'property_unit') {
      this.pageIndex = this.unit_index;
      this.property_offset = (this.pageIndex - 1) * this.property_limit;
      this.property_limit = 10;
    }


  }
  pageAction(e) {
    if(e == 1) this.getGroupAttribute(this.selected_group);
    this.pageIndex = e;
    this.property_offset = (this.pageIndex - 1) * this.property_limit;
    this.jumpForm.patchValue({
      page_number: this.pageIndex
    })
    if (this.entity_type == 'property') {
      this.property_index = this.pageIndex
      this.getProperties();

    };
    if (this.entity_type == 'property_floor') {
      this.floor_index = this.pageIndex
      this.getFloors();
    };
    if (this.entity_type == 'property_unit') {
      this.unit_index = this.pageIndex
      this.getUnits();
    }

  }

  jumpTo(fomrVal: any) {
    if (fomrVal) {
      this.pageAction(fomrVal.page_number)
    }
  }
  createFormGroup() {
    let val = {}, new_list = [];
    let mtl = [];
    this.attribute_list.forEach((v) => {
      val = {
        id: v.id,
        name: v.name,
        parent_id: v.parent_id,
        label: v.label,
        data_type: v.data_type,
        measure_type: v.measure_type,
        measure_unit: v.measure_unit,
        default_value: v.default_value,
        is_mandatory: v.is_mandatory,
        is_hidden: v.is_hidden,
        is_editable: v.is_editable,
        status: v.status,
        mutually_exclusive: v.mutually_exclusive,
        master_id: v.master_id,
        has_linked_master: v.has_linked_master,
        dependent: v.dependent,
        group_id: v.group_id,
        mutual: [],
        child: []
      }
      new_list.push(val);
    });
    new_list.forEach((v) => {
      if (v.mutually_exclusive) {
        v.mutually_exclusive.forEach((m) => {
          this.used_attribute.push(m);
          mtl.push(this.fnc.getArrayValue('id', m, new_list));
        });
        v.mutual = mtl;
      }
      if (v.parent_id) {
        this.used_attribute.push(v.id);
        let index = this.fnc.getArrayindex('id', v.parent_id, new_list);
        if (index !== null) {
          new_list[index].child.push(v);
        }
      }
    });
    this.group_attribute = new_list;
    this.customize_attribute = this.fnc.getDeletedArray('id', this.used_attribute, new_list); // remove duplicate list
  }

  getAttributeDetail(id) {
    return this.fnc.getArrayValue('id', id, this.attribute_list);
  }

  /**
   * check the text is URL or Not
   * @param urlString
   * @returns
   */
  public isValidUrl(urlString: string): boolean {
    try {
      if(/^((http|https):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(urlString)) {
        return true;
      } else {
          return false;
      }
    } catch (error) {
      return false;
    }
  }

  getChildValue(row, id) { // for single child value
    let childs = [], value = '';
    let attr_detail = this.fnc.getArrayValue('id', id, this.customize_attribute);
    if (attr_detail) {
      if (attr_detail.child && attr_detail.data_type != 'table') {
        attr_detail.child.forEach((v) => {
          childs.push(v);
          if (row.data[v.id]) {
            if (v.data_type != 'single-select' && v.data_type != "multi-select") value += '/' + row.data[v.id];
            if (v.data_type == 'single-select') value += '/' + this.getMasterLabel(row.data[v.id]);
            if (v.data_type == "multi-select") value += '/' + this.getMasterLabel(row.data[v.id], true);
          }
        });
      }
      if (attr_detail.mutual && attr_detail.data_type != 'table') {
        attr_detail.mutual.forEach((v) => {
          if (row.data[v.id]) {
            if (v.data_type != 'single-select' && v.data_type != "multi-select") value += '/' + row.data[v.id];
            if (v.data_type == 'single-select') value += '/' + this.getMasterLabel(row.data[v.id]);
            if (v.data_type == "multi-select") value += '/' + this.getMasterLabel(row.data[v.id], true);
          }
        });
      }
    }


    return value;
  }
  getChildLabel(id) {
    let childs = '';
    let attr_detail = this.fnc.getArrayValue('id', id, this.customize_attribute);
    if (attr_detail) {
      if (attr_detail.child && attr_detail.data_type != 'table') {
        attr_detail.child.forEach((v) => {
          childs += "--" + v.label;
        });
      }
      if (attr_detail.mutual && attr_detail.data_type != 'table') {
        attr_detail.mutual.forEach((v) => {
          if (v.label) childs += "/" + v.label;
        });
      }
    }
    return childs;
  }


  //**************TABLE Functions**************
  ngAfterViewinit() {

  }

  getAttributeChanges(selected_property) {
    if (selected_property.attribute_info) {
      if (selected_property.attribute_info.new_floor_ids) {
        this.new_floors_list = selected_property.attribute_info.new_floor_ids;
      }
      if (selected_property.attribute_info.new_unit_ids) {
        this.new_units_list = selected_property.attribute_info.new_unit_ids;
      }
    }
  }

  // start getting data for floor
  getFloors(iscount=true) {
    this.getAttributeChanges(this.selected_property);
    let rec: floor_list;
    this.loader = true;
    this.floor_list = [];
    //let status = this.property_status;
    let status = `${this.property_status}${(this.status_deleted) ? `,${this.status_deleted}` : ''}${(this.status_demolished) ? `,${this.status_demolished}` : ''}`;
    let search_param = "", search_by_name = "";
    if (this.search_input) {
      search_param = "&floor_uid=" + this.search_input;
      search_by_name = "&attribute_value=" + this.search_input;
      this.property_offset = 0;
    }
    if (this._deleted.length && this.status_deleted == '-1') {
      search_param = `&floor_uid=${this._deleted.toString()}`;
    }

    if (this._demolished.length && this.status_demolished == '-2') {
      search_param = `&floor_uid=${this._demolished.toString()}`;
    }
    let url = 'deadmin/' + this.selected_assignment.name + '/properties/' + this.selected_property.id + '/floors?limit=' + this.property_limit + '&offset=' + this.property_offset + search_param + '&sort_by=' + this.orderByFloor + '&return_master_value_ids=true&sort_asc=' + this.sortfloor + '&list_all_attributes=true&return_attribute_ids=true&return_user_details=true' + search_by_name + '&status=' + status+this.add_status_2;
    if(this.all_year){
      url += '&get_latest_property=Y';
    }else{
      url += '&get_latest_property=N';
    }
    this.api.getEpsData(url)
      .subscribe({next:(data: any) => {
        this.loader = false;
        if(data && data.length){
          this.floor_list = [];
          let temp_list=[];
          let serial_no = this.property_offset;
          data.forEach((v) => {
            rec = {
              uid: v.floor_uid,
              id: v.floor_id,
              show: false,
              floor_id: v.floor_id,
              data: v.data,
              added_date: v.added_date,
              attribute_info: v.attribute_info,
              floor_name: v.floor_name,
              floor_type_name: v.floor_type_name,
              no_of_units: v.no_of_units,
              status: v.status,
              sort_order: v.sort_order,
              assigned_to_emailid: v.assigned_to_emailid,
              assigned_to_name: v.assigned_to_name,
              assigned_to: v.assigned_to,
              updated_date: v.updated_date,
              submitted_date: v.submitted_date,
              profile_image: v.profile_image,
              floor_gis_uid: v.floor_gis_uid,
              revers_no: serial_no + 1,
              updateAttribute: v.updatedAttributes || [],
            };
            serial_no ++;
            this.floor_list.push(rec);
            console.log(this.floor_list, ' this.floor_list 3440');

            temp_list.push(rec, { detailRow: true, element : rec })
          });
          //this.getRows(this.floor_list);
          this.dataSource = temp_list;
           if(iscount) {this.getDataForPagin()}
        }else{
          this.floor_list = [];
          this.dataSource =[];
        }
      },
        error:() => {
          this.loader = false;
          this.dataSource = [];
          if(iscount){ this.getDataForPagin()}
        }}
      );
  }
  getFloorHistory(uid) {
    this.loader = true;
    let info = this.fnc.getArrayValue('uid', uid, this.dataSource);
    let rec: floor_list;
    this.floor_history = [];
    let url = 'deadmin/' + this.selected_assignment.name + '/properties/' + this.selected_property.id + '/floors/' + info.id + '/history';
    this.api.getEpsData(url)
      .subscribe({next:(data: any) => {
        this.loader = false;
        if(data && data.length){
          let serial_no = this.property_offset;
          data.forEach((v) => {
            rec = {
              uid: v.updated_date,
              id: v.floor_id,
              show: true,
              floor_id: v.floor_id,
              added_date: v.added.date,
              data: v.data,
              attribute_info: v.attribute_info,
              floor_name: info.floor_name,
              floor_type_name: info.floor_type_name,
              no_of_units: null,
              status: v.status,
              sort_order: null,
              assigned_to_emailid: v.assigned_to_emailid,
              assigned_to_name: v.assigned_to_name,
              assigned_to: v.assigned_to,
              updated_date: v.updated_date,
              submitted_date: v.submitted_date,
              profile_image: v.profile_image,
              floor_gis_uid: v.floor_gis_uid,
              revers_no : 0,
              updateAttribute: v.updatedAttributes || [],
            };
            serial_no++;
            this.floor_history.push(rec);
          });
          this.getHistoryRows(info, this.floor_history, this.floor_list);
        }else{
          this.floor_history =[];
        }
      },
        error:() => {
          this.loader = false;
        }}
      );
  }
  getDate(d) {
    if (d) {
      return this.fnc.formatDateUTC(new Date(d));
    }else{
      return '';
    }
  }
  getUnits() {
    this.getAttributeChanges(this.selected_property);
    let rec: unit_list;
    this.loader = true;
    //status = this.property_status;
    let status = `${this.property_status}${(this.status_deleted) ? `,${this.status_deleted}` : ''}${(this.status_demolished) ? `,${this.status_demolished}` : ''}`;
    let search_param = "", search_by_name = "";
    if (this.search_input) {
      search_param = "&unit_uid=" + this.search_input;
      search_by_name = "&attribute_value=" + this.search_input;
      this.property_offset = 0;
    };
    if (this._deleted.length && this.status_deleted == '-1') {
      search_param = `&unit_uid=${this._deleted.toString()}`;
    }

    if (this._demolished.length && this.status_demolished == '-2') {
      search_param = `&unit_uid=${this._demolished.toString()}`;
    }
    let url = 'deadmin/' + this.selected_assignment.name + '/properties/' + this.selected_property.id + '/floors/' + this.selected_floor.id + '/units?limit=' + this.property_limit + '&offset=' + this.property_offset + search_param + '&sort_by=' + this.orderByUnit + '&return_master_value_ids=true&sort_asc=' + this.sortunit + '&list_all_attributes=true&return_user_details=true&return_attribute_ids=true&property_type_ids=' + this.property_type_id + search_by_name + '&status=' + status+this.add_status_2;
    let serial_no = this.property_offset;
    if(this.all_year){
      url += '&get_latest_property=Y';
    }else{
      url += '&get_latest_property=N';
    }
    this.api.getEpsData(url)
      .subscribe({next: (data: any) => {
        this.unit_list = [];
        let temp_list=[];
        this.loader = false;
        if(data && data.length){
        data.forEach((v) => {
          rec = {
            uid: v.unit_uid,
            id: v.unit_id,
            show: false,
            unit_id: v.unit_uid,
            added_date: v.added_date,
            data: v.data,
            attribute_info: v.attribute_info,
            property_type_name: v.property_type_name,
            property_type_id: v.property_type_id,
            unit_type_label: v.unit_type_label,
            unit_type_name: v.unit_type_name,
            status: v.status,
            assigned_to_emailid: v.assigned_to_emailid,
            assigned_to_name: v.assigned_to_name,
            assigned_to: v.assigned_to,
            updated_date: v.updated_date,
            submitted_date: v.submitted_date,
            profile_image: v.profile_image,
            unit_gis_uid: v.unit_gis_uid,
            revers_no: serial_no + 1,
            updateAttribute: v.updatedAttributes || [],
          };
          serial_no++;
          this.unit_list.push(rec);
          temp_list.push(rec, { detailRow: true, element : rec })
        });
        //this.getRows(this.unit_list);
        this.dataSource = temp_list;
        this.getDataForPagin(false);
      }else{
        this.dataSource =[];
      }
      },
        error:() => {
          this.unit_list = [];
          this.loader = false;
          this.dataSource = [];
        }}
      );
  }
  getUnitHistory(uid) {
    let info = this.fnc.getArrayValue('uid', uid, this.dataSource);
    let rec: unit_list;
    this.unit_history = [];
    this.loader = true;
    let url = 'deadmin/' + this.selected_assignment.name + '/properties/' + this.selected_property.id + '/floors/' + this.selected_floor.id + '/units/' + info.id + '/history';
    this.api.getEpsData(url)
      .subscribe({next:(data: any) => {
        this.loader = false;
        let serial_no = this.property_offset;
        data?.forEach((v) => {
          rec = {
            uid: v.updated_date,
            id: v.unit_id,
            show: true,
            unit_id: v.unit_uid,
            added_date: v.added_date,
            data: v.data,
            attribute_info: v.attribute_info,
            property_type_name: info.property_type_name,
            property_type_id: info.property_type_id,
            unit_type_label: info.unit_type_label,
            unit_type_name: info.unit_type_name,
            status: v.status,
            assigned_to_emailid: info.assigned_to_emailid,
            assigned_to_name: v.assigned_to_name,
            assigned_to: v.assigned_to,
            updated_date: v.updated_date,
            submitted_date: v.submitted_date,
            profile_image: v.profile_image,
            unit_gis_uid: v.unit_gis_uid,
            revers_no : 0,
            updateAttribute: v.updatedAttributes || [],
          };
          serial_no++;
          this.unit_history.push(rec);
        });
        this.getHistoryRows(info, this.unit_history, this.unit_list);
      },
        error:() => this.loader = false
      }
      );
  }

  /**
   * get latest property
   */
  getNewProperty(event){

    if(event.checked == true){
      this.all_year =true;
    }else{
      this.all_year =false;
    }
    this.property_offset = 0;
    this.pageIndex=0;
    if (this.entity_type == 'property') {
      this.getProperties();

    };
    if (this.entity_type == 'property_floor') {
      this.getFloors();
    };
    if (this.entity_type == 'property_unit') {
      this.unit_index = this.pageIndex
      this.getUnits();
    }

  }

  /**
   * sync history data from QAQC page
   */
  historySync(){

    this.syncData('property', this.selected_assignment.year);
    this.syncData('floor', this.selected_assignment.year);
    this.syncData('unit', this.selected_assignment.year);
  }

  syncData(selected_type:string, selected_year) {

    let url = `sync-history-data?type=${selected_type}&year=${selected_year}`;
    this.loader = true;
    this.api.getEpsData(url).subscribe({
      next: (res: any) => {
        this.loader = false;
        this.notify.notify(selected_type + " " + res.message, "success");
      },
      error: () => {
        this.loader = false;
      },
    });
  }

  ngOnInit() {
    this.searchField = new FormControl();
    this.searchField.valueChanges
      .pipe(debounceTime(500))
      .subscribe(term => {
        if (this.selected_assignment === undefined || this.selected_assignment === null) {
          this.notify.notify("Please select assignment", "Dismiss")
          return;
        }
        this.search_input = term.trim();
        if (this.entity_type == 'property') {
          this.getProperties();
        } else if (this.entity_type == 'property_floor') {
          this.getFloors();
        } else if (this.entity_type == 'property_unit') {
          this.getUnits();
        }

      });

    this.jumpForm = this.fb.group({
      page_number: [1]
    })
  }

  sidenavTrigger(event) {
    if (this.sideNav) this.sideNav.close();
    //this.mapView = null;
  }

  sidePNevTrigger(event) {
    if (this.sidePNev) this.sidePNev.close();
    this.mapView = null;
  }
  sidesideUnitMapNavTrigger(event) {
    this.refreshToggles();
    if (this.sideUnitMapNav) this.sideUnitMapNav.close();
    //this.mapView = null;
  }

  openMap(data) {

    this.rowData = data;
    this.mapView = 'address';
    setTimeout(() => {
      if (this.sideNav) {
        this.sideNav.open()
      };
      // console.log(this.sideNav);
    }, 300);


  }

  openParcelMap(data) {
    //this.sideNav.close();
    this.rowData = data;
    this.mapView = 'parcel';
    setTimeout(() => {
      this.sidePNev.open();
    }, 200);
  }
  openUnitMap() {
    this.mapView = 'unit_map';
    setTimeout(() => {
      this.sideUnitMapNav.open();
    }, 200);
  }

  getBlocksByNeigh(val) {
    this.neigh_uid = val.value;
    this.block_uid = null;
    if (val.value == 0) {
      this.blockList = [];
      this.filteredBlockList = [];
      this.neigh_uid = null;
    }

    if (this.neigh_uid) this.getBlock(this.neigh_uid);
    this.getProperties();
  }
  getStreetsByBlock(val) {
    this.block_uid = val.value;
    this.getProperties();
  }

  getBlock(id) {
    let blockList = [];
    let blockDetail = this.fnc.getArrayValue('neigh_uid', id, this.neighbourhoodList);
    if (blockDetail) {
      if (blockDetail.block && blockDetail.block.length) {
        blockList = blockDetail.block;
        this.filteredBlockList = blockList;
        this.blockList = blockList;
      }
    }
  }

  find(type) {
    let tempArr = [], lower, term;
    if (type == 'neigh') {
      term = this.searchTerm.trim().toLowerCase();
      this.neighbourhoodList.forEach(v => {
        let val = v['neigh_name'];
        if (val) {
          lower = val.toLowerCase();
          if (lower.includes(term)) {
            tempArr.push(v);
          }
        }
      });
      this.filteredNeighList = tempArr;
    }
    if (type == 'block') {
      term = this.searchTerm.trim().toLowerCase();
      this.blockList.forEach(v => {
        let val = v['block_name'];
        if (val) {
          lower = val.toLowerCase();
          if (lower.includes(term)) {
            tempArr.push(v);
          }
        }
      });
      this.filteredBlockList = tempArr;
    }
    if (type == 'street') {
      term = this.searchTerm.trim().toLowerCase();
      this.streetList.forEach(v => {
        let val = v['street_name'];
        if (val) {
          lower = val.toLowerCase();
          if (lower.includes(term)) {
            tempArr.push(v);
          }
        }
      });
      this.filteredStreetList = tempArr;
    }
  }

  getItemCount(row, id) {
    let val = row.data[id];
    return (val && val.length) ? val.length : 0;
  }

  openGallery(mode, row, id) {
    if (mode == 'images') {
      if (!this.image_access.GET) {
        this.notify.notify("User not authorized to VIEW Images for Property", 'warn', 5000);
        return;
      }
    }
    if (mode == 'videos') {
      if (!this.image_access.GET) {
        this.notify.notify("User not authorized to VIEW Videos for Property", 'warn', 5000);
        return;
      }
    }
    let cellData;
    // let attr_info = this.fnc.getArrayValue('id', id, this.customize_attribute);
    cellData = {
      data: row.data[id],
      id: id,
      row: row
    }
    let dialogRef = this.dialog.open(GalleryDialog, {
      width: '1000px',
      maxHeight: '95vh',
      minHeight: '55vh',
      minWidth: '70vw',
      panelClass: 'create-master-panel',
      data: {
        parent: this,
        cellData: cellData,
        mode: mode
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public downloadProperties() {
    let url = "properties/uploads/template?get_data=2&module_type=deadmin";
    if(this.all_year){
      url += '&get_latest_property=Y';
    }else{
      url += '&get_latest_property=N';
    }
    // if (!this.property_access.GET) {
    //   this.notify.notify("User not Authorized to download Data","Dismiss")
    //   return;
    // }
    if (this.selected_assignment == null) {
      this.notify.notify("Please select assignment", 'warn');
      return;
    }
    if(this.dataSource?.length > 0){
    }else{
      this.notify.notify("Property not found", 'warn');
      return;
    }
    this.downloadLoader = true;
    if (this.selected_assignment) url += "&assignment_id=" + this.selected_assignment.id;
    if (this.property_type_id) {
      url += "&property_type_ids=" + this.property_type_id;
    } else {
      if (this.unit_type_ids) url += "&property_type_ids=" + this.unit_type_ids;
    }
    if (this.neigh_uid) url += '&neigh_uid=' + this.neigh_uid;
    if (this.block_uid) url += '&block_uid=' + this.block_uid;
    url +='&review_later='+ this.review_later
    let status_s: any;
    let pUIid: any;
    if (this.property_status) {
      status_s = this.property_status;
    }
    if (this._deleted.length && this.status_deleted == '-1') {
      status_s = status_s + ',' + this.status_deleted;
      pUIid = this._deleted;
    }
    if (this._demolished.length && this.status_demolished == '-2') {
      status_s = status_s + ',' + this.status_demolished;
      pUIid = this._demolished;
    }
    if (this.status_multi_parcel == 'multi_parcel' && this._multi_parcel.length) {
      pUIid = this._multi_parcel.toString();
    }
    if (this.review_later && this._review_later.length) {
      pUIid = this._review_later.toString();
    }
    if (this.selected_user_id) {
      url += "&assigned_to=" + this.selected_user_id;
    }

    if (status_s) url += "&status=" + status_s;

    if (pUIid) { url += '&property_uid=' + pUIid.toString(); }
    if (this.search_input) {
      url += "&search_param=" + this.search_input + '&property_uid=' + this.search_input;
    }
    if (this.api.user_id) url += "&user_id=" + this.api.user_id;
    this.api.getCsvData(url)
      .subscribe({next:(data: any) => {
        this.downloadLoader = false;
        // this.getProgressData();
        if (data && data.url) {
          this.downloadCsvSample(data.url);
        }
        else {
          this.notify.notify(data.message,'warn', 5000)
        }
        // this.downloadLoader = false;
        // window.open(data.url, "_blank");
      },
        error:(err) => {
          this.downloadLoader = false;
          this.notify.notify(this.fnc.getErrorMessage(err), 'error');
        }});
        // setTimeout(() => {
        //   this.getProgressData();
        // }, 1000);
  }

  downloadCsvSample(url: string) {
    if (!url) {
      this.notify.notify('No URL/Valid Url Found!');
      return;
    }
    else {
      let apiUrl = `${environment.csvUrl}download-template?city_id=${this.api.city_id}&file_url=${url}`;
      window.open(apiUrl, "_blank");
    }
  }

  // getProgressData(){
  //   this.api.getCsvData(`center-process-status?status=1&order_by=added_date&sort_asc=false`).subscribe((res:any)=>{
  //     if(res && res.data && res.data.length){
  //       this.share.progress.next(res.data);
  //     }else{
  //       this.share.progress.next(null);
  //     }
  //   })
  // }
}
