<!-- #1 For Web view-->
<div *ngIf="ready_load">
  <div fxHide="true" fxShow.gt-sm="true" fxLayout="row" class="w-100 crumb-c" fxLayoutAlign="space-between center">
    <div fxLayout="row" class="w-100 bleft" fxLayoutAlign="space-between center">
      <admin-breadcrumb [total_records]="total_records.total"></admin-breadcrumb>
      <div fxLayout="row" fxLayoutAlign="end center">
        <div fxLayout="row" fxShow="false" fxShow.gt-xs="true" fxLayoutAlign="start center" fxLayoutGap="10px"
          class="search-bar">
          <mat-select [(ngModel)]="selected_entity_type" [errorStateMatcher]="matcher"
            (selectionChange)="searchAttribute(false)" class="form-select-l">
            <mat-option *ngFor="let ent of api.entity_type_list"
              [value]="ent.entity_name">{{ent.entity_name}}</mat-option>
          </mat-select>
          <mat-select *ngIf="is_property_dependent" [errorStateMatcher]="matcher" [(ngModel)]="selected_property_type"
            (selectionChange)="searchAttribute(false)" [disabled]="!is_property_dependent" class="form-select-l">
            <div class="search_bar"><input mat-input [(ngModel)]="searchText" placeholder="Type here to search name..."
                (keydown)="$event.stopPropagation()" /></div>
            <mat-option
              *ngFor="let prp of api.property_type_list | searchfilter:'property_type_name':searchText:api.property_type_list"
              [value]="prp.property_type_id">{{prp.property_type_label[api.language] == undefined ?
              prp.property_type_name : prp.property_type_label[api.language]}}</mat-option>
          </mat-select>
          <!-- <input (input)="searchAttribute(false)" [(ngModel)]="search_input" class="form-input-l search-inputbox" placeholder="Type to search..." type="text">  -->
          <input [formControl]="searchField" class="form-input-l search-inputbox" placeholder="Type to search..."
            type="text">
          <!-- <button >
            <mat-icon>search</mat-icon>
          </button> -->
        </div>
      </div>
    </div>
    <div fxLayout="row" class="cleft" fxLayoutAlign="end center">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="button-b">
        <button *ngIf="attributes_access.POST" mat-button class="grey-button"
          (click)="openDialog('createattribute')">Create Attribute</button>
      </div>
    </div>

  </div>

  <div class="act-container" fxLayout="column" fxHide="true" fxShow.gt-sm="true" [ngStyle]="{'height':height+'px'}">
    <div class=" d-flex align-items-center justify-content-between total-count-container">
      <div class="total-count-wrapper">
        <div class="count-item">Parent Count: <b>{{total_records.parent}}</b></div>
        <div class="count-item">Child Count: <b> {{total_records.child}}</b></div>
        <!-- <div class="count-item">Mutually Exclusive: <b> {{total_records.mutual}} </b></div> -->
      </div>
      <div> <button class="fab_copy" [disabled]="!showCopy" (click)="pasteAttrDialog()" matTooltip="Copy attribute">
          <mat-icon class="copy-icon" svgIcon="copy"></mat-icon>
        </button></div>
    </div>
    <!-- #6 Master group table list -->
    <div class="table-estater table-estater-wrapper ">
      <table class="attr-table">
        <thead>
          <tr>
            <th>S.No.</th>
            <th class="attribute-name">Attribute Name</th>
            <th class="property-type">Property Type</th>
            <th>Lang | Searched</th>
            <th>Master</th>
            <th class="data-type">Data Type</th>
            <th class="description">Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let element of customize_attribute;let k=index;">
            <tr>
              <td width="80px">
                <div class="ap">
                  <mat-checkbox (change)="enableCopy($event, element)" [checked]="isChecked(element.id)"></mat-checkbox>
                  <span>{{k+1}}</span>
                </div>
              </td>
              <td class="attribute-name" [matTooltip]="(element.name?.length > 40)? element.name :''">{{(element.name?.length > 40) ? (element.name | slice:0:50)+'...': element.name}}</td>
              <td class="property-type">
                <span>{{element.property_type}}</span>
                <!-- <span *ngIf="element.id != isproType" (click)="editPropertyType(element)" >{{element.property_type}}</span>
                  <select *ngIf="element.id == isproType" [(ngModel)]="selected_propertyId" class="">
                    <option *ngFor="let prp of api.property_type_list;" [value]="prp.property_type_id">{{prp.property_type_label[api.language]}}</option>
                  </select>
                  <span *ngIf="element.id == isproType" (click)="updateAttribute('property_type_id',element.id,selected_propertyId,$event)" class="select_">
                    <mat-icon>edit</mat-icon>
                  </span> -->
              </td>
              <td width="16%">
                <!-- <mat-checkbox [disabled]="!attributes_access.PATCH" (change)="updateAttribute('is_unique',element.id,$event)"
                  [checked]="element.is_unique"></mat-checkbox> | -->
                <mat-checkbox [disabled]="!attributes_access.PATCH || true"
                  (change)="updateAttribute('is_lang_dependent',element.id,$event)"
                  [checked]="element.is_lang_dependent"></mat-checkbox> |
                <mat-checkbox [disabled]="!attributes_access.PATCH"
                  (change)="updateAttribute('is_frequently_searched',element.id,$event)"
                  [checked]="element.is_frequent_search"></mat-checkbox>
              </td>
              <td width="20%">
                <div class="cent">
                  <div *ngIf="element.id != isAssoMast" class="fxgrid">
                    <span>{{element.associated_master}}</span>
                    <button mat-button class="icon-btn" *ngIf="element.associated_master != 'None'" matTooltip="Edit"
                      (click)="editAssociatedMaster(element)">
                      <mat-icon class="smico">edit</mat-icon>
                    </button>
                  </div>
                  <div *ngIf="element.id == isAssoMast" class="fxgrid">
                    <mat-select *ngIf="!load" [(ngModel)]="associated_masterId" [value]="element.master_id"
                      class="select_" [disabled]="isValidate(element.data_type)">
                      <mat-option *ngFor="let mst of master_list" [value]="mst.master_id">
                        {{mst.master_label[api.language] == undefined ? mst.master_name : mst.master_label[api.language]
                        }}
                      </mat-option>
                    </mat-select>
                    <span *ngIf="load">Updating...</span>
                    <div class="grup mstr">
                      <button mat-button matTooltip="Save" class="icon-btn"
                        (click)="updateAttribute('master_id',element.id, $event, associated_masterId)">
                        <mat-icon class="smico">save</mat-icon>
                      </button>
                      <button mat-button matTooltip="Cancel" class="icon-btn" (click)="close()">
                        <mat-icon class="smico">close</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </td>
              <td class="data-type">{{element.data_type}}</td>
              <td class="description" [matTooltip]="(element.description?.length > 50)? element.description :''">{{(element.description?.length > 50) ? (element.description | slice:0:50)+'...': element.description}} <span class="dash-value" *ngIf="!element.description">--</span></td>
              <td>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <button *ngIf="attributes_access.PATCH" class="action-icon actionbtn" matTooltip="Edit"
                    (click)="openDialog('createattribute',{'edit':true,'val':element})">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <!-- <button *ngIf="attributes_access.DELETE"  class="action-icon actionbtn" matTooltip="Delete">
                    <mat-icon svgIcon="delete-icon"></mat-icon>
                  </button> -->
                  <button *ngIf="attributes_access.POST && !element.parent_id" class="action-icon actionbtn"
                    matTooltip="Add sub attribute" (click)="openDialog('createattribute',{'sub':true,'val':element})">
                    <mat-icon>add</mat-icon>
                  </button>
                  <!-- <button  class="action-icon actionbtn" *ngIf="!attributes_access.POST && !attributes_access.PATCH && !attributes_access.DELETE">
                    <mat-icon>remove</mat-icon>
                  </button> -->
                </div>
              </td>
            </tr>
            <ng-container *ngIf="element.mutual.length">
              <tr *ngFor="let mt of element.mutual;let mk = index;" class="blueFont">
                <td>{{k+1}}.{{mk+1}} mt</td>
                <td [matTooltip]="(mt.name?.length > 50)? mt.name :''">{{(mt.name?.length > 50) ? (mt.name | slice:0:50)+'...': mt.name}}</td>
                <td>{{mt.property_type}}</td>
                <td>
                  <!-- <mat-checkbox [disabled]="!attributes_access.PATCH" (change)="updateAttribute('is_unique',mt.id,$event)"
                    [checked]="mt.is_unique"></mat-checkbox> | -->
                  <mat-checkbox [disabled]="!attributes_access.PATCH || true"
                    (change)="updateAttribute('is_lang_dependent',mt.id,$event)"
                    [checked]="mt.is_lang_dependent"></mat-checkbox> |
                  <mat-checkbox [disabled]="!attributes_access.PATCH"
                    (change)="updateAttribute('is_frequently_searched',mt.id,$event)"
                    [checked]="mt.is_frequent_search"></mat-checkbox>
                </td>
                <td>
                  <div class="cent">
                    <div *ngIf="mt.id != isAssoMast" class="fxgrid">
                      <span>{{mt.associated_master}}</span>
                      <button mat-button class="icon-btn" *ngIf="mt.associated_master != 'None'"
                        (click)="editAssociatedMaster(mt)" matTooltip="Edit">
                        <mat-icon class="smico">edit</mat-icon>
                      </button>
                    </div>
                    <div *ngIf="mt.id == isAssoMast" class="fxgrid">
                      <mat-select *ngIf="!load" [(ngModel)]="associated_masterId" [value]="mt.master_id" class="select_"
                        [disabled]="isValidate(mt.data_type)">
                        <mat-option *ngFor="let mst of master_list" [value]="mst.master_id">
                          {{mst.master_label[api.language] == undefined ? mst.master_name :
                          mst.master_label[api.language]}}
                        </mat-option>
                      </mat-select>
                      <span *ngIf="load">Updating...</span>
                      <div class="grup mstr">
                        <button mat-button matTooltip="Save" class="icon-btn mx-1"
                          (click)="updateAttribute('master_id',mt.id, $event, associated_masterId)">
                          <mat-icon class="smico">save</mat-icon>
                        </button>
                        <button mat-button matTooltip="Cancel" class="icon-btn mx-1" (click)="close()">
                          <mat-icon class="smico">close</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                <td>{{mt.data_type}}</td>
                <td class="description" [matTooltip]="(mt.description?.length > 50)? mt.description :''">{{(mt.description?.length > 50) ? (mt.description | slice:0:50)+'...': mt.description}} <span class="dash-value" *ngIf="!mt.description">--</span></td>
                <td>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <button *ngIf="attributes_access.PATCH" class="action-icon actionbtn" matTooltip="Edit"
                      (click)="openDialog('createattribute',{'edit':true,'val':mt})">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <!-- <button *ngIf="attributes_access.DELETE"  class="action-icon actionbtn" matTooltip="Delete">
                      <mat-icon svgIcon="delete-icon"></mat-icon>
                    </button>
                    <button  class="action-icon actionbtn" *ngIf="!attributes_access.POST && !attributes_access.PATCH && !attributes_access.DELETE">
                      <mat-icon>remove</mat-icon>
                    </button> -->
                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-container *ngIf="element.child.length">
              <ng-container *ngFor="let child of element.child;let ck=index;">
                <tr class="oragFont">
                  <td>{{k+1}}.{{ck+1}} ch</td>
                  <td [matTooltip]="(child.name?.length > 50)? child.name :''">{{(child.name?.length > 50) ? (child.name | slice:0:50)+'...': child.name}}</td>
                  <td>{{child.property_type}}</td>
                  <td>
                    <!-- <mat-checkbox [disabled]="!attributes_access.PATCH" (change)="updateAttribute('is_unique',child.id,$event)"
                      [checked]="child.is_unique"></mat-checkbox> | -->
                    <mat-checkbox [disabled]="!attributes_access.PATCH || true"
                      (change)="updateAttribute('is_lang_dependent',child.id,$event)"
                      [checked]="child.is_lang_dependent"></mat-checkbox> |
                    <mat-checkbox [disabled]="!attributes_access.PATCH"
                      (change)="updateAttribute('is_frequently_searched',child.id,$event)"
                      [checked]="child.is_frequent_search"></mat-checkbox>
                  </td>
                  <td>
                    <div class="cent">
                      <div *ngIf="child.id != isAssoMast" class="fxgrid">
                        <span>{{child.associated_master}}</span>
                        <button mat-button class="icon-btn" *ngIf="child.associated_master != 'None'"
                          (click)="editAssociatedMaster(child)" matTooltip="Edit">
                          <mat-icon class="smico">edit</mat-icon>
                        </button>
                      </div>
                      <div *ngIf="child.id == isAssoMast" class="fxgrid">
                        <mat-select *ngIf="!load" [(ngModel)]="associated_masterId" [value]="child.master_id"
                          class="select_" [disabled]="isValidate(child.data_type)">
                          <mat-option *ngFor="let mst of getMasterDropDown(child.parent_id)" [value]="mst.master_id">
                            {{mst.master_label[api.language] == undefined ? mst.master_name :
                            mst.master_label[api.language]}}
                          </mat-option>
                        </mat-select>
                        <span *ngIf="load">Updating...</span>
                        <div class="grup mstr">
                          <button mat-button matTooltip="Save" class="icon-btn"
                            (click)="updateAttribute('master_id',child.id, $event, associated_masterId)">
                            <mat-icon class="smico">save</mat-icon>
                          </button>
                          <button mat-button matTooltip="Cancel" class="icon-btn" (click)="close()">
                            <mat-icon class="smico">close</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{{child.data_type}}</td>
                  <td class="description" [matTooltip]="(child.description?.length > 50)? child.description :''">{{(child.description?.length > 50) ? (child.description | slice:0:50)+'...': child.description}} <span class="dash-value" *ngIf="!child.description">--</span></td>
                  <td>
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <button *ngIf="attributes_access.PATCH" class="action-icon actionbtn" matTooltip="Edit"
                        (click)="openDialog('createattribute',{'edit':true,'val':child})">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <!-- <button *ngIf="attributes_access.DELETE"  class="action-icon actionbtn" matTooltip="Delete">
                        <mat-icon svgIcon="delete-icon"></mat-icon>
                      </button>
                      <button  class="action-icon actionbtn" *ngIf="!attributes_access.POST && !attributes_access.PATCH && !attributes_access.DELETE">
                        <mat-icon>remove</mat-icon>
                      </button> -->
                    </div>
                  </td>
                </tr>
                <ng-container *ngIf="child.mutual.length">
                  <tr *ngFor="let mt of child.mutual;let cmk = index;" class="greenFont">
                    <td>{{k+1}}.{{ck+1}}.{{cmk+1}} cm</td>
                    <td>{{mt.name}}</td>
                    <td>{{mt.property_type}}</td>
                    <td>
                      <!-- <mat-checkbox [disabled]="!attributes_access.PATCH" (change)="updateAttribute('is_unique',mt.id,$event)"
                        [checked]="mt.is_unique"></mat-checkbox> | -->
                      <mat-checkbox [disabled]="!attributes_access.PATCH || true"
                        (change)="updateAttribute('is_lang_dependent',mt.id,$event)"
                        [checked]="mt.is_lang_dependent"></mat-checkbox> |
                      <mat-checkbox [disabled]="!attributes_access.PATCH"
                        (change)="updateAttribute('is_frequently_searched',mt.id,$event)"
                        [checked]="mt.is_frequent_search"></mat-checkbox>
                    </td>
                    <td>
                      <div class="cent">
                        <div *ngIf="mt.id != isAssoMast" class="fxgrid">
                          <span>{{mt.associated_master}}</span>
                          <button mat-button class="icon-btn" *ngIf="mt.associated_master != 'None'"
                            (click)="editAssociatedMaster(mt)" matTooltip="Edit">
                            <mat-icon class="smico">edit</mat-icon>
                          </button>
                        </div>
                        <div *ngIf="mt.id == isAssoMast" class="fxgrid">
                          <mat-select *ngIf="!load" [(ngModel)]="associated_masterId" [value]="child.master_id"
                            class="select_" [disabled]="isValidate(mt.data_type)">
                            <mat-option *ngFor="let mst of getMasterDropDown(mt.parent_id)" [value]="mst.master_id">
                              {{mst.master_label[api.language] == undefined ? mst.master_name :
                              mst.master_label[api.language]}}
                            </mat-option>
                          </mat-select>
                          <span *ngIf="load">Updating...</span>
                          <div class="grup">
                            <button mat-button class="icon-btn"
                              (click)="updateAttribute('master_id',mt.id, $event, associated_masterId)">
                              <mat-icon class="smico">save</mat-icon>
                            </button>
                            <button mat-button class="icon-btn" (click)="close()">
                              <mat-icon class="smico">close</mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{{mt.data_type}}</td>
                    <td class="description"  [matTooltip]="(mt.description?.length > 50)? mt.description :''">{{(mt.description?.length > 50) ? (mt.description | slice:0:50)+'...': mt.description}} <span class="dash-value" *ngIf="!mt.description">--</span></td>
                    <td>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <button *ngIf="attributes_access.PATCH" class="action-icon actionbtn" matTooltip="Edit"
                          (click)="openDialog('createattribute',{'edit':true,'val':mt})">
                          <mat-icon>edit</mat-icon>
                        </button>
                        <!-- <button *ngIf="attributes_access.DELETE"  class="action-icon actionbtn" matTooltip="Delete">
                          <mat-icon svgIcon="delete-icon"></mat-icon>
                        </button>
                        <button  class="action-icon actionbtn" *ngIf="!attributes_access.POST && !attributes_access.PATCH && !attributes_access.DELETE">
                          <mat-icon>remove</mat-icon>
                        </button> -->
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
      <div *ngIf="customize_attribute?.length === 0">
        <mat-card class="p-4 text-center "> <strong>No Record Found</strong> </mat-card>
      </div>
      <!-- loader start -->
      <div *ngIf="loader" class='loader-div'>
        <ngx-loading [show]="loader"></ngx-loading>
      </div>
      <!-- loader end -->
    </div>
  </div>
</div>
<!-- loader start -->
<div *ngIf="!ready_load">
  <ngx-loading [show]="!ready_load"></ngx-loading>
</div>
<!-- loader end -->

<!-- end of #1 For Web view-->

<!-- #2 For Mobile Screen (Search and Add)-->
<div fxLayout="column" class="topb" fxShow="true" fxShow.gt-sm="false">
  <div fxLayout="row" class="w-100" fxLayoutAlign="space-between center">
    <div class="w-100">
      <admin-breadcrumb></admin-breadcrumb>
    </div>
  </div>
  <div fxLayout="row" *ngIf="!showmobilesearchbar" class="w-100 master-middle-bar" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
      <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="space-around start">
        <label class="small-search-label">Property type</label>
        <mat-select class="form-select-white">
          <mat-option>Apartment</mat-option>
        </mat-select>
      </div>
      <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="space-around start">
        <label class="small-search-label">Entity type</label>
        <mat-select class="form-select-white">
          <mat-option>Property</mat-option>
        </mat-select>
      </div>
    </div>
    <div fxLayout="row" *ngIf="!showsubmas" fxLayoutGap="5px" fxLayoutAlign="end center">
      <button (click)="openDialog('createattribute')" class="greenbutton">
        <mat-icon>add</mat-icon>
      </button>
      <button class="obutton" (click)="showMobileSearchBar()">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </div>

  <div fxLayout="row" *ngIf="showmobilesearchbar" [ngClass]="{'animated fadeIn': showmobilesearchbar}"
    class="w-100 master-middle-bar" fxLayoutAlign="space-between center">
    <div fxFlex="90%">
      <input type="text" class="mobile-searchbar" placeholder="Search attribute by name" />
    </div>
    <div fxFlex="10%">
      <button (click)="showMobileSearchBar()" class="obutton">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>


<!-- For Mobile Screen-->

<div class="container listview" fxShow="true" fxShow.gt-sm="false" fxLayout="row">
  <div fxLayout="column" class="w-100" fxLayoutGap="15px" fxLayoutAlign="space-between center">
    <div class="pack w-100">
      <div class="draggableset sets" *ngFor="let source of dataSource;let s = index">

        <div fxLayout="column" fxLayoutAlign="center start" class="les" id="les{{s}}"
          (swipeleft)="swipeleftactions(source,s)" (swiperight)="swiperightactions(source,s)">

          <div class="heading" fxFlex="100%" [ngClass]="{active : mobiletablistselect == s}">
            {{s+1}}. {{source.name}}
          </div>
          <div class="middlec" fxLayout="row" fxLayoutAlign="space-around center">
            <div fxLayout="column" fxFlex="50%">
              <div class="subtext">
                <span class="label">Property Type : </span>
                <span class="value">{{source.master_language}}</span>
              </div>
              <div class="subtext">
                <span class="label">Data Type : </span>
                <span class="value">{{source.property_type}}</span>
              </div>
              <div class="subtext">
                <span class="label">Associated Master : </span>
                <span class="value">{{source.master_language}}</span>
              </div>
            </div>

            <div fxLayout="column" fxFlex="50%">
              <div class="subtext" fxLayout="row" fxLayoutAlign="space-between center">
                <span class="label">Property Type : </span>
                <span class="value">
                  <mat-checkbox></mat-checkbox>
                </span>
              </div>
              <div class="subtext" fxLayout="row" fxLayoutAlign="space-between center">
                <span class="label">Data Type : </span>
                <span class="value">
                  <mat-checkbox></mat-checkbox>
                </span>
              </div>
              <div class="subtext" fxLayout="row" fxLayoutAlign="space-between center">
                <span class="label">Associated Master : </span>
                <span class="value">
                  <mat-checkbox></mat-checkbox>
                </span>
              </div>
            </div>

          </div>
          <div class="swipeactions">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <button mat-button class="swipe-button" matTooltip="Edit">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-button class="swipe-button">
                <mat-icon svgIcon="delete-icon"></mat-icon>
              </button>
            </div>
            <!-- <button mat-button class="submaster">Submaster</button> -->
          </div>
        </div>

      </div>
    </div>

  </div>
</div>

<!-- For Mobile Screen-->

<!-- End of #2 Mobile Screen -->
