<div *ngIf="!edit">
    <form #groupForm="ngForm" (ngSubmit)="createMasterGroup($event)">
        <mat-toolbar class="creategroup">
            <div>Create Master Group</div>
        </mat-toolbar>
        <mat-dialog-content>
            <mat-form-field floatLabel="never" class="w-100">
                <input matInput type="text" [errorStateMatcher]="matcher" maxlength="64" placeholder="Group Name" name="master_group_label" #master_group_label="ngModel" [ngModel]="group.master_group_label[1]" (ngModelChange)="group.master_group_label[1] = $event" required pattern="^[a-zA-Z0-9_ ]*$">
                <mat-hint>Enter group name here</mat-hint>
                <mat-hint align="end">{{master_group_label?.value?.length || 0}}/64</mat-hint>
                <!-- <mat-error *ngIf="master_group_label.errors.required && (master_group_label.dirty || master_group_label.touched)">Group is required</mat-error>
                <mat-error *ngIf="master_group_label.errors.pattern && (master_group_label.dirty || master_group_label.touched)">Special Characters are not allowed!</mat-error> -->
                <mat-error *ngIf="!master_group_label?.valid && (master_group_label?.dirty || master_group_label?.touched)" class="error">
                    <mat-error *ngIf="master_group_label.errors?.required">
                        Group Name is required
                    </mat-error>
                    <mat-error *ngIf="master_group_label.errors.pattern">
                        Special characters are not allowed
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions">
            <button mat-raised-button type="submit" [disabled]="groupForm.invalid" class="blue-button">Create</button>
            <button mat-raised-button type="button" (click)="closeDialog($event);">Cancel</button>
        </mat-dialog-actions>
    </form>
</div>

<div *ngIf="edit">
    <form #groupFormEdit="ngForm" (ngSubmit)="updateMasterGroup($event)">
        <mat-toolbar class="creategroup">
            <div>Edit Master Group</div>
        </mat-toolbar>
        <mat-dialog-content>
            <mat-form-field floatLabel="never" class="w-100">
                <input matInput [errorStateMatcher]="matcher" type="text" maxlength="64" placeholder="Group Name" name="master_group_label" #master_group_label="ngModel" [ngModel]="group_update.master_group_label[1]" (ngModelChange)="group_update.master_group_label[1] = $event" required pattern="^[a-zA-Z0-9_ ]*$">
                <mat-hint>Edit group name here</mat-hint>
                <mat-hint align="end">{{master_group_label.value.length}}/64</mat-hint>
                <!-- <mat-error>Group is required</mat-error>
                <mat-error>Special Characters are not allowed!</mat-error> -->
                <mat-error *ngIf="!master_group_label?.valid && (master_group_label?.dirty || master_group_label?.touched)" class="error">
                    <mat-error *ngIf="master_group_label.errors.required">
                        Group Name is required
                    </mat-error>
                    <mat-error *ngIf="master_group_label.errors.pattern">
                        Special characters are not allowed
                    </mat-error>
                </mat-error>
            </mat-form-field>

        </mat-dialog-content>
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions">
            <button mat-raised-button type="submit" [disabled]="groupFormEdit.invalid || groupFormEdit.pristine" class="blue-button">Update</button>
            <button mat-raised-button type="button" (click)="closeDialog($event);">Cancel</button>
        </mat-dialog-actions>
    </form>
</div>
