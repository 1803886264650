import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { FormControl} from '@angular/forms';
import * as $ from 'jquery';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Options } from 'sortablejs';
import { ApiserviceService } from '../../apiservice.service';
import { AdminBreadcrumbService } from '../admin-breadcrumb/services/admin-breadcrumb.service';
import { ConfirmDialogBox } from '../confirm-dialog/confirm-dialogbox';
import { NotificationService } from '../services/notification.service';

import { debounceTime } from 'rxjs';
import { MatErrorStateMatcher } from '../admin-error-state-macher';
import { CreateMasterGroupDialog } from './components/create-update-mastergroup/admin-creategroup.component';
import { CreateMasterValueDialog } from './components/create-update-master-value/admin-createvalue.component';
import { CreateMaster } from './components/create-update-master/admin-createmaster.component';
import { CreateSubMasterValueDialog } from './components/create-update-sub-master-value/admin-create-subvalue.component';
import { MasterValueDialog } from './components/master-value-dialog/master-value-dialog.component';
import { MasterDownloadComponent } from './components/master-download/master-download.component';


export interface Sets {
  sno: number;
  name: string;
  master_language: number;
  property_type: string;
  right_menu?: Array<{}>;
  id: number;
  status: number;
}

//const ELEMENT_DATA: Sets[] = [
// {sno: 1, name: 'Boron', master_language: 10.811,  right_menu : [],property_type: 'B', id:1, status:1},
//];

const ELEMENT_DATA_SUBMASTER = [
  { sno: 1, submaster: 'Hydrogen', language: 'Sony - Arabic', status: '1' }
]
/**
*
* <strong>List of API using</strong>
* <ol>
* <li>umsapi_user_resource_post</li>
* <li>estapi_entities_get</li>
* <li>estapi_ropertytypes_get</li>
* <li>estapi_mastergroups_get</li>
* <li>estapi_masters_get</li>
* <li>estapi_entity_masters_master_id_values_get</li>
* <li>estapi_entity_masters_master_id_values_master_value_id_patch</li>
* <li>estapi_entity_masters_master_id_values_patch</li>
* </ol>
*
*/

@Component({
  selector: 'app-admin-master',
  templateUrl: './admin-master.component.html',
  styleUrls: ['admin-master.component.scss']
})
export class AdminMasterComponent implements OnInit, AfterViewInit {
  matcher = new MatErrorStateMatcher();
  dialogFn: Function;
  searchFn: Function;
  mastergroups_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  }
  masters_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  }
  mastervalues_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  }
  property_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  }
  entity_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  }
  loading: boolean;
  ready_load: boolean = true;
  loading_sub_master: boolean;
  showsubmas: boolean = false;
  items = [1, 2, 3, 4, 5, 6, 7];
  displayedColumns = ['sno', 'name', 'master_language', 'icon','status', 'actions'];
  displayedColumnssubmaster = ['sno', 'submaster', 'language', 'status', 'actions'];
  dataSource: any = [];
  dataSourcesubmaster = ELEMENT_DATA_SUBMASTER;
  mobilemasterpopup = false;
  mobilemasterpopupslideout = false;
  submasterpop: boolean;
  popwindow: number = -1;
  popsubwindow: number = -1;
  mobiletablistselect: number = -1;
  selected_list_item: any;
  selected: any;
  submasterlist: Array<{}> = [];
  mobilesearchbar: boolean = false;
  showCopy: boolean;
  selected_master_valueList: any = [];
  tableoptionsSub: Options = {
    draggable: '.draggableset',
    onUpdate: (event: any) => this.sortSubFields()
  };
  tableoptions: Options = {
    draggable: '.draggableset',
    onUpdate: (event: any) => this.sortFields()
  };
  public master_group_list;
  public masterList: any;
  public selected_master: any;
  selected_master_value: any;
  sub_master_value_list: any;
  selected_sub_master: any;
  selectedValRow: any;
  searchVal = '';
  master_val_search ='';
  search_input: string;
  searchField: FormControl;

  selected_property_type: number;
  selected_entity_type: string;
  is_property_dependent: boolean;
  height: number;
  inHeight: number;
  allSubmasterVal: any = [];
  selected_item: any;
  sort_asc: boolean = true;
  color = 'accent';
  master_val_limit = 15;
  master_val_offset = 0;
  selected_sorting_type: string = 'master_value_name';
  sort_type = [
    { name: 'Sort order', value: 'sort_order' },
    { name: 'Added date', value: 'added_date' },
    { name: 'Master name', value: 'master_value_name' }
  ]
  is_master_ui :boolean=false;
  active_value_status = 'all';
  constructor(public dialog: MatDialog, private menus: AdminMenusService, private _el: ElementRef, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    public api: ApiserviceService, private fnc: CommonfunctionService, private _crumbs: AdminBreadcrumbService,
    private notify: NotificationService, private route: Router, private cdr: ChangeDetectorRef) {
    this.menus.setActive('masters');
    this._crumbs.clear();
    this._crumbs.addcrumb = { title: 'Masters' };
    this._crumbs.mobiletitle = 'Masters';
    this._crumbs.count = 1;
    iconRegistry.addSvgIcon(
      'edit-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/edit.svg'))
      .addSvgIcon(
        'down-arrow-icon',
        sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/down_arrow.svg'))
      .addSvgIcon(
        'delete-icon',
        sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/delete.svg'))
      .addSvgIcon(
        'property-listing-icon',
        sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/property_listing.svg')).addSvgIcon(
          'move-icon',
          sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/move.svg'))
      .addSvgIcon(
        'sub_master_icon',
        sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/sub_master.svg'))
    this.dialogFn = this.openDialog.bind(this);
    this.searchFn = this.searchMasterValue.bind(this);
    this.getAllowResourcesApi();
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.height = (window.innerHeight - 140);
    this.inHeight = (window.innerHeight - 200);
  }
  //Context Function
  onContextClick(e) {

  }
  sortBy() {
    if (this.selected_sorting_type) {
      this.searchMasterValue(false);
    }
  }
  changeOrder(e) {
    if (e.checked) {
      this.sort_asc = e.checked;
      this.searchMasterValue(false);
    } else {
      this.sort_asc = e.checked;
      this.searchMasterValue(false);
    }
    this.cdr.markForCheck();
}
  // getting allow resources
  getAllowResourcesApi() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe({next:(res: any) => {
        this.api.allowResources = res.data;
        this.getAllowResource();
        this.getEntityType();

      },
      error:(err)=>{
      }
    }
      )
  }


  changeView(){
    if(this.mastervalues_access.GET){
      this.is_master_ui = !this.is_master_ui;
    }else{
      this.notify.notify('You are not authorize to get master value', 'error');
    }
  }

  // getAllowResourcesApi() {
  //   let body = "place_id=" + this.api.city_id;
  //   this.api.postUmsData('user/resource', body)
  //     .subscribe(
  //       (data: any) => {
  //         this.api.allowResources = data;
  //         this.getAllowResource();
  //         this.getEntityType();
  //       },
  //       err => {
  //       }
  //     )
  // }

  // getting list of entity type
  getEntityType() {
    this.api.getEmsData('entities')
      .subscribe(
        {
          next: (data: any) => {
            this.api.entity_type_list = data;
            this.getPropertyType();
          },
          error(){}
        }
      );
  }
  // getting list of property type
  getPropertyType(sort_by = 'property_type_name', limit = 10000, offset = 0) {
    let url = "propertytypes?sort_by=" + sort_by + "&limit=" + limit + "&offset=" + offset + '&status=1';
    this.api.getEmsData(url)
      .subscribe(
        {
          next: (data: any) => {
            this.api.property_type_list = data;
            this.selected_entity_type = this.api.entity_type_list[0].entity_name;
            this.is_property_dependent = this.api.entity_type_list[0].is_property_type_dependent;
            this.selected_property_type = this.api.property_type_list[0].property_type_id;
            this.getMaster('master_name', 1000, 0);
          },
          error:()=>{
            console.log('error');
          }
        }
      );
  }

  searchMasterValue(master: any, resetOffset: boolean = false) {
    this.selected_list_item = null;
    if (!master) {
      master = this.selected_master;
    } else {
      this.master_val_offset = 0;
    }
    if (resetOffset) this.master_val_offset = 0;
    let param = '', name = '', prop = '';
    let entity_info = this.fnc.getArrayValue('entity_name', this.selected_entity_type, this.api.entity_type_list);
    this.is_property_dependent = entity_info.is_property_type_dependent;
    if (this.search_input) {
      name = "&master_value_name=" + this.search_input;
    }

    if (this.selected_property_type && this.is_property_dependent) {
      prop = "&property_type_ids=" + this.selected_property_type;
    }
    param = name + prop;
    if (this.selected_sorting_type) {
      this.getMasterValue(master, this.selected_sorting_type, this.master_val_limit, this.master_val_offset, param);
    }
  }

  showMobileSearchBar() {
    this.mobilesearchbar = !this.mobilesearchbar;
  }


  select(item, obj?) {
    this.selected = (this.selected === obj ? null : obj);
    this.selected_item = obj
  }
  isActive(item) {
    return this.selected === item;
  }

  showSubmaster() {
    this.showsubmas = !this.showsubmas;
  }

  isActivemasterVal(item) {
    return this.selectedValRow === item;
  }

  isChecked(id) {
    if (this.selected_master_valueList.includes(id)) {
      return true;
    } else {
      return false;
    }
  }

  enableCopy(e, data) {
    if (e.checked == true) {
      this.selected_master_valueList.push(data.master_value_id);
    } else {
      this.selected_master_valueList.splice(this.selected_master_valueList.indexOf(data.id), 1)
    }
    if (this.selected_master_valueList.length) {
      this.showCopy = true;
    } else {
      this.showCopy = false;
    }
  }

  pastemastervalDialog() {

    let dialogRef = this.dialog.open(MasterValueDialog, {
      width: '350px',
      panelClass: 'create-master-panel',
      data: { parent: this }//optional
    });
    dialogRef.afterClosed().subscribe(result => {

    });

  }

  getActiveMasterValue(){
    this.master_val_offset = 0;
    this.dataSource = [];
    this.searchMasterValue(false, false);
  }

  selectedMasterValue(val: any, k: any, target) {
    this.selected_master_value = val;
    this.selectedValRow = k;
    this.sub_master_value_list = [];
    this.scroll(target);
    if(val.id != null &&  this.selected_list_item){
      this.triggerSubmaster('refresh');
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
  mobiletableoptions: Options = {
    draggable: '.draggableset',
    handle: '.mobdraghandle',
    scroll: true
  };

  // groupoptions: SortablejsOptions = {
  //   draggable: '.rowse',
  //   handle: '.draghandle',
  // };

  submastergroupoptions: Options = {
    draggable: '.subdraghandle',
    handle: '.draghandle',
    dragClass: 'submaster-ghostclass'
  };

  mobilegroupoptions: Options = {
    draggable: '.mobilerowslist',
    handle: '.draghandle',
  };

  @HostListener('document:click', ['$event'])
  handleClick(event: Event) {
    ;
    if (!$(event.target).hasClass('mastergroup_menuactions') && !$(event.target).hasClass('add-b')) {
      this.hideMobilePopover();
    }
  }
  showListHeader: boolean;
  dragDisabled:boolean=true;

  /**
   * This is a funtion to use sort order of master group
   * @param event
   */
  dropGroup(event) {
    this.dragDisabled = true;
    moveItemInArray(this.master_group_list, event.previousIndex, event.currentIndex);
    if(event.previousIndex != event.currentIndex){
      this.updateMasterGroup(this.master_group_list);
    }
  }

  updateMasterGroup(datalist:any){
    if(datalist && datalist?.length>0){
      let body = [];
      datalist.map((ele, index)=>{
        body.push({
          "master_group_id": ele.id,
          "sort_order": index,
          "status": 1,
          "updated_by": this.api.user_id
        })
      })
      this.api.patchEmsData(`mastergroups`, body).subscribe((res:any)=>{
        if(res && res == 1){
          this.getMasterGroup('added_date', 10000, 0);
        }
      })
    }

  }

  getMasterGroup(sort_by: any, limit: number, offset: number) {
    let url = "mastergroups?sort_by=" + sort_by + "&limit=" + limit + "&offset=" + offset;
    let i = 0;
    let masterGroup: any = [];
    if (!this.mastergroups_access.GET) {
      this.master_group_list = masterGroup;
      return;
    }
    this.api.getEmsData(url).subscribe(
      (data: any) => {
        data.forEach(element => {
          let data = {
            "id": element.master_group_id,
            "label": element.master_group_label,
            "name": element.master_group_name,
            "title": (element.master_group_label && element.master_group_label[this.api.language]) ? element.master_group_label[this.api.language]: element.master_group_name,
            "parent_id": element.parent_master_group_id,
            "childrens": [],
            "sort_order":element.sort_order
          };
          if (this.masterList) {
            this.masterList.forEach(master => {
              if (master.master_group_id == element.master_group_id) {
                if (!master.parent_master_id) { // sub master will not added in this group
                  let val = {
                    "id": master.master_id,
                    "label": master.master_label,
                    "name": master.master_name,
                    "title": (master.master_label && master.master_label[this.api.language]) ? master.master_label[this.api.language] : master.master_name ,
                    "parent_id": master.parent_master_id,
                    "group_id": master.master_group_id
                  };
                  data.childrens.push(val);
                }
              }

            });
          }
          masterGroup.push(data);
        });
        this.master_group_list = masterGroup;
        // displaying value of first master of first group on page load
        let first_master = this.master_group_list[0].childrens[0];
        if (first_master) {
          this.selected_master = first_master;
          this.select(0); //select 0th index of group
          let obj = this.master_group_list[0];
          this.selected_item = obj.id;
          this.searchMasterValue(false);
        }

      }
    );
  }

  onscroll(e) {
    let scroll = document.getElementById('detect-scroll');
    //console.log(e);
    let isReachingBottom = e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight;
    let isReachingBottom_1 = scroll.scrollTop + scroll.offsetHeight + 20 >= scroll.scrollHeight;
    if ((isReachingBottom || isReachingBottom_1) && !this.loading) {
      this.master_val_offset += this.master_val_limit;
      this.searchMasterValue(false, false);

    }
  }

  /**
   * This is a function for get masters
   * @param sort_by
   * @param limit
   * @param offset
   */
  getMaster(sort_by: string, limit: number, offset: number) {
    let url = "masters?sort_by=" + sort_by + "&limit=" + limit + "&offset=" + offset;
    this.api.getEmsData(url).subscribe(
      {
        next: (data) => {
        this.masterList = [];
        if (this.masters_access.GET) {
          this.masterList = data;
        }
        this.getMasterGroup('added_date', 10000, 0);
      },
      error:() => {
        if (!this.masterList) {
          // if master is empty
          this.getMasterGroup('added_date', 10000, 0);
        }}
      }
    );
  }

  getSingleMaster(master: any) {
    // console.log(master);
    if(master){master['id'] = master?.master_id;}
    let url = `masters/${master.id}`;
    this.api.getEmsData(url).subscribe(
     {next: (data:any) => {
        if(this.submasterlist.length > 0){
          this.submasterlist.map((ele:any, index)=>{
            if(ele.master_id == data.master_id){
              this.submasterlist[index] = data;
            }
          })
        }
        if (data) {
          data['name'] = data?.master_name;
          this.selected_list_item = data;
        }
        // this.getMasterValue(this.selected_master, this.selected_sorting_type, this.master_val_limit, this.master_val_offset, this.master_val_search);
      },
      error:() => {}
      }
    );
  }

  // getting the value of master
  getMasterValue(master: any, sort_by: string, limit: number, offset: number, search: any = '') {
    this.master_val_search = search;
    this.selected_sub_master = null;
    this.selectedValRow = null;
    this.selected_master_value = null;

    if (!this.mastervalues_access.GET) {
      this.dataSource = [];
      return;
    }

    let url = this.selected_entity_type + "/masters/" + master.id + "/values?sort_by=" + sort_by + "&limit=" + limit + "&offset=" + offset + search + "&sort_asc=" + this.sort_asc;
    if(this.active_value_status == 'enable'){
      url += "&status=1";
    }else if(this.active_value_status == 'disable'){
      url += "&status=0"
    }

    let ctr = offset + 1; let find = true;
    let property: any;
    let language: any;
    this.loading = true;
    this.selected_master = master;
    if (offset == 0) {
      this.dataSource = [];
    }
    let tempArr = [];
    this.api.getEmsData(url).subscribe(
      {next:(data: any) => {
        this.loading = false;
        if(data?.length) {
        data.forEach(element => {
          let val = {
            sno: ctr,
            name: element.master_value_name,
            master_language: (element.master_value_label && element.master_value_label[this.api.language]) ? element.master_value_label[this.api.language] : element.master_value_name,
            right_menu: [],
            property_type: 'None',
            id: element.master_value_id,
            status: element.status,
            property_type_id: element.property_type_id,
            labels: element.master_value_label,
            data_source: element.data_source,
            master_value_id: element.master_value_id,
            image_url: element.image_url
          };
          // getting property detail by id
          if (element.property_type_id) {
            property = this.fnc.getArrayValue('property_type_id', element.property_type_id, this.api.property_type_list);
            val.property_type = (property.property_type_label && property.property_type_label[this.api.language]) ?  property.property_type_label[this.api.language]: property.property_type_name;
          }
          for (let key in element.master_value_label) {
            //getting language detail by id
            language = this.fnc.getArrayValue('language_id', key, this.api.language_list);
            find = true;
            let lang_value = {
              label: language.name,
              value: element.master_value_label[key]
            };
            val.right_menu.push(lang_value);
          }
          ctr++;
          tempArr.push(val);
        });

        if (offset == 0) {
          this.dataSource = tempArr;
          this.submasterlist = this.fnc.getChildArray('parent_master_id', master.id, this.masterList);
        } else {
          this.dataSource = [...this.dataSource, ...tempArr];
        }
      }

      },
      error:() => {
        this.loading = false
      }}
    );

  }
  triggerSubmaster(e) {
    if (e != 'refresh') {
      let val = this.fnc.getArrayValue('master_id', e.source.value, this.submasterlist);
      if (val) {
        val['name'] = val?.master_name;
        this.selected_list_item = val;
        this.getSubMasterValue(val, 'master_value_name', 500, 0);
      }
    } else {
      this.getSubMasterValue(this.selected_list_item, 'master_value_name', 500, 0);
    }
  }
  getAllSubMasterValues() {
    if (!this.mastervalues_access.GET) {
      this.sub_master_value_list = [];
      return;
    }
    let sort_by = 'sort_order';
    let master;
    let url = this.selected_entity_type + "/masters/" + master.master_id + "/values?sort_by=" + sort_by;
  }
  // getting the value of sub master
  getSubMasterValue(master: any, sort_by: string, limit: number, offset: number) {
    if (!master || !master.master_id) {
      return;
    }
    let prop = "";
    if (this.selected_property_type && this.is_property_dependent) prop = "&property_type_ids=" + this.selected_property_type;
    this.selected_sub_master = master;
    this.allSubmasterVal = [];
    if (!this.mastervalues_access.GET) {
      this.sub_master_value_list = [];
      return;
    }
    let url = this.selected_entity_type + "/masters/" + master.master_id + "/values?sort_by=" + sort_by + "&limit=" + limit + "&sort_asc=true&offset=" + offset + prop;
    // if(this.selected_master_value) url += "&parent_master_value_ids=" + this.selected_master_value.id;
    let ctr = 1; let find = true;
    let property: any;
    let language: any;
    this.loading_sub_master = true;
    this.api.getEmsData(url).subscribe(
    { next: (data: any) => {
        this.loading_sub_master = false;
        if(data && data.length > 0){
          this.sub_master_value_list = [];
          data.forEach((element:any) => {
            this.allSubmasterVal.push({
              sno: ctr,
              name: element.master_value_name,
              master_language: element.master_value_label[this.api.language],
              property_type: '',
              id: element.master_value_id,
              parent_master_value_ids: element.parent_master_value_ids,
              status: element.status,
              property_type_id: element.property_type_id,
              labels: element.master_value_label
            });

            if (element.parent_master_value_ids == null) element.parent_master_value_ids = "";
            // let parent_master_value_id_arr = element.parent_master_value_ids.split(',');
            let parent_master_value_id_arr = element.parent_master_value_ids.split(',');
            if (parent_master_value_id_arr.indexOf('' + this.selected_master_value.id).toString() > -1) {
            // if (element.parent_master_value_id == this.selected_master_value.id) {
              let val = {
                sno: ctr,
                name: element.master_value_name,
                master_language: element.master_value_label[this.api.language],
                right_menu: [],
                property_type: '',
                id: element.master_value_id,
                parent_master_value_ids: element.parent_master_value_ids,
                status: element.status,
                property_type_id: element.property_type_id,
                labels: element.master_value_label,
                data_source: element.data_source
              };
              // gettingb property detail by id
              if (element.property_type_id) {
                property = this.fnc.getArrayValue('property_type_id', element.property_type_id, this.api.property_type_list);
                val.property_type = property.property_type_label[this.api.language];
              }
              for (let key in element.master_value_label) {
                //getting language detail by id
                language = this.fnc.getArrayValue('language_id', key, this.api.language_list);
                find = true;
                let lang_value = {
                  label: language.name,
                  value: element.master_value_label[key]
                };
                // let lang_value = { label: "<span class='menu-icon'>" + language.name + " - " + element.master_value_label[key] + "</span>" };
                val.right_menu.push(lang_value);
              }
              ctr++;
              this.sub_master_value_list.push(val);
            }
          });
        }

      },
      error:(err) => {
        this.loading_sub_master = false;
        this.sub_master_value_list = [];
      }}
    );
  }
  getAllowResource() {
    this.mastergroups_access = this.fnc.checkResourceAccess('mastergroups');
    this.masters_access = this.fnc.checkResourceAccess('masters');
    this.mastervalues_access = this.fnc.checkResourceAccess('mastervalues');
    this.property_access = this.fnc.checkResourceAccess('propertytypes');
    this.entity_access = this.fnc.checkResourceAccess('entities');
    if (!this.property_access.GET) {
      this.api.property_type_list = [];
    }
    if (!this.entity_access.GET) {
      this.api.entity_type_list = [];
    }
    if (!this.masters_access.GET) {
      this.route.navigateByUrl('/');
      return;
    }
  }
  openDialog(type: string, info: any = {}, event): void {
    // event.stopPropagation();
    let dialogview;
    info.pObj = this;
    if (type == 'mastergroup') {
      dialogview = CreateMasterGroupDialog
    }
    if (type == 'master') {
      dialogview = CreateMaster
    }
    if (type == 'mastervalue') {
      dialogview = CreateMasterValueDialog
      this.selected_master_value = info.master_val;
    }
    if (type == 'submastervalue') {
      dialogview = CreateSubMasterValueDialog
    }
    if (dialogview) {
      let dialogRef = this.dialog.open(dialogview, {
        width: '450px',
        panelClass: 'create-master-panel',
        data: info
      });
      dialogRef.disableClose = true;
      //dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // if (type == 'mastergroup' || type == 'master') {
      //   // this.getMaster('master_name',1000,0);
      // }
      // if (type == 'mastervalue' && this.selected_master) {
      //   // this.getMasterValue(this.selected_master,'master_value_name',200,0);
      // }

      //});
    }
  }
  public selected_master_value_id;
  openConfirmation(value) {
    this.selected_master_value_id = value.id;
    let _data = {
      parent_data: this,
      message: 'Do you want to unlink master values?',
    }
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: '350px',
      data: _data
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  confirmDialogYes(confirm) {
    if (confirm == 'YES') {
      let body = {
        master_value_id: this.selected_master_value_id,
        parent_master_value_ids: [this.selected_master_value.id],
        user_id: this.api.user_id
      }
      this.api.patchEmsData('unlink-master-values', body).subscribe(
     { next:  (data: any) => {
        this.notify.notify("unlink master value", "warn");
        this.triggerSubmaster('refresh');
      }, error: ()  => {
        this.notify.notify("master value not unlinked", "error")
      }}
      )
    }
  }
  showMasterGroup() {
    this.mobilemasterpopup = !this.mobilemasterpopup;
    if (this.mobilemasterpopup) {
      this.mobilemasterpopupslideout = false;
    }
  }

  selectel(k) {
    this.mobiletablistselect = k;
  }

  swiped() {
    //console.log('swiped');
    this.mobilemasterpopupslideout = true;
    setTimeout(function () {
      this.mobilemasterpopup = false;
    }.bind(this), 1000)
  }


  showsmallpop(index: number, submaster: boolean = false) {
    this.popsubwindow = null;
    this.popwindow = index;
    this.submasterpop = submaster;
  }
  showsmallsubpop(event, classname: string, index: number, position?: boolean) {
    this.popwindow = null;
    //this.submasterlist = [];
    this.popsubwindow = index;
    if (position) {
      this.positionsubpopup(event, classname);
    }
  }

  showsmallpopmobile(event, classname: string, index: number, children: Array<{}>) {
    this.popwindow = index;
    this.submasterlist = children;
    this.positionpopup(event, classname);
  }

  positionpopup(event, classname) {
    $("." + classname).css({
      top: event.y - 80,
      left: event.x + 20
    })
  }
  // hideTooltip(){
  //   this.popwindow = null;
  //   this.submasterpop = null;
  //   this.popsubwindow = null;
  // }


  positionsubpopup(event, classname) {
    $("." + classname).css({
      top: event.y - 80,
      left: event.x - ($(".mobilesubmastergroups").outerWidth())
    })
  }

  swipeleftactions(s, k) {
    let el = $(this._el.nativeElement);
    el.find("#les" + k).find('.swipeactions').show();
    el.find(".les").not('#les' + k).css('margin-left', '0');
    el.find("#les" + k).show().css('margin-left', '-200px');

  }

  swiperightactions(s, k) {
    let el = $(this._el.nativeElement);
    el.find('.swipeactions').hide();
    el.find("#les" + k).css('margin-left', '0');
  }

  removepop() {
    //console.log('remove');
  }

  loadGroup() {
    this.swiped();
    this.hideMobilePopover();
  }

  hideMobilePopover() {
    this.popwindow = -1;
    this.popsubwindow = -1;
  }

  ngOnInit() {
    this.searchField = new FormControl();
    this.searchField.valueChanges
    .pipe(debounceTime(1000))
      .subscribe(term => {
        this.search_input = term.trim().toLowerCase();
        this.searchMasterValue(false, true);
      })
  }

  ngAfterViewInit() {
    $(".sortable-list").css({ "padding": "5px 10px !important" });
    $(".listview").css('width', window.screen.width);
    $('.listview').on('click mousedown mouseover select', function (evt) {

      var e = evt || window.event;
      e.stopPropagation();
    });

    $(".addmastergrouppopup").on('click', function (e) {
      if ($(e.target).hasClass('subgrouplist') || $(e.target).hasClass('addmastergrouppopup') || $(e.target).hasClass('add-b')) {
        this.hideMobilePopover();
      }

    }.bind(this));
    var mob = $(window).height() - 167;
    $(".pack").css('height', mob);
    $(".grouplist").css('height', $(window).height() - 110)
    $(".subgrouplist").css('height', $(window).height() - 110)
  }

  updateAttribute(field: string, id: number, event) {
    let value = 0;
    let record = {
      "updated_by": this.api.user_id
    }
    if (event.checked) {
      value = 1;
    } else {
      value = 0;
    }
    record[field] = value;
    //console.log(record);
    this.api.patchEmsData(this.selected_entity_type + '/masters/' + this.selected_master.id + '/values/' + id, record)
      .subscribe(
        {
          next: (data) => {
          this.notify.notify('Row updated', 'success');
            this.searchMasterValue(false,false)
        },
        error:(err) => { this.notify.notify(this.fnc.getErrorMessage(err), 'error'); }
        }
      );
  }

  updateSubAttribute(field: string, id: number, event) {
    let value = 0;
    let record = {
      "updated_by": this.api.user_id
    }
    if (event.checked) value = 1;
    record[field] = value;
    let url = this.selected_entity_type + "/masters/" + this.selected_sub_master.master_id + "/values/" + id;
    this.api.patchEmsData(url, record)
      .subscribe(
        {
         next: (data) => {
            this.notify.notify('Row updated', 'success');
            this.triggerSubmaster('refresh');
            //this.data.pObj.getAttributes('attribute_name',10000,0);
          },
          error:(err) => {
            this.notify.notify(this.fnc.getErrorMessage(err), 'error');
            //.log(this.fnc.getErrorMessage(err));
          }
        }
      );
  }

  sortFields() {
    let fields = [];
    let val = {};
    if (!this.mastergroups_access.PATCH) {
      this.notify.notify('You are not authorize to update Mastergroup', 'warn')
      return;
    }
    this.dataSource.forEach((v, k) => {
      v.sno = k + 1; // serial number
      val = {
        "sort_order": k,
        "master_value_id": v.id,
        "updated_by": this.api.user_id,
      };

      fields.push(val);
    });
    let url = this.selected_entity_type + '/masters/' + this.selected_master.id + '/values/';

    this.api.patchEmsData(url, fields)
      .subscribe(
        {next:(data) => {//this.notify.notify('Groups order updated','Dismiss');
        },
        error :(err) => this.notify.notify(this.fnc.getErrorMessage(err), 'error'),}
      );

  }
  sortSubFields() {
    let fields = [];
    let val = {};
    if (!this.mastervalues_access.PATCH) {
      this.notify.notify('You are not authorize to update Mastervalue', 'error')
      return;
    }
    this.sub_master_value_list.forEach((v, k) => {
      v.sno = k + 1; // serial number
      val = {
        "sort_order": k,
        "master_value_id": v.id,
        "updated_by": this.api.user_id,
      };

      fields.push(val);
    });
    let url = this.selected_entity_type + "/masters/" + this.selected_sub_master.master_id + '/values/'

    this.api.patchEmsData(url, fields)
    .subscribe(
      {next:(data) => {//this.notify.notify('Groups order updated','Dismiss');
      },
      error :(err) => this.notify.notify(this.fnc.getErrorMessage(err), 'error'),}
    );
  }
  downloadMaster() {

    let dialogRef = this.dialog.open(MasterDownloadComponent, {
      width: '350px',
      panelClass: 'create-master-panel',
      data: { parent: this },//optional
      disableClose:true,
    });
    dialogRef.afterClosed().subscribe(result => {

    });

  }

}


