<div>
    <div fxLayout="row" class="w-100 crumb-c" fxLayoutAlign="space-between center">
        <div fxLayout="row" class="w-100 bleft" fxLayoutAlign="space-between center" style="overflow: auto;">
            <admin-breadcrumb></admin-breadcrumb>
            <div fxLayout="row" fxLayoutAlign="end center" class="px-2">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="search-bar">
                    <div *ngIf="selected_assignment" class="group-icon-post">

                        <div class="badge1" matTooltip="{{_deleted.length + _demolished.length + _multi_parcel.length + _review_later.length}}" matTooltipPosition="above">
                            {{_deleted.length + _demolished.length + _multi_parcel.length + _review_later.length}}</div>

                        <img class="pointer" [matMenuTriggerFor]="menuAction" matTooltip="filter" [src]="group_active_icon ? '../../../assets/icon/group-icon-active.svg' : '../../../assets/icon/group-icon.svg'" width="22px"  alt="">
                        <mat-menu #menuAction="matMenu">
                            <div class="group-icon" (click)="$event.stopPropagation();">
                                <app-step-toggle *ngIf="showStepToggle" [deleted_count]="_deleted.length" [demolished_count]="_demolished.length" [multi_parcel]="_multi_parcel.length" [entity_type]="entity_type" [review_count]="_review_later.length"
                                 (selectedOption)="buildStatus($event)"
                                (changeOption)="group_active_icon = $event"></app-step-toggle>
                           </div>
                        </mat-menu>
                    </div>
                    <!-- <strong class="fnt">Select Assignment:</strong> -->
                    <mat-select (selectionChange)="updateAllRecords('assignment',$event)" (openedChange)="filterTerm=''" placeholder="Assignment" class="form-select-l ovrd100" [disabled]="assignment_list.length == 0">
                        <div class="search_bar"> <input type="text" mat-input [(ngModel)]="filterTerm" placeholder="Search" (keydown)="$event.stopPropagation()"></div>
                        <mat-option *ngFor="let data of assignment_list | searchfilter:'label':filterTerm" [value]="data.assignment_id">{{data.label}}-{{data?.year}}</mat-option>
                    </mat-select>

                    <!-- <span class="fnt b7">Select User:</span> -->
                    <mat-select *ngIf="entity_type == 'property'" [disabled]="!listEnable" (selectionChange)="updateAllRecords('user',$event)" placeholder="User" class="form-select-l ovrd100" [(value)]="selected_user_id">
                        <mat-option *ngFor="let data of user_list" [value]="data.id">{{data.username}}</mat-option>
                    </mat-select>

                    <!-- <span class="fnt b7">Select status:</span> -->
                    <!-- <mat-select *ngIf="entity_type == 'property'" (selectionChange)="updateAllRecords('status',$event)"
                            placeholder="Status" [(value)]="property_status" class="form-select-l ovrd100" [disabled]="!dataSource">
                            <mat-option *ngFor="let option of options" [value]="option.value">{{option.label}}</mat-option>
                         </mat-select> -->

                    <mat-select *ngIf="entity_type == 'property_unit'" (selectionChange)="updateAllRecords('property_type',$event)" placeholder="Property Type" [(value)]="property_type_id" class="form-select-l ovrd100">
                        <ng-container *ngFor="let type of property_type_list">
                            <mat-option [value]="type.id">{{type.name}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <ng-container *ngIf="selected_assignment">
                        <div class="v-bar"></div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <button mat-raised-button class="all-btn1" (click)="updateAllRecords('status',{value:'2,3,7'})" [ngClass]="('2,3,7' == property_status)?'selection-br':''" matTooltip="Select all" matTooltipPosition="above">
                                <img class="all-select" src="./assets/images/selectAll.png" alt="All">
                            </button>
                            <div class="status_filter" *ngFor="let sts of options" (click)="updateAllRecords('status',sts)">
                                <div class="badge1" matTooltip="{{statusCount(sts.value)}}" matTooltipPosition="above">
                                    {{statusCount(sts.value)}}</div>
                                <div class="fbtn" [ngClass]="(sts.value == property_status)?'selection-br':''" matTooltip="{{sts.label}}" matTooltipPosition="below">
                                    <div class="mark" [ngClass]="getStatus(sts)"></div>
                                </div>
                            </div>
                            <button mat-icon-button [matMenuTriggerFor]="menuStatus" matTooltip="Show Demolished">
                                <mat-icon [class.active_menu]="add_status_2">more_vert</mat-icon>
                            </button>
                            <mat-menu #menuStatus="matMenu">
                                <div class="check_box_design">
                                    <mat-checkbox (change)="addStatus($event)" matTooltip="Show Demolished" matTooltipPosition="below">
                                        <span style="padding: 0 0;">Demolished</span> </mat-checkbox>
                                </div>
                            </mat-menu>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="entity_type == 'property' && selected_assignment">
                        <div class="v-bar"></div>

                        <mat-select [(ngModel)]="neigh_uid" (selectionChange)="getBlocksByNeigh($event)" (click)="searchTerm = ''; filteredNeighList = neighbourhoodList" placeholder="Neighbourhood" class="form-select-l ovrd100">
                            <div class="rel w-100 cen">
                                <div class="search_bar"> <input [ngModelOptions]="{standalone: true}" type="text" [(ngModel)]="searchTerm" placeholder="Search" (keyup)="find('neigh')" (keydown)="$event.stopPropagation()" /></div>
                                <mat-option [value]="0">All</mat-option>
                                <mat-option *ngFor="let data of filteredNeighList" [value]="data.neigh_uid">
                                    {{data.neigh_name}}
                                </mat-option>
                            </div>
                        </mat-select>

                        <mat-select [(ngModel)]="block_uid" (selectionChange)="getStreetsByBlock($event)" (click)="searchTerm = ''; filteredBlockList = blockList" placeholder="Block" class="form-select-l ovrd100">
                            <div class="rel w-100 cen">
                                <div class="search_bar">  <input [ngModelOptions]="{standalone: true}" type="text" [(ngModel)]="searchTerm" placeholder="Search" (keyup)="find('block')" (keydown)="$event.stopPropagation()" /></div>
                                <mat-option *ngIf="filteredBlockList.length>0" [value]="0">All</mat-option>
                                <mat-option *ngFor="let data of filteredBlockList" [value]="data.block_uid">
                                    {{data.block_name}}
                                </mat-option>
                            </div>
                        </mat-select>
                        <mat-select class="form-select-l ovrd100" placeholder="Sort By" [(ngModel)]="orderBy" (selectionChange)="sortBy($event.value)">
                            <mat-option *ngFor="let type of sort_by" [value]="type.value">
                                {{ type.label }}
                            </mat-option>
                        </mat-select>
                        <mat-select class="form-select-l ovrd100" [(ngModel)]="sortAsc" placeholder="Sort Order" (selectionChange)="sortOrder($event.value)">
                            <mat-option *ngFor="let value of sort_order" [value]="value.value">
                                {{ value.label }}
                            </mat-option>
                        </mat-select>
                    </ng-container>
                    <ng-container *ngIf="entity_type == 'property_floor' && selected_assignment">
                        <div class="v-bar"></div>
                        <mat-select class="form-select-l ovrd100" placeholder="Sort By" [(ngModel)]="orderByFloor" (selectionChange)="sortBy($event.value)">
                            <mat-option *ngFor="let type of sort_by_floor" [value]="type.value">
                                {{ type.label }}
                            </mat-option>
                        </mat-select>
                        <mat-select class="form-select-l ovrd100" [(ngModel)]="sortfloor" placeholder="Sort Order" (selectionChange)="sortOrder($event.value)">
                            <mat-option *ngFor="let value of sort_order" [value]="value.value">
                                {{ value.label }}
                            </mat-option>
                        </mat-select>
                    </ng-container>
                    <ng-container *ngIf="entity_type == 'property_unit' && selected_assignment">
                        <div class="v-bar"></div>
                        <mat-select class="form-select-l ovrd100" placeholder="Sort By" [(ngModel)]="orderByUnit" (selectionChange)="sortBy($event.value)">
                            <mat-option *ngFor="let type of sort_by_unit" [value]="type.value">
                                {{ type.label }}
                            </mat-option>
                        </mat-select>
                        <mat-select class="form-select-l ovrd100" [(ngModel)]="sortunit" placeholder="Sort Order" (selectionChange)="sortOrder($event.value)">
                            <mat-option *ngFor="let value of sort_order" [value]="value.value">
                                {{ value.label }}
                            </mat-option>
                        </mat-select>
                    </ng-container>
                    <input [formControl]="searchField" class="form-input-l search-inputbox" placeholder="Type to search..." type="text">
                    <div  fxLayout="row" fxLayoutAlign="space-between center" *ngIf="selected_assignment">
                        <button class="sync-icon" matTooltip="sync" matTooltipPosition="below" (click)="historySync()">
                            <img src="../../../assets/icon/sync-icon.svg"  alt="">
                        </button>
                        <button class="show-all-year">
                            <mat-checkbox matTooltip="Show all year" (change)="getNewProperty($event)" [checked]="all_year"
                             matTooltipPosition="below" ></mat-checkbox>
                        </button>
                    </div>
                    <ng-container *ngIf="entity_type == 'property'">
                        <button matTooltip="Download" class="sync-icon" [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <!-- No of property : <input type="number" class="form-input-l search-inputbox1"> -->
                            <button mat-menu-item (click)="downloadProperties()">
                                <mat-icon>cloud_download</mat-icon>
                                <span>Download</span>
                            </button>

                        </mat-menu>
                    </ng-container>

                </div>
            </div>
        </div>
        <!-- <div fxLayout="row" class="bleft" fxLayoutAlign="end center">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="button-b">
                <button mat-button class="orange-button" (click)="openDialog()" [disabled]="!selected_assignment">Submit</button>
            </div>
        </div> -->
    </div>
</div>

<div class="w-100">
    <mat-drawer-container class="" hasBackdrop="false">
        <mat-drawer #sideNav mode="over" opened="false" [position]="nav_position">
            <app-review-map *ngIf="rowData" [parent]="this" [rowData]="rowData" [selectedAssignment]="selected_assignment" (sideNavToggle)='sidenavTrigger($event)'>
            </app-review-map>
        </mat-drawer>

        <mat-drawer #sidePNev mode="over" opened="false" [position]="navp_position">
            <app-parcel-map *ngIf="rowData" [parent]="this" [rowData]="rowData" [selectedAssignment]="selected_assignment" (sidePNavToggle)='sidePNevTrigger($event)'>
            </app-parcel-map>
        </mat-drawer>

        <mat-drawer #sideUnitMapNav mode="over" opened="false" position="end">
            <app-unit-map *ngIf="mapView == 'unit_map'" [parent]="this" [selectedAssignment]="selected_assignment" (sidesidesideUnitMapToggle)='sidesideUnitMapNavTrigger($event)'>
            </app-unit-map>
        </mat-drawer>
        

        <mat-drawer-content *ngIf="selected_assignment">

            <!-- <button (click)="sideNav.toggle()" mat-button>Toggle SideNav</button>
      <button (click)="onTogglePosition(nav_position)" mat-button>Toggle Position {{ nav_position }}</button> -->

            <div class="container1">
                <div class="w-100 bleft rel">
                    <div class="grid50ph-start rel">
                      <button matTooltipPosition="above" matTooltip="Bulk Approve" *ngIf="entity_type == 'property' && selectedIds.length > 0 && (property_status == '2' || property_status == '3' || property_status == '7')" class="tickBtn" mat-mini-fab (click)="openBulkDialog()">
                          <mat-icon>done_all</mat-icon>
                      </button>
                      <button  matTooltipPosition="above" matTooltip="Bulk Approve" *ngIf="(entity_type == 'property_floor' || entity_type == 'property_unit') && selectedIds.length > 0 && (property_status == '2' )" class="tickBtn" mat-mini-fab (click)="bulkReject()">
                          <mat-icon>done_all</mat-icon>
                      </button>
                        <div *ngIf="selected_assignment && (getCount('count') || getCount('count') ==0)">
                            <span class="w-100 title ptc"> Total - <b class="bolder ptc">{{getCount('count')}}</b>
                            </span><br />
                            <span class="w-100 title ptc">Unapproved - </span>
                            <span class="bolder ptc">{{getCount('count') - (getCount('count') ? statusCount(5) : 0)}}</span>
                        </div>
                        <div *ngIf="(groups.length == 0 && !group_loader && !loader) &&  dataSource?.length" class="no-group">
                            <h4>Groups/attributes are not configured. Please <a routerLink="/admin/field">configure</a> to see the records.</h4>
                        </div>
                        <ng-container *ngIf="groups">
                            <div class="scrollh">
                                <div class="grid-auto">
                                    <ng-container *ngFor="let data of groups">
                                        <button (click)="getGroupAttribute(data)" *ngIf="data.childrens.length == 0" mat-button class="grp" [ngClass]="{'active':isSelectedGroup(data)}">
                                            <span>
                                                {{data.group_name}}
                                            </span>
                                        </button>
                                        <button *ngIf="data.childrens.length > 0" [ngClass]="{'active':isSelectedGroup(data)}" (click)="group_selected(data)" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{arr: data.childrens}" mat-button class="grp">
                                            <ng-container *ngIf="selected_group">
                                                <ng-container *ngIf="data.id == selected_group.parent_id">
                                                    {{selected_group.group_name}}
                                                </ng-container>
                                                <span *ngIf="data.id != selected_group.parent_id">
                                                    {{data.group_name}}
                                                </span>
                                            </ng-container>
                                            <ng-container *ngIf="!selected_group">
                                                <span>
                                                    {{data.group_name}}
                                                </span>
                                            </ng-container>
                                            <mat-icon class="tr90">play_arrow</mat-icon>
                                        </button>
                                    </ng-container>
                                    <mat-menu #menu="matMenu">
                                        <ng-template let-arr="arr" matMenuContent>
                                            <button (click)="getGroupAttribute(ch)" *ngFor="let ch of arr" mat-menu-item>{{getLabel(ch.labels)}}</button>
                                        </ng-template>
                                    </mat-menu>
                                </div>
                            </div>
                        </ng-container>

                    </div>

                </div>
                <div class="demolished-data">
                    <button (click)="downloadDemolish()"><mat-icon>cloud_download</mat-icon>
                         To Be Demolished Data</button>
                </div>
            </div>

            <div class="container rel property-container">
                <!-- <ngx-loading [show]="downloadLoader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}">
                </ngx-loading> -->

                <div class="table-estater table-estater_over">
                    <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}">
                    </ngx-loading>
                    <!-- <div class="alin-m" *ngIf="!showTable && selected_assignment && !loader && selected_group">
                        <span>No attributes found in the selected group.</span>
                    </div> -->
                    <mat-table #table cdkDropList [cdkDropListData]="dataSource" (cdkDropListDropped)="onTableDrop($event)" [cdkDropListDisabled]="dragDisabled" [dataSource]="dataSource | paginate: { id: 'server', itemsPerPage: property_limit, currentPage: this.pageIndex , totalItems: statusCount(property_status) }">

                        <ng-container *ngFor="let column of columns" [cdkColumnDef]="column.columnDef">

                            <!-- table header -->
                            <mat-header-cell class="chk-header rel streched" *cdkHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center" title="{{ column.header }}">

                                <div class="bIco11" *ngIf="(column.columnDef == 'action' && entity_type == 'property') && (property_status == 2 || property_status == 3 || property_status == 7) ">
                                    <mat-checkbox (change)="changeSelectedAllIds($event)" [checked]="selectedIds.length == property_list.length && property_list.length !=0">
                                    </mat-checkbox>
                                </div>

                                <div class="bIco11" *ngIf="(column.columnDef == 'action' && entity_type == 'property_floor') && (property_status == 2)">
                                    <mat-checkbox (change)="changeSelectedAllIds($event)" [checked]="selectedIds.length == floor_list.length && floor_list.length !=0">
                                    </mat-checkbox>
                                </div>

                                <div class="bIco11" *ngIf="(column.columnDef == 'action' && entity_type == 'property_unit') && (property_status == 2)">
                                    <mat-checkbox (change)="changeSelectedAllIds($event)" [checked]="selectedIds.length == unit_list.length && unit_list.length !=0">
                                    </mat-checkbox>
                                </div>

                                <p>{{ column.header }}{{getChildLabel(column.id)}}
                                    <span class="unit" *ngIf="getAttributeDetail(column.id).measure_unit">({{getAttributeDetail(column.id).measure_unit}})</span>
                                </p>
                            </mat-header-cell>

                            <!-- table cell -->
                            <mat-cell class="cell" [ngClass]="{'edited-attrib':isEdited(row,column.id), 'rejected-attrib':isRejected(row,column.id), 'rejected-cell':wasRejected(row,column.id), 'newcreated': isNewlyCreated(row, column.columnDef)}" *cdkCellDef="let row" fxLayout="row"
                                fxLayoutAlign="center center">

                                <ng-container *ngIf="column.columnDef == 'action'">

                                    <div class="grid-act">
                                        <div class="bIco">
                                            <!-- checkbox for multiple property seletion -->
                                            <mat-checkbox *ngIf="entity_type == 'property' && (row.status == '2' || row.status == '3' || row.status == '7') && (property_status != '2,3,7')" (change)="changeSelectedIds($event, row)" [checked]="isChecked(row.id)">
                                            </mat-checkbox>

                                            <!-- checkbox for multiple floor seletion -->
                                            <mat-checkbox *ngIf="entity_type == 'property_floor' && (row.status == '2')" (change)="changeSelectedIds($event, row)" [checked]="isChecked(row.id)">
                                            </mat-checkbox>

                                            <!-- checkbox for multiple unit seletion -->
                                            <mat-checkbox *ngIf="entity_type == 'property_unit' && (row.status == '2')" (change)="changeSelectedIds($event, row)" [checked]="isChecked(row.id)">
                                            </mat-checkbox>

                                        </div>

                                        <div class="bIco">
                                            <mat-icon *ngIf="entity_type == 'property'" (click)="switchEntity('property_floor',row.uid)" class="icn" svgIcon="floor" title="Floor"></mat-icon>
                                            <mat-icon *ngIf="entity_type == 'property_floor'" (click)="switchEntity('property_unit',row.uid)" class="icn" svgIcon="unit" title="Unit"></mat-icon>
                                        </div>
                                        <div [ngClass]="{'bIco': !isExpanded(row), 'bIco1-inverse': isExpanded(row)}" matRipple (click)="toggleRow(row)">
                                            <mat-icon class="icn-fnt-normal" *ngIf="!isExpanded(row)" title="Expand">add
                                            </mat-icon>
                                            <mat-icon class="icn-fnt-toggle" *ngIf="isExpanded(row)" title="Hide">remove
                                            </mat-icon>
                                        </div>

                                        <div class="bIco bdrh" (click)="setStatus(row, column.columnDef)" title="{{getStatus(row)}}">
                                            <div class="mark" [ngClass]="getStatus(row)" title="{{getStatus(row)}}">
                                            </div>
                                        </div>
                                        <div class="abs9" *ngIf="showStatus && row.uid == selected_row && column.columnDef == selected_col">
                                            <ngx-loading [show]="loader_status" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}">
                                            </ngx-loading>
                                            <mat-select (selectionChange)="selectStatus($event)" placeholder="Select" class="form-select-l ovrd">
                                                <mat-option [value]="data.value" *ngFor="let data of property_options">
                                                    <div class="alin-c">
                                                        <div class="mark" [ngStyle]="{'background': data.color}"></div>
                                                        <span class="fnt-xsm">{{data.label}}</span>
                                                    </div>
                                                </mat-option>
                                            </mat-select>
                                            <div class="alin-c">
                                                <div class="btn-xsm" (click)="saveStatus()">
                                                    <mat-icon class="icn-fnt-normal blue">done</mat-icon>
                                                </div>
                                                <div class="btn-xsm" (click)="cancelStatus()">
                                                    <mat-icon class="icn-fnt-normal danger">close</mat-icon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="">
                                        <span class="revers_no">{{row.revers_no}}</span>
                                        <span class="dateshower"  title="Added date - {{getDate(row.added_date)}}">A -
                                            {{getDate(row.added_date)}}</span>
                                        <span class="submitted_date"  title="Submitted date - {{getDate(row.submitted_date)}}">S -
                                            {{getDate(row.submitted_date)}}</span>
                                    </div>

                                </ng-container>
                                <ng-container *ngIf="column.columnDef == 'user'">

                                    <div class="grid-50p" *ngIf="checkUser(row) == false">
                                        <div class="cont40 rel cp" title="{{row?.assigned_to_name}}">
                                            <span *ngIf="row.profile_image" class="alin-m">
                                                <img class="avatar" src="{{row.profile_image}}"
                                                    onerror="this.onerror=null; this.src='assets/images/user.png'"
                                                    alt="user">
                                            </span>
                                            <span *ngIf="!row.profile_image" class="avatar-t alin-m cp">
                                                {{getInitials(row?.assigned_to_name)}}
                                            </span>
                                            <div class="move" *ngIf="entity_type == 'property_floor' && property_status !== '5'" (mousedown)="dragDisabled = (entity_type == 'property_floor')?false:true">
                                                <img class="" src="assets/images/drag.png" alt="">

                                            </div>
                                        </div>
                                        <div class="dfc">
                                            <div *ngIf="row.uid" class="rel">
                                                <span class="fnt-normal fxw lnk" title="{{row.uid}}" (click)="switch(row)">{{row.uid}}</span>
                                                <span class="sort-or ml-1" *ngIf="row.floor_id">{{row.sort_order}}</span>

                                                <span class="abs-pin">
                                                    <!-- <img matTooltip="{{row.added_date}}" src="../../../assets/icon/calender.png"> -->
                                                    <mat-icon class="pin" *ngIf="entity_type == 'property'"
                                                        matTooltip="View/Edit Address" (click)="openMap(row)">
                                                        location_on</mat-icon>
                                                    <!-- <img matTooltip="{{row.added_date}}" src="../../../assets/icon/calender.png"> -->
                                                </span>
                                                <!-- <span class="abs-pin">

                                                <img matTooltip="{{row.added_date}}" src="../../../assets/icon/calender.png">
                                                </span> -->



                                            </div>

                                            <div *ngIf="getIdentityName(row)">
                                                <span class="fnt-normal fxw uname cp" title="{{getIdentityName(row)}}">{{getIdentityName(row)}}</span>
                                            </div>
                                            <div *ngIf="row.no_of_floors>=0 || row.no_of_units>=0">
                                                <span>
                                                    <span *ngIf="row.no_of_floors >= 0" class="fl-text">Floors:
                                                        <span class="fnt-normal">{{row.no_of_floors}}</span>
                                                </span>
                                                <span *ngIf="row.no_of_units >= 0" class="un-text">Units:
                                                        <span class="fnt-normal">{{row.no_of_units}}</span>
                                                </span>
                                                <span class="parcel_map" *ngIf="showMap(row.uid)" matTooltip="View/Edit Parcel" (click)="openParcelMap(row)">
                                                        <mat-icon class="parcel_map_icon">map</mat-icon>
                                                    </span>

                                                </span>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="alin-x" *ngIf="checkUser(row) == true">
                                        <mat-select (selectionChange)="setUser($event)" class="form-select-l" placeholder="Select User">
                                            <ng-container *ngFor="let data of user_list">
                                                <mat-option *ngIf="data.id != 0" [value]="data.id">{{data.username}}
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                        <div class="alin-m h100">
                                            <div class="btn-md">
                                                <mat-icon class="icn-fnt-normal blue" (click)="saveUser()">done
                                                </mat-icon>
                                            </div>
                                            <div class="btn-md">
                                                <mat-icon class="icn-fnt-normal danger" (click)="cancelUser()">close
                                                </mat-icon>
                                            </div>
                                        </div>
                                    </div>

                                </ng-container>

                                <!-- pop up div -->
                                <div class="abs2-auto mat-elevation-z8" *ngIf="boxToggle && row.uid == selected_row && column.columnDef == selected_col">
                                    <form id="property_form" [formGroup]="propertyForm" (ngSubmit)="updateProperty(propertyForm)">
                                        <div class="inner-cont">
                                            <div class="innermost-div">
                                                <!-- Data type not table -->
                                                <ng-container *ngIf="current_attribute.data_type != 'table'">
                                                    <div class="alin">
                                                        <input type="radio" id="radio_{{current_attribute.id}}_1" name="radio_{{current_attribute.id}}" *ngIf="current_attribute.mutual.length > 0" (change)="showChild(current_attribute,$event,1)" />

                                                        <input class="form-input-l" type="text" matInput placeholder="Edit current value" id="input_{{current_attribute.id}}" formControlName="input_{{current_attribute.id}}" *ngIf="current_attribute.data_type == 'string-input'" />

                                                        <input class="form-input-l" type="number" matInput placeholder="Edit current value" id="input_{{current_attribute.id}}" formControlName="input_{{current_attribute.id}}" *ngIf="current_attribute.data_type == 'number-input' || current_attribute.data_type == 'decimal-input'"
                                                            onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 46) ? null : event.charCode >= 48 && event.charCode <= 57" />

                                                        <input class="form-input-l" type="date" id="input_{{current_attribute.id}}" formControlName="input_{{current_attribute.id}}" *ngIf="current_attribute.data_type == 'date'" />

                                                        <!-- <mat-checkbox id="input_{{current_attribute.id}}" formControlName="input_{{current_attribute.id}}"
                                                                    *ngIf="current_attribute.data_type == 'toggle'"></mat-checkbox> -->

                                                        <div class="toggle" *ngIf="current_attribute.data_type == 'toggle'">
                                                            <label class="switch">
                                                                <input type="checkbox"
                                                                    id="input_{{current_attribute.id}}"
                                                                    formControlName="input_{{current_attribute.id}}">
                                                                <span class="slider round"></span>
                                                            </label>
                                                        </div>



                                                        <div *ngIf="current_attribute.data_type == 'single-select'" (click)="openMultiSelect(current_attribute)" [ngClass]="{'disabled-div':!current_attribute.is_editable}" class="lnk fixed-in-1">
                                                            <mat-select formControlName="input_{{current_attribute.id}}" id="input_{{current_attribute.id}}" class="hidden lnk" [value]="getSelectedValues(current_attribute, true)">
                                                            </mat-select>
                                                            <output disabled="disabled" class="form-input-l sel-area lnk" id="input_custom_{{current_attribute.id}}"></output>
                                                        </div>

                                                        <div *ngIf="current_attribute.data_type == 'multi-select'" (click)="openMultiSelect(current_attribute)" [ngClass]="{'disabled-div':!current_attribute.is_editable}" class="lnk fixed-in-1">
                                                            <mat-select formControlName="input_{{current_attribute.id}}" id="input_{{current_attribute.id}}" class="hidden lnk" [value]="getSelectedValues(current_attribute)">
                                                            </mat-select>
                                                            <output disabled="disabled" class="form-input-l sel-area lnk" id="input_custom_{{current_attribute.id}}"></output>
                                                        </div>
                                                    </div>

                                                    <!-- Data type not table -->

                                                    <!-- form for child start -->
                                                    <ng-container *ngIf="current_attribute.child.length >= 1">

                                                        <ng-container *ngFor="let child of current_attribute.child">
                                                            <div class="alin">
                                                                <div>
                                                                    <input type="radio" id="radio_{{child.id}}_1" name="radio_{{child.id}}" *ngIf="child.mutual.length > 0" (change)="showChild(child,$event,1)" />

                                                                    <input class="form-input-l" type="text" matInput placeholder="Edit current value" id="input_{{child.id}}" formControlName="input_{{child.id}}" *ngIf="child.data_type == 'string-input'" />
                                                                    <input class="form-input-l" type="number" matInput placeholder="Edit current value" id="input_{{child.id}}" formControlName="input_{{child.id}}" *ngIf="child.data_type == 'number-input' || child.data_type == 'decimal-input'" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 46) ? null : event.charCode >= 48 && event.charCode <= 57"
                                                                    />
                                                                    <input class="form-input-l" type="date" *ngIf="child.data_type == 'date'" id="input_{{child.id}}" formControlName="input_{{child.id}}" />

                                                                    <!-- <mat-checkbox id="input_{{child.id}}" formControlName="input_{{child.id}}" *ngIf="child.data_type == 'toggle'"></mat-checkbox> -->
                                                                    <div class="toggle" *ngIf="child.data_type == 'toggle'">
                                                                        <label class="switch">
                                                                            <input type="checkbox"
                                                                                id="input_{{child.id}}"
                                                                                formControlName="input_{{child.id}}">
                                                                            <span class="slider round"></span>
                                                                        </label>
                                                                    </div>


                                                                    <div *ngIf="child.data_type == 'single-select'" (click)="openMultiSelect(child,current_attribute)" [ngClass]="{'disabled-div':!child.is_editable}" class="lnk fixed-in-1">
                                                                        <mat-select formControlName="input_{{child.id}}" id="input_{{child.id}}" class="hidden lnk" [value]="getSelectedValues(child, true)">
                                                                        </mat-select>
                                                                        <output disabled="disabled" class="form-input-l sel-area lnk" id="input_custom_{{child.id}}"></output>
                                                                    </div>

                                                                    <div *ngIf="child.data_type == 'multi-select'" (click)="openMultiSelect(child,current_attribute)" [ngClass]="{'disabled-div':!child.is_editable}" class="lnk fixed-in-1">
                                                                        <mat-select formControlName="input_{{child.id}}" id="input_{{child.id}}" class="hidden lnk" [value]="getSelectedValues(child)">
                                                                        </mat-select>
                                                                        <output disabled="disabled" class="form-input-l sel-area lnk" id="input_custom_{{child.id}}"></output>
                                                                    </div>
                                                                </div>

                                                                <!-- mutual under child start -->
                                                                <div>
                                                                    <ng-container *ngIf="child.mutual.length > 0">
                                                                        <input type="radio" id="radio_{{child.id}}_2" name="radio_{{child.id}}" (change)="showChild(child,$event,2)" />
                                                                        <div id="div_{{current_attribute.id}}_2" class="disabled-div">
                                                                            <ng-container *ngFor="let mt of child.mutual">
                                                                                <input class="form-input-l" type="text" matInput placeholder="Edit current value" id="input_{{mt.id}}" formControlName="input_{{mt.id}}" *ngIf="mt.data_type == 'string-input'" />
                                                                                <input class="form-input-l" type="number" matInput placeholder="Edit current value" id="input_{{mt.id}}" formControlName="input_{{mt.id}}" *ngIf="mt.data_type == 'number-input' || mt.data_type == 'decimal-input'" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 46) ? null : event.charCode >= 48 && event.charCode <= 57"
                                                                                />
                                                                                <input class="form-input-l" type="date" *ngIf="mt.data_type == 'date'" id="input_{{mt.id}}" formControlName="input_{{mt.id}}" />

                                                                                <!-- <mat-checkbox id="input_{{mt.id}}" formControlName="input_{{mt.id}}" *ngIf="mt.data_type == 'toggle'"></mat-checkbox> -->
                                                                                <div class="toggle" *ngIf="mt.data_type == 'toggle'">
                                                                                    <label class="switch">
                                                                                        <input type="checkbox"
                                                                                            id="input_{{mt.id}}"
                                                                                            formControlName="input_{{mt.id}}">
                                                                                        <span
                                                                                            class="slider round"></span>
                                                                                    </label>
                                                                                </div>

                                                                                <div *ngIf="mt.data_type == 'single-select'" (click)="openMultiSelect(mt,child)" [ngClass]="{'disabled-div':!mt.is_editable}" class="lnk fixed-in-1">
                                                                                    <mat-select formControlName="input_{{mt.id}}" id="input_{{mt.id}}" class="hidden lnk" [value]="getSelectedValues(mt, true)">
                                                                                    </mat-select>
                                                                                    <output type="text" disabled="disabled" class="form-input-l sel-area lnk" id="input_custom_{{mt.id}}" ></output>
                                                                                </div>

                                                                                <div *ngIf="mt.data_type == 'multi-select'" (click)="openMultiSelect(mt,child)" [ngClass]="{'disabled-div':!mt.is_editable}" class="lnk fixed-in-1">
                                                                                    <mat-select formControlName="input_{{mt.id}}" id="input_{{mt.id}}" class="hidden lnk" [value]="getSelectedValues(mt)">
                                                                                    </mat-select>
                                                                                    <output type="text" disabled="disabled" class="form-input-l sel-area lnk" id="input_custom_{{mt.id}}"></output>
                                                                                </div>
                                                                            </ng-container>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                            <!-- mutual under child end-->
                                                        </ng-container>
                                                    </ng-container>

                                                    <!-- mutual attributes start -->
                                                    <ng-container *ngIf="current_attribute.mutual.length > 0">
                                                        <input type="radio" id="radio_{{current_attribute.id}}_2" name="radio_{{current_attribute.id}}" (change)="showChild(current_attribute,$event,2)" />
                                                        <div id="div_{{current_attribute.id}}_2" class="disabled-div">
                                                            <ng-container *ngFor="let mt of current_attribute.mutual">

                                                                <input class="form-input-l" type="text" matInput placeholder="Edit current value" id="input_{{mt.id}}" formControlName="input_{{mt.id}}" *ngIf="mt.data_type == 'string-input'" />
                                                                <input class="form-input-l" type="number" matInput placeholder="Edit current value" id="input_{{mt.id}}" formControlName="input_{{mt.id}}" *ngIf="mt.data_type == 'number-input' || mt.data_type == 'decimal-input'" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 46) ? null : event.charCode >= 48 && event.charCode <= 57"
                                                                />

                                                                <input class="form-input-l" type="date" *ngIf="mt.data_type == 'date'" id="input_{{mt.id}}" formControlName="input_{{mt.id}}" />

                                                                <!-- <mat-checkbox id="input_{{mt.id}}" formControlName="input_{{mt.id}}" *ngIf="mt.data_type == 'toggle'"></mat-checkbox> -->
                                                                <div class="toggle" *ngIf="mt.data_type == 'toggle'">
                                                                    <label class="switch">
                                                                        <input type="checkbox" id="input_{{mt.id}}"
                                                                            formControlName="input_{{mt.id}}">
                                                                        <span class="slider round"></span>
                                                                    </label>
                                                                </div>

                                                                <div *ngIf="mt.data_type == 'single-select'" (click)="openMultiSelect(mt,parent)" [ngClass]="{'disabled-div':!mt.is_editable}" class="lnk fixed-in-1">
                                                                    <mat-select formControlName="input_{{mt.id}}" id="input_{{mt.id}}" class="hidden lnk" [value]="getSelectedValues(mt, true)">
                                                                    </mat-select>
                                                                    <output type="text" disabled="disabled" class="form-input-l sel-area lnk" id="input_custom_{{mt.id}}"></output>
                                                                </div>

                                                                <div *ngIf="mt.data_type == 'multi-select'" (click)="openMultiSelect(mt,parent)" [ngClass]="{'disabled-div':!mt.is_editable}" class="lnk fixed-in-1">
                                                                    <mat-select formControlName="input_{{mt.id}}" id="input_{{mt.id}}" class="hidden lnk" [value]="getSelectedValues(mt)">
                                                                    </mat-select>
                                                                    <output type="text" disabled="disabled" class="form-input-l sel-area lnk" id="input_custom_{{mt.id}}" ></output>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                    <!-- mutual attributes end -->
                                                </ng-container>

                                                <!-- Data type table -->
                                                <ng-container *ngIf="current_attribute.data_type == 'table'">
                                                    <div class="tableData">
                                                        <label class="label-text">{{current_attribute.label}}</label>
                                                        <div class="master-container" *ngFor="let masterval of getMasterById(current_attribute.master_id)">
                                                            <label>{{masterval.label}}</label>
                                                            <div class="master-field" *ngIf="masterval">
                                                                <ng-container *ngFor="let child of current_attribute.child">
                                                                    <input class="inp-area form-input-l" type="text" placeholder="{{child.label}}" matInput id="input_{{masterval.id}}_{{child.id}}" formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'string-input'" />
                                                                    <input class="inp-area form-input-l" type="number" placeholder="{{child.label}}" matInput id="input_{{masterval.id}}_{{child.id}}" formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'number-input' || child.data_type == 'decimal-input'" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 46) ? null : event.charCode >= 48 && event.charCode <= 57"
                                                                    />
                                                                    <input class="inp-area form-input-l" type="date" placeholder="{{child.label}}" id="input_{{masterval.id}}_{{child.id}}" formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'date'" />

                                                                    <div class="toggle" *ngIf="child.data_type == 'toggle'">
                                                                        <label class="switch">
                                                                            <input type="checkbox"
                                                                                id="input_{{masterval.id}}_{{child.id}}"
                                                                                formControlName="input_{{masterval.id}}_{{child.id}}">
                                                                            <span class="slider round"></span>
                                                                        </label>
                                                                    </div>

                                                                    <mat-select class="form-select-l margb0" placeholder="{{child.label}}" id="input_{{masterval.id}}_{{child.id}}" formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'single-select'">
                                                                        <mat-option *ngFor="let master of getMasterByValueId(child,masterval.id)" class="ddList" value="{{master.id}}">
                                                                            {{master.label}}</mat-option>
                                                                    </mat-select>

                                                                    <mat-select class="form-select-l margb0" placeholder="{{child.label}}" multiple id="input_{{masterval.id}}_{{child.id}}" formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'multi-select'">
                                                                        <mat-option *ngFor="let master of getMasterByValueId(child,masterval.id)" class="ddList" value="{{master.id}}">
                                                                            {{master.label}}</mat-option>
                                                                    </mat-select>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>

                                        </div>
                                        <div class="abs7">
                                            <div *ngIf="!viewMode" class="btn-sm" (click)="saveView('saved')">
                                                <mat-icon class="icn-fnt-normal blue">save</mat-icon>
                                            </div>
                                            <div class="btn-sm" (click)="closeView()">
                                                <mat-icon class="icn-fnt-normal danger">close</mat-icon>
                                            </div>
                                        </div>
                                        <!-- using hidden button to submit the form   -->
                                        <input style="display: none" id="property_form_btn" type="submit" name="submit" />
                                    </form>
                                </div>
                                <div class="abs2 mat-elevation-z8 pad5 grid-50v" *ngIf="deleteToggle && row.uid == selected_row && column.columnDef == selected_col">
                                    <mat-form-field floatLabel="never" class="full-width rel noline">
                                        <textarea class="area" matInput placeholder="Leave a comment" rows="3" [(ngModel)]="comment_rejected"></textarea>
                                    </mat-form-field>
                                    <div class="abs7">
                                        <div class="btn-sm" (click)="saveView('rejected')">
                                            <mat-icon class="icn-fnt-normal blue">save</mat-icon>
                                        </div>
                                        <div class="btn-sm" (click)="closeView()">
                                            <mat-icon class="icn-fnt-normal danger">close</mat-icon>
                                        </div>
                                    </div>
                                </div>
                                <!-- pop up div -->


                                <!-- Photos and Videos section start-->
                                <ng-container class="cell" *ngIf="column.columnDef == 'images'">
                                    <div class="streched rel update-indicator-wrapper">
                                        <img class="update-indicator" *ngIf="isInUpdated(column.id, row.updateAttribute)"  src="assets/icon/black-dot.svg" alt="">
                                        <div class="abs8-fixed">
                                            <button mat-button class="view" [ngClass]="{'view-disabled': isBtnDisable(row,column.id)}"
                                            [disabled]="isBtnDisable(row,column.id)" (click)="openGallery('images', row, column.id)" title="View images">
                                                <mat-icon class="btn-icon">insert_photo</mat-icon>
                                                {{getItemCount(row, column.id)}}
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container class="cell" *ngIf="column.columnDef == 'videos'">
                                    <div class="streched rel update-indicator-wrapper">
                                        <img class="update-indicator" *ngIf="isInUpdated(column.id, row.updateAttribute)"  src="assets/icon/black-dot.svg" alt="">
                                        <div class="abs8-fixed">
                                            <button mat-button class="view" [ngClass]="{'view-disabled': isBtnDisable(row,column.id)}"
                                            [disabled]="isBtnDisable(row,column.id)" (click)="openGallery('videos', row, column.id)" title="View videos">
                                                <mat-icon class="btn-icon">videocam</mat-icon>
                                                {{getItemCount(row, column.id)}}
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                                <!-- Photos and Videos section end-->


                                <ng-container class="cell" *ngIf="column.columnDef != 'action' && column.columnDef != 'user' && column.columnDef != 'images' && column.columnDef != 'videos'">
                                    <div class="streched rel update-indicator-wrapper">
                                        <!-- view button for table type -->
                                        <img class="update-indicator" *ngIf="isInUpdated(column.id, row.updateAttribute)"  src="assets/icon/black-dot.svg" alt="">
                                        <div class="abs8-fixed" *ngIf="getDataType(column.id) == 'table'">
                                            <button mat-button class="view" (click)="viewData(row.uid, column.columnDef, column.id)">View</button>
                                        </div>
                                        <div class="abs3 grid-50v" *ngIf="checkForEdit()">
                                            <div class="custom">
                                                <div class="icn-btn" title="Edit" (click)="editView(row.uid, column.columnDef, column.id)">
                                                    <mat-icon class="icn-fnt-nofocus blue">edit</mat-icon>
                                                </div>
                                                <div class="icn-btn" title="Reject" (click)="deleteView(row.uid, column.columnDef, column.id)">
                                                    <mat-icon class="icn-fnt-nofocus1 danger">close</mat-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- master values single and multi select-------------------------------------- -->
                                        <div *ngIf="getDataType(column.id) != 'table'" class="update-indicator-wrapper">
                                          <!-- <img class="update-indicator" *ngIf="isInUpdated(column.id, row.updateAttribute)"  src="assets/icon/black-dot.svg" alt=""> -->
                                            <p class="fnt-normal" *ngIf="getDataType(column.id) == 'single-select'">
                                              {{getMasterLabel({row: row, column: column})}}
                                            </p>
                                            <p class="fnt-normal" *ngIf="getDataType(column.id) == 'multi-select'" [title]="getMasterLabel({row: row, column: column},true)">
                                            {{getMasterLabel({row: row, column: column},true)}}
                                            </p>
                                            <p *ngIf="getDataType(column.id) == 'toggle'" class="fnt-normal">
                                                <ng-container *ngIf="row.data[column.id] == true || row.data[column.id] == false">
                                                    <span *ngIf="row.data[column.id] === true">Yes</span>
                                                    <span *ngIf="row.data[column.id] === false">No</span>
                                                </ng-container>
                                            </p>

                                            <p *ngIf="getDataType(column.id) != 'toggle' && getDataType(column.id) != 'single-select' && getDataType(column.id) != 'multi-select'" class="fnt-normal update-indicator-wrapper">
                                                <span *ngIf="isValidUrl(row.data[column.id])" class="pointer">
                                                  <a href="{{row.data[column.id]}}" target="_blank"> {{ row.data[column.id] }} </a>
                                                </span>
                                                <span *ngIf="!isValidUrl(row.data[column.id])" class="ellipsis" [title]="row.data[column.id]">
                                                  {{ row.data[column.id] }}
                                                </span>
                                            </p>
                                            <p class="fnt-normal update-indicator-wrapper" [title]="getChildValue(row,column.id)">
                                                <span class="ellipsis"> {{getChildValue(row,column.id)}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </ng-container>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <div class="cell-expanded rel" *matCellDef="let detail">
                                <div class="cell-hidden">
                                    <ng-container *ngFor="let column of columns">

                                        <mat-cell *ngIf="column.columnDef == 'action'" class="cellt"> </mat-cell>

                                        <mat-cell *ngIf="column.columnDef == 'user'" class="cell-cen">
                                            <div class="grid-50p ml-1" title="{{detail.element?.assigned_to_name}}">
                                                <span *ngIf="detail.element.profile_image" class="alin-m">
                                                    <img class="avatar" src="{{detail.element.profile_image}}"
                                                        onerror="this.onerror=null; this.src='assets/images/user.png'"
                                                        alt="user">
                                                </span>
                                                <span *ngIf="!detail.element.profile_image" class="avatar-t alin-m">
                                                    {{getInitials(detail.element?.assigned_to_name)}}
                                                </span>
                                                <div class="dfc">
                                                    <span class="fnt-normal fxw" title="{{getDate(detail.element.updated_date)}}">{{getDate(detail.element.updated_date)}}</span>
                                                </div>
                                            </div>
                                        </mat-cell>

                                        <mat-cell *ngIf="column.columnDef != 'action' && column.columnDef != 'user'" class="cell" fxLayout="row" fxLayoutAlign="center center" [ngClass]="{'edited-attrib-h':isEdited(detail.element,column.id), 'rejected-attrib-h':isRejected(detail.element,column.id)}"
                                            matTooltip="{{getComment(detail.element, column.id)}}" [matTooltipDisabled]="!isRejected(detail.element,column.id)">
                                            <ng-container class="cell">
                                                <div class="streched rel">

                                                    <div class="abs8-fixed" *ngIf="getDataType(column.id) == 'table'">
                                                        <button [matMenuTriggerFor]="viewData" mat-button class="view-v" [ngClass]="{'danger2': isRejected(detail.element,column.id), 'edited': isEdited(detail.element,column.id)}" (click)="viewHistoryData(detail.element, column.id)">View</button>
                                                        <mat-menu #viewData="matMenu">
                                                            <ng-template matMenuContent>
                                                                <div>
                                                                    <div class="ovrfl-h">
                                                                        <div class="h25p w-100"></div>
                                                                        <div class="m-label">
                                                                            <span class="margl-5">
                                                                                {{current_attribute.label}}
                                                                            </span>
                                                                        </div>
                                                                        <div class="historyView">
                                                                            <div class="in-con" *ngFor="let data of history_view">
                                                                                <div class="c-label">
                                                                                    {{data.master_label}}</div>
                                                                                <div class="grid-cust" *ngFor="let attrib of data.attributes">
                                                                                    <div class="alin fnt-sm">
                                                                                        {{attrib.child_label}}</div>
                                                                                    <div class="alin fnt-sm">
                                                                                        {{attrib.value}}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                        </mat-menu>
                                                    </div>
                                                    <div *ngIf="getDataType(column.id) != 'table'">
                                                        <p class="fnt-normal" *ngIf="getDataType(column.id) == 'single-select'">
                                                            {{getMasterLabel({row: detail.element, column: column})}}
                                                        </p>
                                                        <p class="fnt-normal" *ngIf="getDataType(column.id) == 'multi-select'">
                                                            {{getMasterLabel({row: detail.element, column: column},true)}}
                                                        </p>
                                                        <p *ngIf="getDataType(column.id) == 'toggle'" class="fnt-normal">
                                                            <ng-container *ngIf="detail.element.data[column.id] == true || detail.element.data[column.id] == false">
                                                                <span *ngIf="detail.element.data[column.id] === true">Yes</span>
                                                                <span *ngIf="detail.element.data[column.id] === false">No</span>
                                                            </ng-container>
                                                        </p>
                                                        <p *ngIf="getDataType(column.id) != 'toggle' && getDataType(column.id) != 'single-select' && getDataType(column.id) != 'multi-select'" class="fnt-normal">
                                                            {{ detail.element.data[column.id] }}
                                                        </p>
                                                        <p class="fnt-normal">
                                                            {{getChildValue(detail.element,column.id)}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let element; columns: displayedColumns; when: !isExpansionDetailRow" class="element-row" cdkDrag [cdkDragData]="element">
                        </mat-row>

                        <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow" [@detailExpand]="row.element.show ? 'expanded' : 'collapsed'" style="overflow: hidden">
                        </mat-row>
                    </mat-table>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="" [ngClass]="{'hidden': !dataSource}">
                <div class="pagindiv">
                    <!-- <mat-paginator [length]="entity_count.count" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageAction($event);">
          </mat-paginator> -->
                    <div *ngIf="this.entity_count.count > 0" style="margin: 0 10px;">
                        <form [formGroup]="jumpForm" (ngSubmit)="jumpTo(jumpForm.value)">Page No : <input type="number" formControlName="page_number" class="page_numb"><button class="jump-btn">Jump >></button>
                        </form>
                    </div>
                    <mat-select *ngIf="this.entity_count.count > 0" class="mt-2 limit_drop" [(value)]="property_limit" (valueChange)="pageAction(1)">
                        <mat-option *ngFor="let val of pageSizeOptions" [value]="val">{{val}}
                        </mat-option>
                    </mat-select>
                    <div style="margin-top: 15px;">
                        <pagination-controls *ngIf="this.entity_count.count > 0" (pageChange)="pageAction($event)" id="server">
                        </pagination-controls>
                    </div>
                </div>
                <!-- <div class="pagindiv">
          <div class="pagindiv1">
            <div><small class="text-danger">{{errormess}}</small></div>
            <div>Enter page number : <input [formControl]="pageNo" class="form-input-pagin" type="number" min="1">
              <button (click)="byNumPagin()" class="go-btn">Go</button>
            </div>
          </div>
        </div> -->
            </div>

        </mat-drawer-content>
    </mat-drawer-container>
</div>
